import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import api from "../adapter/base";
import AdminHeader from "./admin_header";
import AdminNav from "./admin_nav";
import { Card, Collapse, Statistic, Table, Tooltip, message } from "antd";
import {
  ArrowUpOutlined,
  RedoOutlined
} from "@ant-design/icons";
import { restore } from "../services/restore_service";
import qs from "qs";
const Recycler = () => {
  const [datas, setDatas] = useState([]);
  const [load, setLoad] = useState(false);
  const params = {
    params: {
      only_trashed: null,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { strictNullHandling: true });
    },
  };
  const {
    data: products,
    refetch: product_refetch,
    isLoading: product_load,
  } = useQuery("trashed_products", () => {
    return api.get("products", params);
  });
  const {
    data: suppliers,
    refetch: supplier_refetch,
    isLoading: supplier_load,
  } = useQuery("trashed_suppliers", () => {
    return api.get("suppliers", params);
  });
  const {
    data: categories,
    refetch: category_refetch,
    isLoading: category_load,
  } = useQuery("trashed_categories", () => {
    return api.get("categories", params);
  });
  const {
    data: users,
    refetch: users_refetch,
    isLoading: user_load,
  } = useQuery("trashed_users", () => {
    return api.get("users", params);
  });
  const {
    data: packages,
    refetch: packages_refetch,
    isLoading: package_load,
  } = useQuery("trashed_package", () => {
    return api.get("packages", params);
  });
  const {
    data: subcategories,
    refetch: sub_category_refetch,
    isLoading: sub_category_load,
  } = useQuery("trashed_subcategories", () => {
    return api.get("subcategories", params);
  });
  const [keys, setKeys] = useState(localStorage.getItem("col_key"));
  const { Panel } = Collapse;

  const keyChange = (key) => {
    localStorage.setItem("col_key", key);
    setKeys(localStorage.getItem("col_key"));
  };

  const statstic = (counter) => (
    <Statistic
      title="Deleted"
      value={counter}
      precision={2}
      valueStyle={{ color: "#3f8600" }}
      suffix={<ArrowUpOutlined />}
    />
  );

  const restores = async (id) => {
    setLoad(true);
    const selected_key = localStorage.getItem("col_key");
    var url = "";
    var refetcher = "";
    switch (selected_key) {
      case "1":
        url = "products";
        refetcher = product_refetch;
        break;
      case "2":
        url = "suppliers";
        refetcher = supplier_refetch;
        break;
      case "3":
        url = "categories";
        refetcher = category_refetch;
        break;
      case "4":
        url = "users";
        refetcher = users_refetch;
        break;
      case "5":
        url = "packages";
        refetcher = packages_refetch;
        break;
      case "6":
        url = "subcategories";
        refetcher = sub_category_refetch;
        break;
      default:
        url = "";
        break;
    }
    restore(url, id.split("#")[1])
      .then((res) => {
        setLoad(false);
        message.success("Restored Succesfully");
        refetcher();
      })
      .catch((err) => {
        setLoad(false);
        message.error("Something Went Wrong");
      });
  };
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Registered At",
      dataIndex: "created_at",
    },
    {
      title: "Deleted At",
      dataIndex: "deleted_at",
    },
    {
      render: (record) => {
        return (
          <>
            <Tooltip title="restore">
              <RedoOutlined
                style={{ color: "green", marginLeft: 40, fontSize: 17 }}
                onClick={() => {
                  restores(record.id);
                }}
              />
            </Tooltip>
            {/* <Tooltip title="Delete Permanently">
                  <DeleteOutlined style={{color : 'red',marginLeft : 40, fontSize : 17}} onClick={()=>{}} live_feed={false}/>
              </Tooltip> */}
          </>
        );
      },
    },
  ];
  useEffect(() => {
    var fetch = "";
    switch (keys) {
      case "1":
        fetch = products;
        break;
      case "2":
        fetch = suppliers;
        break;
      case "3":
        fetch = categories;
        break;
      case "4":
        fetch = users;
        break;
      case "5":
        fetch = packages;
        break;
      case "6":
        fetch = subcategories;
        break;
      default:
        break;
    }
    setDatas([]);
    for (let i = 0; i < fetch?.data?.data.length; i++) {
      setLoad(true);
      setDatas((pre) => {
        return [
          ...pre,
          {
            id: "#" + fetch?.data?.data[i].id,
            name: fetch?.data?.data[i]?.name,
            created_at: fetch?.data?.data[i]?.created_at
              ? fetch?.data?.data[i]?.created_at.split("T")[0]
              : "?",
            deleted_at: fetch?.data?.data[i]?.deleted_at
              ? fetch?.data?.data[i]?.deleted_at.split("T")[0]
              : "?",
          },
        ];
      });
      setLoad(false);
    }
  }, [[], keys]);
  return (
    <div>
      <Card
        hoverable
        title="Deleted Recycler"
        style={{ marginTop: "3%", marginLeft: "5%", width: "90%" }}
      >
        <Collapse
          bordered={false}
          defaultActiveKey={localStorage.getItem("col_key")}
          accordion
          onChange={keyChange}
        >
          <Panel header="Products" key="1">
            {statstic(products?.data?.data.length)}
            <Table
              columns={columns}
              dataSource={datas}
              size="small"
              loading={product_load || load}
            />
          </Panel>
          <Panel header="Suppliers" key="2">
            {statstic(suppliers?.data?.data.length)}
            <Table
              columns={columns}
              dataSource={datas}
              size="small"
              loading={supplier_load || load}
            />
          </Panel>
          <Panel header="Categories" key="3">
            {statstic(categories?.data?.data.length)}
            <Table
              columns={columns}
              dataSource={datas}
              size="small"
              loading={category_load || load}
            />
          </Panel>
          <Panel header="Users" key="4">
            {statstic(users?.data?.data.length)}
            <Table
              columns={columns}
              dataSource={datas}
              size="small"
              loading={user_load || load}
            />
          </Panel>
          <Panel header="Packages" key="5">
            {statstic(packages?.data?.data.length)}
            <Table
              columns={columns}
              dataSource={datas}
              size="small"
              loading={package_load || load}
            />
          </Panel>
          <Panel header="Sub Categories" key="6">
            {statstic(subcategories?.data?.data.length)}
            <Table
              columns={columns}
              dataSource={datas}
              size="small"
              loading={sub_category_load || load}
            />
          </Panel>
        </Collapse>
      </Card>
    </div>
  );
};

export default Recycler;
