/* eslint-disable */
import api from "../../adapter/base";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { message } from "antd";

const getImageAdverts = async (page = 1) => {
  const response = await api.get(`/image-adverts?page=${page}`);
  return response.data;
};

export const useGetImageAdvert = (page) => {
  return useQuery(["imageAdverts", page], () => getImageAdverts(page));
};

const getImageAdvert = async (id) => {
  const response = await api.get(`image-adverts/${id}`);
  return response.data;
};

export const useGetImaegAdvert = (id) => {
  return useQuery(["imageAdvert", id], () => getImageAdvert(id));
};

const postImageAdvert = async (advert) => {
  const response = await api.post(`image-adverts`, advert);
  return response;
};

export const usePostImageAdvert = (onSuccessCallback) => {
  const clientQuery = useQueryClient();
  const mutation = useMutation(postImageAdvert, {
    onSuccess: (data) => {
      const advertName = data?.data?.data?.name;
      message.success([advertName, "Registered !"].join(" "));
      clientQuery.invalidateQueries("imageAdverts", 1);
      onSuccessCallback();
    },
    onError: (err) => {
      var er =
        err?.response?.status === 500
          ? "Server Error"
          : err?.response?.data?.message;
      message.error(er);
    },
  });
  return { mutation };
};

const updateImageAdvert = async (imageAdvert) => {
  const advertId = imageAdvert?.id;
  const response = await api.put(`image-adverts/${advertId}`, imageAdvert);
  return response;
};

export const useUpdateImageAdvert = () => {
  const clientQuery = useQueryClient();
  const mutation = useMutation(updateImageAdvert, {
    onSuccess: (data) => {
      const response = data?.data?.data;
      message.success([response?.name, "Updated!"].join(""));
      clientQuery.invalidateQueries(["imageAdvert", response?.id]);
    },
    onError: (err) => {
      var er =
        err?.response?.status === 500
          ? "Server Error"
          : err?.response?.data?.message;
      message.error(er);
    },
  });
  return mutation;
};

const deleteImageAdvert = async (advertId) => {
  const response = await api.delete(`image-adverts/${advertId}`);
  return response;
};

export const useDeleteImageAdvert = () => {
  const clientQuery = useQueryClient();
  const deleteMutation = useMutation(deleteImageAdvert, {
    onSuccess: (data) => {
      const response = data?.data?.data;
      message.warning([response?.name, "deleted!"].join(""));
      clientQuery.invalidateQueries("imageAdverts");
    },
    onError: (err) => {
      var er =
        err?.response?.status === 500
          ? "Server Error"
          : err?.response?.data?.message;
      message.error(er);
    },
  });
  return { deleteMutation };
};

const activateDeactivateAd = async (ImageAd) => {
  const updateImageAdStatus = { is_active: !ImageAd?.is_active ? 1 : 0 };
  const response = await api.put(
    `image-adverts/${ImageAd.id}`,
    updateImageAdStatus
  );
  return response;
};

export const useActivateDeactivateAd = () => {
  const clientQuery = useQueryClient();
  const activateDeactivateMutation = useMutation(activateDeactivateAd, {
    onSuccess: (data) => {
      const response = data?.data?.data;
      response?.is_active
        ? message.success([response?.title, "activated!"].join(" "))
        : message.warning([response?.title, "deactivated!"].join(" "));
      clientQuery.invalidateQueries("imageAdverts");
    },
    onError: (err) => {
      var er =
        err?.response?.status === 500
          ? "Server Error"
          : err?.response?.data?.message;
      message.error(er);
    },
  });
  return { activateDeactivateMutation };
};

const changePopUpStatus = async (ImageAd) => {
  const updateImageAdStatus = { is_popup: !ImageAd?.is_popup ? 1 : 0 };
  const response = await api.put(
    `image-adverts/${ImageAd.id}`,
    updateImageAdStatus
  );
  return response;
};

export const useChangePopUpStatus = () => {
  const clientQuery = useQueryClient();
  const popupMutation = useMutation(changePopUpStatus, {
    onSuccess: (data) => {
      const response = data?.data?.data;
      message.success(
        response?.is_popup
          ? [response?.title, "become popup!"].join(" ")
          : [response?.title, "become Normal!"].join(" ")
      );

      clientQuery.invalidateQueries("imageAdverts");
    },
    onError: (err) => {
      var er =
        err?.response?.status === 500
          ? "Server Error"
          : err?.response?.data?.message;
      message.error(er);
    },
  });
  return { popupMutation };
};
