import api from "../../adapter/base";
import { useQuery, useMutation } from "react-query";

/**
 *
 * This is a custom hook to manage data request and response from api
 *
 */

// used to fetch corporates
const fetchCorporate = async (page = 1, searchTerm) => {
  const res = await api.get(
    `corporates?page=${page}${searchTerm ? `&name=${searchTerm}` : ``}`
  );
  return res.data;
};

// used to fetch corporate Employees
const corporateEmployees = async (corporate_id, page = 1) => {
  const res = await api.get(`corporates/${corporate_id}/users?page=${page}`);
  return res.data;
};

const registerCorporate = (corporate) => {
  return api.post("corporates", corporate);
};

const deleteCorporate = (id) => {
  return api.delete(`corporates/${id}`);
};

const updateCorporate = (data) => {
  return api.put(`corporates/${data.id}`, data);
};

const getCorporateUsers = (id) => {
  return api.get(`corporates/${id}/users`);
};

const registerCorporateManager = (manager) => {
  return api.post(manager);
};

// custom hook queries

export const useCorporateData = (page, search) => {
  return useQuery(["corporates", page, search], () =>
    fetchCorporate(page, search)
  );
};

export const useCorporateEmployee = () => {
  return useQuery("corporateEmployees", corporateEmployees);
};

export const useAddCorporateData = () => {
  return useMutation(registerCorporate);
};

export const useDeleteCorporate = () => {
  return useMutation(deleteCorporate);
};

export const useUpdateCorporate = () => {
  return useMutation(updateCorporate);
};

export const useGetCorporateEmployee = (id) => {
  return useQuery(["corporateEmployees", id], () => {
    return getCorporateUsers(id);
  });
};

export const useRegisterCorporateEmployee = () => {
  return useMutation(registerCorporateManager);
};
