import api from "../adapter/base";
import { useMutation, useQuery } from "react-query";

const resetPinCode = (employeeId) => {
  return api.put(`users/${employeeId}/reset-pin-code`);
};

const corporateEmployee = async (id, page, name) => {
  const res = await api.get(
    `corporates/${id}/users?page=${page}${name ? `&name=${name}` : ``}`
  );
  return res.data;
};
export const useResetPinCode = () => {
  return useMutation(resetPinCode);
};

export const useCorporateEmployees = (id, page, name) => {
  return useQuery(["corporateEmployees", id, page, name], () =>
    corporateEmployee(id, page, name)
  );
};
