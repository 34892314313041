import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input, Button, Space, message } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { auth_actions } from "../store/auth-slice";
import { permission_action } from "../store/permission_slice";
import { login_service } from "../services/login_service";
export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const login = async () => {
    setLoading(true);
    const data = login_service({
      email: email,
      password: password,
    })
      .then((response) => {
        dispatch(auth_actions.login(response?.data));
        dispatch(
          permission_action.registerPermission(
            response?.data?.permission_groups
          )
        );
        if (response.data.scopes[0] === "administrator") {
          navigate("/admin");
        } else if (response.data.scopes[0] === "corporate-manager") {
          navigate("/empl_manager");
        } else {
          message.error("Incorrect UserName or Password");
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message)
          message.error(err?.response?.data?.message);
        !err?.message?.includes("Request failed with status code 401") &&
          message.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
    return data;
  };
  return (
    <div className="container-fluid">
      <div className="login">
        <div className="login_header">
          <h2>መግቢያ</h2>
          <form autoComplete="off">
            <Space
              direction="vertical"
              style={{ width: "100%", margin: "2px" }}
            >
              <Input
                size="large"
                onKeyUp={(e) => e.key === "Enter" && login()}
                className="user_name"
                name="email"
                style={{ marginTop: "1%" }}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="የስም መለያ"
                suffix={<UserOutlined />}
                autoComplete="off"
              />
              <Input.Password
                className="pass"
                onKeyUp={(e) => e.key === "Enter" && login()}
                name="password"
                placeholder="ምስጢር ቁጥር"
                style={{ marginTop: "1%" }}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="new-password"
              />
              <Button
                size="large"
                type="primary"
                loading={loading}
                onClick={() => login()}
                style={{
                  width: 400,
                  marginTop: "20px",
                  height: 50,
                  background: "#F4AD33",
                }}
              >
                ይግቡ
              </Button>
            </Space>
          </form>
        </div>
      </div>
    </div>
  );
}
