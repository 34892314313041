/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  Menu,
  Avatar,
  Badge,
  Popover,
  Typography,
  List,
  Tooltip,
  message,
} from "antd";
import { EyeOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import image from "../../images/ser.png";
import { actions } from "../../store/products-slice";
import { auth_actions } from "../../store/auth-slice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { permission_action } from "../../store/permission_slice";
import { MdOutlineNotificationsNone } from "react-icons/md";
import { useQuery, useQueryClient } from "react-query";
import store from "../../store";
import api from "../../adapter/base";
import echo from "../../ws_config/ws";
function Header() {
  const user = useSelector((state) => state?.auth?.user?.data);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const content=(<div><p>Edit Profile</p></div>)
  const { data: notifications } = useQuery(
    "all_user_unread_notifications",
    () => {
      return api.get("/unread-notifications");
    }
  );
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const query_client = useQueryClient();
  const markasRead = (id) => {
    setLoad(true);
    api
      .put("/notifications/mark-as-read", { id: id })
      .then((res) => {
        query_client.invalidateQueries("all_user_unread_notifications");
      })
      .catch((err) => {
        message.error("SomeThing Went Wrong!");
      })
      .finally(() => {
        setLoad(false);
      });
  };
  const contentDIV = (
    <div
      id="scrollableDiv"
      style={{
        maxHeight: 400,
        overflow: "auto",
      }}
    >
      <List
        bordered
        loading={load}
        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            <Typography.Text
              mark={!item.message?.includes("free trip")}
              className={item.message?.includes("free trip") && "bg-green-300"}
            >
              {item.message}
            </Typography.Text>
            <Tooltip title="mark as read">
              <EyeOutlined onClick={() => markasRead(item.id)} />
            </Tooltip>
          </List.Item>
        )}
      />
    </div>
  );

  useEffect(() => {
    echo
      .private(`users.${store?.getState()?.auth?.user?.id}`)
      .notification((e) => {
        console.log(e);
        query_client.invalidateQueries("all_user_unread_notifications");
      });
  }, []);

  useEffect(() => {
    setData([]);
    notifications?.data?.data?.map((notification) => {
      setData((pre) => {
        return [
          ...pre,
          {
            id: notification?.id,
            message: notification?.data?.order_id
              ? `Order with Id # ${notification?.data?.order_id} is ready for pickup`
              : `New free trip request with id # ${notification?.data?.free_trip_request_id} Awaits!`,
          },
        ];
      });
    });
  }, [notifications]);

  const logout = () => {
    dispatch(auth_actions.logout());
    dispatch(actions.defaultState());
    dispatch(permission_action.removePermission());
    navigate("/login");
  };
  return (
    <div
      className="container-fluid"
      style={{
        marginLeft: "0%",
        width: "100%",
        paddingLeft: "0%",
        paddingRight: "0%",
      }}
    >
      <div>
        <Menu theme="dark" mode="horizontal" className="admin_menu">
          <Menu.Item key="features">
            <span className="span_header">እንኳን በደህና መጡ!</span>
            <h6>{user?.first_name}</h6>
          </Menu.Item>
          {user && notifications?.data?.data?.length && (
            <Menu.Item key="notification" style={{ alignItems: "center" }}>
              <Popover content={contentDIV} title="Unread Notifications">
                <Badge count={notifications?.data?.data?.length}>
                  <MdOutlineNotificationsNone fontSize={30} color="white" />
                </Badge>
              </Popover>
            </Menu.Item>
          )}
          <Menu.Item key="about" onClick={() => navigate("/home_")}>
            <Avatar
              src={
                <img src={user?.profile_thumbnail_path} alt="user profile" />
              }
            />
          </Menu.Item>
          <Menu.Item key="logout" onClick={logout}>
            {user && (
              <div>
                <div
                  style={{ fontSize: "25px", color: "white" }}
                  className="logout"
                >
                  <i className="fa fa-sign-out"></i>
                </div>
              </div>
            )}
          </Menu.Item>
        </Menu>
        <div>
          <div className="admin_logo">
            <img src={image} className="img" alt="seregela logo" />
            <div className="sergela_link">
              <a href="https://seregelagebeya.com">
                Sergela<span>Gebeya</span>
              </a>
            </div>
          </div>
          <div className="gear_icons">
            <i
              style={{ color: "white", fontSize: 25 }}
              className="fa-solid fa-gear"
            ></i>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Header;
