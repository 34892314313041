/* eslint-disable */
import { useState } from "react";
import api from "../../adapter/base";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { message } from "antd";

// fetch banks
const banks = async (page = 1, searchterm) => {
  const res = await api.get(
    `banks?page=${page}${searchterm ? `&name=${searchTerm}` : ``}`
  );
  return res.data;
};

// register Bank
const registerBank = async (bank) => {
  return await api.post(`banks`, bank);
};

// update bank information
const updateBank = async (bank) => {
  return await api.put(`banks/${bank.id}`, bank);
};

// soft delete bank
const deleteBank = async (id) => {
  return await api.delete(`banks/${id}`);
};

// react-queries
export const useBanks = (page, term) => {
  return useQuery("banks", () => banks(page, term));
};

export const useRegisterBank = (onSuccessCallback) => {
  const client = useQueryClient();
  const [error, setError] = useState(undefined);
  const mutation = useMutation(registerBank, {
    onSuccess: (data) => {
      client.invalidateQueries("banks");
      message.success([data?.data?.data?.name, "registered"].join(" "));
      onSuccessCallback();
      setError(undefined);
    },
    onError: (error) => {
      var err;
      if (error?.response?.status === 500) {
        err = "Server Error";
      } else {
        err = error?.response?.data?.message ?? "Something went wrong!";
      }
      setError(err);
    },
  });
  return { mutation, error };
};

export const useUpdateBank = (onSuccessCallback) => {
  const client = useQueryClient();
  const [error, setError] = useState(undefined);
  const mutation = useMutation(updateBank, {
    onSuccess: (data) => {
      client.invalidateQueries("banks");
      message.success([data?.data?.data?.name, "updated"].join(" "));
      onSuccessCallback();
      setError(undefined);
    },
    onError: (error) => {
      var err;
      if (error?.response?.status === 500) {
        err = "Server Error";
      } else {
        err = error?.response?.data?.message ?? "Something went wrong!";
      }
      setError(err);
    },
  });
  return { mutation, error };
};

export const useDeleteBank = () => {
  const client = useQueryClient();
  const [error, setError] = useState(undefined);
  const mutation = useMutation(deleteBank, {
    onSuccess: (data) => {
      message.warning("Bank Deleted Successfully");
      client.invalidateQueries("banks");
    },
    onError: (err) => {
      setError(err);
    },
  });
  return { deleteBank:mutation, error };
};
