/* eslint-disable */
import { useEffect } from "react";
import { Table, Button } from "antd";
import tw from "tailwind-styled-components";
import { tabColumns } from "./static/TableColumns";
import {
  useSuccessfullyDeliveredOrder,
  useExportSuccessfullyDelivered,
} from "./hooks/useOrderData";
import OrderedProducts from "./orderedProducts";
import { FileExcelOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useSelector } from "react-redux";
import echo from "../ws_config/ws";
import { useQueryClient } from "react-query";
const StyledTableContainer = tw.div`
  mx-auto max-w-screen-lg
`;
const StyledTable = tw(Table)`
  overflow-x-auto w-full
`;

const columns = [
  ...tabColumns,
  {
    title: "Delivery",
    dataIndex: ["delivery_type", "name"],
    key: "delivery_type",
  },
  {
    title: "ApprovedBy",
    dataIndex: ["approver", "name"],
    key: "approved_by",
    render: (approver) => {
      return approver ? approver : "?";
    },
  },
  {
    title: "ApprovedAt",
    dataIndex: "approved_at",
    key: "approved_at",
  },
];

const SuccessfullyDelivered = () => {
  const [page, setPage] = useState(1);
  // on row click modal visiblity controller
  const [modalVisible, setModalVisible] = useState(false);
  const [recordData, setRecordData] = useState();
  const client = useQueryClient();
  const handleModalClose = () => {
    setModalVisible(false);
  };
  const handleModalOpen = () => {
    setModalVisible(true);
  };
  const onRow = (record) => {
    return {
      onClick: (event) => {
        handleModalOpen();
        setRecordData(record);
      },
    };
  };
  const { data, isLoading: load } = useSuccessfullyDeliveredOrder(page);
  // handle pagination changes
  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };

  // handle excel download
  const { mutate: exportOrders, isLoading: load_download } =
    useExportSuccessfullyDelivered();

  const permissions = useSelector((state) => state.permission.permission);

  // listen for successfully delivered orders socket
  useEffect(() => {
    echo.private(`orders.status-change`).listen("OrderDelivered", (data) => {
      client.invalidateQueries("successfullyDeliveredOrders");
    });

    return () => {
      echo.leave(`orders.status-change`);
    };
  }, []);

  return (
    <div>
      <div>
        {permissions.find((permission) => {
          return permission == "Report Management";
        }) && (
          <Button onClick={() => exportOrders()} loading={load_download}>
            <FileExcelOutlined
              title="Download Excel File"
              style={{ fontSize: "22px", color: "#79BF59" }}
            />
            Export Excel
          </Button>
        )}
      </div>
      <StyledTableContainer>
        <StyledTable
          columns={columns}
          dataSource={data?.data?.map((dt) => ({ ...dt, key: dt.id }))}
          pagination={
            data?.meta?.last_page > 1
              ? {
                  current: page,
                  position: "both center",
                  total: data?.meta?.last_page * 10,
                  showSizeChanger: false,
                }
              : false
          }
          onChange={handleTableChange}
          loading={load}
          onRow={onRow}
          size="small"
        />
        <OrderedProducts
          visible={modalVisible}
          onClose={handleModalClose}
          recordData={recordData}
        />
      </StyledTableContainer>
    </div>
  );
};

export default SuccessfullyDelivered;
