/* eslint-disable */

import { Tabs } from "antd";
import {
  SearchOutlined,
  CarOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import tw from "tailwind-styled-components";
import AdminHeader from "../Admin/admin_header";
import AdminNav from "../Admin/admin_nav";
import AcceptedOrders from "./acceptedOrder";
import SearchBar from "./searchBars";
import SearchResult from "./searchResult";
import AcceptPendingPickup from "./acceptPendingPickup";
import PendingDeliverys from "./pendingDeliverys";
import SuccessfullyDelivered from "./successfullyDelivered";
import { useState } from "react";

const { TabPane } = Tabs;

const StyledTabContainer = tw.div`
  mx-auto max-w-screen px-4 py-8
`;

const StyledTabs = tw(Tabs)`
  border-0 bg-white
`;

const StyledTabPane = tw(TabPane)`
  flex justify-center items-center
`;

const StyledTabIcon = tw.span`
  mr-2 text-base
`;

const StyledTabTitle = tw.span`
  text-base font-medium
`;

const TabContent = ({ title, icon, component }) => (
  <>
    <StyledTabIcon>{icon}</StyledTabIcon>
    <StyledTabTitle>{title}</StyledTabTitle>
    {component}
  </>
);

const Main = () => {
  const [filters, setFilters] = useState();
  const [page] = useState(1);

  const handleFiltersUpdate = (newValues) => {
    setFilters(newValues);
  };

  return (
    <div>
      <StyledTabContainer>
        <SearchBar onFiltersChange={handleFiltersUpdate} />
        <StyledTabs>
          <StyledTabPane
            tab={
              <TabContent title="Searched Results" icon={<SearchOutlined />} />
            }
            key="1"
          >
            <SearchResult filters={filters} pageToDefault={page} />
          </StyledTabPane>
          <StyledTabPane
            tab={
              <TabContent
                title="Accept Pending PickUp"
                icon={<CarOutlined />}
              />
            }
            key="2"
          >
            <AcceptPendingPickup />
          </StyledTabPane>
          <StyledTabPane
            tab={
              <TabContent title="Accepted Orders" icon={<CheckOutlined />} />
            }
            key="3"
          >
            <AcceptedOrders />
          </StyledTabPane>
          <StyledTabPane
            tab={
              <TabContent
                title="Pending Delivery"
                icon={<ClockCircleOutlined />}
              />
            }
            key="4"
          >
            <PendingDeliverys />
          </StyledTabPane>
          <StyledTabPane
            tab={
              <TabContent
                title="Successfully Delivered"
                icon={<CheckCircleOutlined />}
              />
            }
            key="5"
          >
            <SuccessfullyDelivered />
          </StyledTabPane>
        </StyledTabs>
      </StyledTabContainer>
    </div>
  );
};

export default Main;
