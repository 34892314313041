import React, { useEffect, useState } from "react";
import qs from "qs";
import AdminHeader from "./admin_header";
import AdminNav from "./admin_nav";
import NoImage from "../images/No-Image.png";
import EditCategories from "../modals/editcategories";
import EditSubCategories from "../modals/editsubcategories";
import AddCategory from "../modals/addcategories";
import AddSubCategory from "../modals/addsubcategories";
import "../style/style.css";
import {
  Dropdown,
  Menu,
  Space,
  Button,
  Card,
  Checkbox,
  Row,
  Col,
  Modal,
  Input,
  message,
  Tag,
  Table,
  Pagination,
  Popover,
  notification,
} from "antd";
import { MoreOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useQuery, useQueryClient } from "react-query";
import api from "../adapter/base";
import VectorBar from "../images/Vector_bar.png";
import ClipLoader from "react-spinners/ClipLoader";
import Ellipse1 from "../images/ellipses/Ellipse 1.png";
import Vector from "../images/ellipses/low.png";
import Ellipse3 from "../images/ellipses/Ellipse 3.png";
import Vector1 from "../images/ellipses/right_sign.png";
import Ellipse2 from "../images/ellipses/Ellipse 2.png";
import Vector2 from "../images/ellipses/red-x-icon.webp";
function AdminStockManagement() {
  const [visible, setVisible] = useState(false);
  const [categories, setCategories] = useState();
  const [subCatagories, setSubCategories] = useState([]);
  const [visible_, setVisible_] = useState();
  const [visible__, setVisible__] = useState();

  const [popular, setPopular] = useState();
  const [allProducts, setAllProducts] = useState();
  const [subcatmodal, setSubcatmodal] = useState();

  const [page, setPage] = useState(1);
  const [last_page, setLastPage] = useState();

  //set category and subcategories to edit
  const [categoryToEdit, setCategoryToEdit] = useState();
  const [subCategoryToEdit, setSubCategoryToEdit] = useState();

  const [loading, setLoading] = useState(true);
  const { data: categoriess } = useQuery("categories_by_pagination", () => {
    return api.get("categories", {
      params: { all: null },
      paramsSerializer: (params) => {
        return qs.stringify(params, { strictNullHandling: true });
      },
    });
  });
  const { data: subcategoriess } = useQuery(
    "subcategories_by_pagination",
    () => {
      return api.get("/subcategories", {
        params: { all: null },
        paramsSerializer: (params) => {
          return qs.stringify(params, { strictNullHandling: true });
        },
      });
    }
  );
  const client = useQueryClient();
  //useQuery updates
  const { data: instock } = useQuery("in_stock", () => {
    return api.get(
      "/products/count",
      { params: { in_stock: null } },
      {
        paramsSerializer: (params) => {
          return qs.stringify(params, { strictNullHandling: true });
        },
      }
    );
  });
  const { data: outstock } = useQuery("out_stock", () => {
    return api.get("/products/count", {
      params: { out_of_stock: null },
      paramsSerializer: (params) => {
        return qs.stringify(params, { strictNullHandling: true });
      },
    });
  });
  const { data: lowstock } = useQuery("low_from_stock", () => {
    return api.get("/products/count", {
      params: { low_in_stock: null },
      paramsSerializer: (params) => {
        return qs.stringify(params, { strictNullHandling: true });
      },
    });
  });
  const [productName, setProductName] = useState();
  const handleSubCancel = () => {
    setVisible__(false);
  };
  const columns = [
    {
      key: "1",
      title: "ID",
      dataIndex: "id",
    },
    {
      key: "2",
      title: "Store Name",
      dataIndex: "name",
    },
    {
      key: "3",
      title: "Quantity",
      dataIndex: "quantity",
    },
    {
      key: "4",
      title: "Store Type",
      dataIndex: "type",
    },
  ];
  const [dataset, setDataset] = useState([]);
  const productStore = (
    <div className="container">
      <Table columns={columns} dataSource={dataset} />
    </div>
  );

  const storeProduct = (pro) => {
    setDataset([]);
    pro?.stores?.map((store) => {
      return setDataset((pre) => {
        return [
          ...pre,
          {
            id: store.id,
            name: store.name,
            quantity: store?.pivot?.quantity,
            type: store?.type,
          },
        ];
      });
    });
  };

  const { Meta } = Card;
  const { Search } = Input;
  useEffect(() => {
    api
      .get("/products", {
        params: {
          name: productName,
          page: page,
        },
      })
      .then((res) => {
        setLastPage(res?.data?.meta?.last_page);
        setAllProducts(res?.data?.data);
        setLoading(false);
      });
  }, [productName, page]);
  useEffect(() => {
    const args = {
      type: "info",
      message: "Want To Get The Product Store?",
      description: "Click On The Product Table Rows",
      position: "bottomRight",
    };
    notification.open(args);
  }, []);
  const setcatmodalvisible = () => {
    setSubcatmodal(true);
  };
  const setEditVisible_ = () => {
    setVisible_(true);
  };

  const setModalVisible = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleCloseCategoryEdit = () => {
    setVisible_(false);
  };

  const handleCloseSubCategoryEdit = () => {
    setSubcatmodal(false);
  };
  const menus = (category) => (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <a
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                editCategories(category);
                setEditVisible_();
              }}
            >
              Edit
            </a>
          ),
          icon: <EditOutlined />,
        },
        {
          key: "2",
          label: (
            <a
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => deletecat(category)}
            >
              Delete
            </a>
          ),
          icon: <DeleteOutlined />,
          danger: true,
        },
      ]}
    />
  );

  const menussub = (sub_category) => (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <a
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                editSubCategories(sub_category);
                setcatmodalvisible();
              }}
            >
              Edit
            </a>
          ),
          icon: <EditOutlined />,
        },
        {
          key: "2",
          label: (
            <a
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => deletesubcat(sub_category)}
            >
              Delete
            </a>
          ),
          icon: <DeleteOutlined />,
          danger: true,
        },
      ]}
    />
  );
  const deletesubcat = (subcategory) => {
    api
      .delete(`/subcategories/${subcategory.id}`)
      .then((res) => {
        message.warning("Deleted Succesfully");
        client.invalidateQueries("subcategories_by_pagination");
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
      });
  };

  const deletecat = (category) => {
    api
      .delete(`categories/${category?.id}`)
      .then((res) => {
        message.warning("Deleted Succesfully");
        client.invalidateQueries("categories_by_pagination");
      })
      .catch((err) => {
        message.error("Something Went Wrong");
      });
  };

  const stockProduct = (paramss) => {
    api
      .get("stock-products", {
        params: {
          [`${paramss}`]: null,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { strictNullHandling: true });
        },
      })
      .then((res) => {
        return setAllProducts(res?.data?.data);
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
      });
  };
  const switchStocks = (status) => {
    switch (status) {
      case "in_stock":
        stockProduct("in_stock");
        break;
      case "low_in_stock":
        stockProduct("low_in_stock");
        break;
      case "out_of_stock":
        stockProduct("out_of_stock");
        break;
      default:
        break;
    }
  };
  const itemm = [
    {
      label: (
        <Tag
          style={{
            width: "100%",
            fontSize: 13,
            color: "green",
            height: 25,
            background: "#88D4BD",
          }}
        >
          In stock
        </Tag>
      ),
      key: "in_stock",
    },
    {
      label: (
        <Tag
          style={{
            width: "100%",
            fontSize: 13,
            color: "#FAAD14",
            height: 25,
            background: "#FFEEC5",
          }}
        >
          Low in stock
        </Tag>
      ),
      key: "low_in_stock",
    },
    {
      label: (
        <Tag
          style={{
            width: "100%",
            fontSize: 13,
            color: "#FD706F",
            height: 25,
            background: "#FBC7C5",
          }}
        >
          Out of stock
        </Tag>
      ),
      key: "out_of_stock",
    },
  ];
  const menu = (
    <Menu
      items={itemm}
      onClick={(e) => {
        switchStocks(e.key);
      }}
    />
  );
  const tag = (quantity) => {
    if (quantity >= 10) {
      return (
        <Tag
          style={{
            fontSize: 13,
            color: "green",
            height: 25,
            background: "#88D4BD",
          }}
        >
          In stock
        </Tag>
      );
    } else if (quantity < 10 && quantity > 0) {
      return (
        <Tag
          style={{
            fontSize: 13,
            color: "#FAAD14",
            height: 25,

            background: "#FFEEC5",
          }}
        >
          Low stock
        </Tag>
      );
    } else {
      return (
        <Tag
          style={{
            fontSize: 13,
            color: "#FD706F",
            height: 25,
            background: "#FBC7C5",
          }}
        >
          Out of stock
        </Tag>
      );
    }
  };

  const editCategories = (category) => {
    setCategoryToEdit(category);
  };

  const editSubCategories = (subcategory) => {
    setSubCategoryToEdit(subcategory);
  };

  useEffect(() => {
    setCategories(categoriess?.data?.data);
    setSubCategories(subcategoriess?.data?.data);
    api
      .get("/popular-products", {
        params: {
          paginate: 5,
        },
      })
      .then((res) => {
        setPopular(res.data["data"]);
      })
      .catch((err) => {
        console.log("not setted");
      });
    api
      .get("/products")
      .then((res) => {
        setAllProducts(res.data["data"]);
      })
      .catch();
  }, []);

  useEffect(() => {
    setCategories(categoriess?.data?.data);
  }, [categoriess]);

  useEffect(() => {
    setSubCategories(subcategoriess?.data?.data);
  }, [subcategoriess]);

  return (
    <div className="admin_stock" style={{ width: "100%" }}>
      <h6
        style={{
          fontSize: 16,
          fontWeight: "bold",
          color: "#252733",
          marginTop: 30,
          marginLeft: 90,
        }}
      >
        የስቶክ መረጃ
      </h6>
      <Row gutter={[16, 16]} style={{ marginLeft: "15%", marginTop: "2%" }}>
        <Col xs={16} md={12} lg={7}>
          <Card
            hoverable
            style={{ width: 280, height: 100, borderColor: "#DFE0EB" }}
          >
            <img
              className="ellipse"
              style={{
                borderRadius: 40,
                width: 80,
                height: 80,
                marginTop: -15,
                opacity: 2,
              }}
              src={Ellipse1}
              alt="ellipse for items"
            />
            <img
              className="vector"
              style={{ width: 40, height: 40, marginLeft: -59, marginTop: -14 }}
              src={Vector}
              alt="vector for items"
            />
            <h6
              style={{
                fontSize: 45,
                marginTop: -80,
                marginLeft: 50,
                textAlign: "center",
                color: "#9FA2B4",
              }}
            >
              {lowstock?.data}
            </h6>
            <h6
              style={{
                fontSize: 13,
                fontWeight: "bold",
                color: "#252733",
                marginTop: -27,
                marginLeft: 100,
              }}
            >
              ትንሽ መጠን ያላቸው
            </h6>
          </Card>
        </Col>
        <Col xs={16} md={12} lg={7}>
          <Card
            hoverable
            style={{ width: 280, height: 100, borderColor: "#DFE0EB" }}
          >
            <img
              className="ellipse"
              style={{
                borderRadius: 40,
                width: 80,
                height: 80,
                marginTop: -15,
                zIndex: 10,
              }}
              src={Ellipse2}
              alt="ellipse for items"
            />
            <img
              className="vector"
              style={{ width: 40, height: 40, marginLeft: -59, marginTop: -14 }}
              src={Vector2}
              alt="vector for items"
            />
            <h6
              style={{
                fontSize: 45,
                marginTop: -80,
                marginLeft: 50,
                textAlign: "center",
                color: "#9FA2B4",
              }}
            >
              {outstock?.data}
            </h6>
            <h6
              style={{
                fontSize: 13,
                fontWeight: "bold",
                color: "#252733",
                marginTop: -27,
                marginLeft: 120,
              }}
            >
              ከስቶክ ያለቁ
            </h6>
          </Card>
        </Col>
        <Col xs={16} md={12} lg={7}>
          <Card
            hoverable
            style={{ width: 280, height: 100, borderColor: "#DFE0EB" }}
          >
            <img
              className="ellipse"
              style={{
                borderRadius: 40,
                width: 80,
                height: 80,
                marginTop: -15,
                opacity: 2,
              }}
              src={Ellipse3}
              alt="ellipse for items"
            />
            <img
              className="vector"
              style={{ width: 40, height: 40, marginLeft: -59, marginTop: -14 }}
              src={Vector1}
              alt="vector for items"
            />
            <h6
              style={{
                fontSize: 45,
                marginTop: -80,
                marginLeft: 50,
                textAlign: "center",
                color: "#9FA2B4",
              }}
            >
              {instock?.data}
            </h6>
            <h6
              style={{
                fontSize: 13,
                fontWeight: "bold",
                color: "#252733",
                marginTop: -27,
                marginLeft: 120,
              }}
            >
              በስቶክ ያሉ
            </h6>
          </Card>
        </Col>
      </Row>
      <Row
        gutter={[16, 8]}
        style={{ marginLeft: "5%", marginTop: "2%", width: "90%" }}
      >
        <Col xs={16} xl={12}>
          <h6 style={{ fontSize: 16, color: "#252733", marginLeft: "2%" }}>
            Catagories
          </h6>
          <Card
            hoverable
            className="scrollable"
            style={{
              width: 550,
              height: 380,
              borderColor: "#DFE0EB",
              marginTop: "3%",
            }}
          >
            <Button
              style={{
                color: "#FAAD14",
                marginLeft: "80%",
                width: 100,
                borderColor: "#FAAD14",
              }}
              onClick={() => setModalVisible()}
            >
              Add
            </Button>
            <Card
              hoverable
              style={{
                width: 500,
                height: 50,
                marginTop: 10,
                marginLeft: 0,
                borderColor: "#EBEBEB",
                backgroundColor: "#EBEBEB",
              }}
            >
              <div className="stock_check" style={{ marginTop: -10 }}>
                <Checkbox />
              </div>
              <h6
                style={{
                  fontSize: 14,
                  color: "#252733",
                  marginTop: -20,
                  marginLeft: 60,
                }}
              >
                Category
              </h6>
              <h6
                style={{
                  fontSize: 14,
                  color: "#252733",
                  marginTop: -22,
                  marginLeft: "50%",
                }}
              >
                Quantity
              </h6>
              <div
                className="more_outline"
                style={{ marginLeft: "90%", marginTop: -30 }}
              >
                <MoreOutlined
                  style={{ fontSize: 16, fontWeight: "bold", color: "#000" }}
                />
              </div>
            </Card>

            {categories ? (
              <div>
                {categories?.map((category) => {
                  return (
                    <Card
                      hoverable
                      style={{
                        width: 500,
                        height: 50,
                        marginTop: 10,
                        marginLeft: 0,
                        borderColor: "#EBEBEB",
                      }}
                    >
                      <div className="stock_check" style={{ marginTop: -10 }}>
                        <Checkbox />
                      </div>
                      <h6
                        style={{
                          fontSize: 12,
                          color: "#8C8C8C",
                          marginTop: -20,
                          marginLeft: 60,
                        }}
                      >
                        {category.name}
                      </h6>
                      <h6
                        style={{
                          fontSize: 12,
                          color: "#8C8C8C",
                          marginTop: -20,
                          marginLeft: "52%",
                        }}
                      >
                        {category.products_count}
                      </h6>
                      <div
                        className="more_outline"
                        style={{ marginLeft: "90%", marginTop: -30 }}
                      >
                        <Dropdown overlay={menus(category)} trigger={["click"]}>
                          <a onClick={(e) => e.preventDefault()}>
                            <Space>
                              <MoreOutlined
                                style={{
                                  fontSize: 16,
                                  fontWeight: "bold",
                                  color: "#000",
                                }}
                              />
                            </Space>
                          </a>
                        </Dropdown>
                      </div>
                    </Card>
                  );
                })}
              </div>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  marginTop: "20%",
                  height: "500px",
                }}
              >
                <ClipLoader size={20} color={"#FAAD14"} loading={true} />
              </div>
            )}
          </Card>
        </Col>
        <Col xs={16} xl={12}>
          <h6 style={{ fontSize: 16, color: "#252733", marginLeft: "2%" }}>
            Sub categories
          </h6>
          <Card
            hoverable
            className="scrollable"
            style={{
              width: 550,
              // overflow: "hidden",
              height: 380,
              borderColor: "#DFE0EB",
              marginTop: "3%",
            }}
          >
            <Button
              style={{
                color: "#FAAD14",
                marginLeft: "80%",
                width: 100,
                borderColor: "#FAAD14",
              }}
              onClick={() => setVisible__(true)}
            >
              Add
            </Button>
            <Card
              hoverable
              style={{
                width: 500,
                height: 50,
                marginTop: 10,
                marginLeft: 0,
                borderColor: "#EBEBEB",
                backgroundColor: "#EBEBEB",
              }}
            >
              <div className="stock_check" style={{ marginTop: -10 }}>
                <Checkbox />
              </div>
              <h6
                style={{
                  fontSize: 14,
                  color: "#252733",
                  marginTop: -20,
                  marginLeft: 60,
                }}
              >
                subcategories
              </h6>
              <h6
                style={{
                  fontSize: 14,
                  color: "#252733",
                  marginTop: -22,
                  marginLeft: "50%",
                }}
              >
                Quantity
              </h6>
              <div
                className="more_outline"
                style={{ marginLeft: "90%", marginTop: -30 }}
              >
                <MoreOutlined
                  style={{ fontSize: 16, fontWeight: "bold", color: "#000" }}
                />
              </div>
            </Card>
            {subCatagories?.length ? (
              <div>
                {subCatagories?.map((subcategory) => {
                  return (
                    <Card
                      hoverable
                      style={{
                        width: 500,
                        height: 50,
                        marginTop: 10,
                        marginLeft: 0,
                        borderColor: "#EBEBEB",
                      }}
                    >
                      <div className="stock_check" style={{ marginTop: -10 }}>
                        <Checkbox />
                      </div>
                      <h6
                        style={{
                          fontSize: 12,
                          color: "#8C8C8C",
                          marginTop: -20,
                          marginLeft: 60,
                        }}
                      >
                        {subcategory.name}
                      </h6>
                      <h6
                        style={{
                          fontSize: 12,
                          color: "#8C8C8C",
                          marginTop: -20,
                          marginLeft: "53%",
                        }}
                      >
                        {subcategory.products_count}
                      </h6>
                      <div
                        className="more_outline"
                        style={{ marginLeft: "90%", marginTop: -30 }}
                      >
                        <Dropdown
                          overlay={menussub(subcategory)}
                          trigger={["click"]}
                        >
                          <a onClick={(e) => e.preventDefault()}>
                            <Space>
                              <MoreOutlined
                                style={{
                                  fontSize: 16,
                                  fontWeight: "bold",
                                  color: "#000",
                                }}
                              />
                            </Space>
                          </a>
                        </Dropdown>
                      </div>
                    </Card>
                  );
                })}
              </div>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  marginTop: "20%",
                  height: "500px",
                }}
              >
                <ClipLoader size={20} color={"#FAAD14"} loading={true} />
              </div>
            )}
          </Card>
        </Col>
      </Row>
      <Modal
        className="add_catagories"
        destroyOnClose={true}
        closable={false}
        footer={null}
        open={visible}
        title={
          <div>
            <span>አዲስ ምድብ መጨመሪያ</span>
          </div>
        }
        onCancel={handleCancel}
      >
        <AddCategory handleCancel={handleCancel} />
      </Modal>
      <Modal
        open={visible_}
        destroyOnClose={true}
        footer={null}
        closable={false}
        // onOk={editPressed}
        onCancel={handleCloseCategoryEdit}
      >
        <EditCategories
          editCategory={categoryToEdit}
          handleCloseCategoryEdit={handleCloseCategoryEdit}
        />
      </Modal>
      <Modal
        open={visible__}
        footer={null}
        closable={false}
        onCancel={handleSubCancel}
        destroyOnClose={true}
      >
        <AddSubCategory handleSubCancel={handleSubCancel} />
      </Modal>

      <Modal
        open={subcatmodal}
        footer={null}
        closable={false}
        destroyOnClose={true}
        onCancel={() => {
          handleCloseSubCategoryEdit();
        }}
      >
        <EditSubCategories
          editSubCategories={subCategoryToEdit}
          handleCloseSubCategoryEdit={handleCloseSubCategoryEdit}
        />
      </Modal>

      <div style={{ marginTop: "3%", marginLeft: "6%" }}>
        <h5>በጣም የተሸጡ እቃዎች</h5>
        <div
          style={{
            marginTop: "2%",
            border: "1px solid #EBEBEB",
            width: "90%",
            marginLeft: "3%",
          }}
        >
          <Row gutter={[20, 6]} style={{ marginTop: "2%", width: "100%" }}>
            {popular?.map((pop) => {
              return (
                <Col xs={10} sm={8} md={6} xl={4} style={{ marginLeft: "3%" }}>
                  <Card
                    key={pop.id}
                    style={{ width: 200 }}
                    hoverable
                    cover={
                      <img
                        alt="popular uploads"
                        src={
                          pop?.image_paths?.length
                            ? pop?.image_paths[0]
                            : NoImage
                        }
                      />
                    }
                  >
                    <Meta title={pop.name} description={pop.price + " ብር"} />
                  </Card>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>

      {/* Stock report table */}
      <div className="content">
        <h5 style={{ marginTop: 50, marginLeft: "6%" }}>Stock report </h5>
        <Card
          className="fourth_card"
          hoverable
          style={{
            MaxWidth: 1200,
            minHeight: 550,
            marginLeft: 100,
            marginTop: 20,
            borderRadius: 10,
          }}
        >
          <Card
            className="first_card"
            hoverable
            style={{ maxWidth: 1120, height: 60, background: "#F9F9F9" }}
          >
            <Search
              placeholder="Product Name /then Enter key"
              enterButton
              style={{
                marginTop: -10,
                marginLeft: -10,
                width: 400,
                borderColor: "#F4AD33",
              }}
              onKeyUp={(e) => {
                e.key === "Enter"
                  ? setProductName(e.target.value)
                  : console.log(e.target.value);
              }}
            />
            <Dropdown
              overlay={menu}
              placement="bottom"
              arrow={{
                pointAtCenter: true,
              }}
            >
              <Button
                style={{
                  marginTop: -20,
                  marginLeft: 500,
                  height: 40,
                  background: "#F9F9F9",
                  color: "#F4AD33",
                  borderColor: "#F4AD33",
                }}
              >
                <img
                  src={VectorBar}
                  alt="vector"
                  style={{ paddingRight: 10 }}
                />{" "}
                አማራጮች
              </Button>
            </Dropdown>
          </Card>
          <Card
            className="second_card"
            hoverable
            style={{
              maxWidth: 1120,
              height: 50,
              marginTop: 20,
              background: "#EBEBEB",
            }}
          >
            <div className="links" style={{ marginTop: -15 }}>
              <Row>
                <Col span={2}>
                  <Button type="link">የእቃው ቁጥር </Button>
                </Col>
                <Col span={6}>
                  <Button type="link">የእቃው ስም </Button>
                </Col>
                <Col span={4}>
                  <Button type="link">የእቃው ዋጋ </Button>
                </Col>
                <Col span={4}>
                  <Button type="link">Stock status </Button>
                </Col>
                <Col span={4}>
                  <Button type="link">Quantity </Button>
                </Col>
                <Col span={4}>
                  <Button type="link">ቀን </Button>
                </Col>
              </Row>
            </div>
          </Card>
          {allProducts?.map((product) => {
            return (
              <Popover content={productStore} trigger="click">
                <Card
                  className="second_card"
                  onClick={() => {
                    storeProduct(product);
                  }}
                  hoverable
                  style={{
                    width: 1120,
                    height: 50,
                    marginTop: 20,
                    background: "#FFF",
                  }}
                  loading={loading}
                >
                  <Row style={{ marginTop: -15 }}>
                    <Col span={2}>#{product.id}</Col>
                    <Col span={6}>{product.name}</Col>
                    <Col span={4}>{product.price + " ብር"}</Col>
                    <Col span={4}>{tag(product.left_in_stock)}</Col>
                    <Col span={4}>{product.left_in_stock}</Col>
                    <Col span={4}>
                      {product.created_at
                        ? product.created_at.split("T")[0]
                        : ""}
                    </Col>
                  </Row>
                </Card>
              </Popover>
            );
          })}
          <div style={{ marginTop: 10 }}>
            <Pagination
              defaultCurrent={1}
              total={last_page * 10}
              onChange={(page) => {
                setPage(page);
                setLoading(true);
              }}
              showSizeChanger={false}
            />
          </div>
        </Card>
      </div>
    </div>
  );
}

export default AdminStockManagement;
