/* eslint-disable */
import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { MenuOutlined } from "@ant-design/icons";
function AdminNav(props) {
  const permissions = useSelector((state) => state.permission.permission);
  return (
    <>
      <div className="admin_nav" style={{ marginTop: "0%" }}>
        <Menu
          mode="horizontal"
          defaultSelectedKeys={props.nav_link}
          overflowedIndicator={<MenuOutlined />}
        >
          {permissions.find((permission) => {
            return permission == "Dashboard";
          }) && (
            <Menu.Item key="main_page">
              <Link to="/dashboard">
                <div className="home">ሰረገላ አድሚን</div>
              </Link>
            </Menu.Item>
          )}
          {permissions.find((permission) => {
            return permission == "Corporate Management";
          }) && (
            <Menu.Item key="main">
              <Link to="/main">ዋና ገጽ</Link>
            </Menu.Item>
          )}
          {permissions.find((permission) => {
            return permission == "Product Management";
          }) && (
            <Menu.Item key="new_products">
              <Link to="/products"> ዕቃዎች </Link>
            </Menu.Item>
          )}
          {permissions.find((permission) => {
            return permission == "Store Management";
          }) && (
            <Menu.Item key="warehouse">
              <Link to="/warehouse">ዌር ሀውስ</Link>
            </Menu.Item>
          )}
          {permissions.find((permission) => {
            return permission == "Bank Management";
          }) && (
            <Menu.Item key="bank">
              <Link to="/bank">ባንክ</Link>
            </Menu.Item>
          )}
          {permissions.find((permission) => {
            return permission == "Package Management";
          }) && (
            <Menu.Item key="package_management">
              <Link to="/package_management">Package Management</Link>
            </Menu.Item>
          )}
          {permissions.find((permission) => {
            return permission == "Delivery Type Management";
          }) && (
            <Menu.Item key="delivery_type">
              <Link to="/delivery-type">Delivery type</Link>
            </Menu.Item>
          )}
          {permissions.find((permission) => {
            return permission == "Stock Management";
          }) && (
            <Menu.Item key="stock_management">
              <Link to="/stock_management">Stock Management</Link>
            </Menu.Item>
          )}
          {permissions.find((permission) => {
            return permission == "RBAC Management";
          }) && (
            <Menu.Item key="rbac">
              <Link to="/rbac">RBAC</Link>
            </Menu.Item>
          )}
          {permissions.find((permission) => {
            return permission == "Order Management";
          }) && (
            <Menu.Item key="order_management">
              <Link to="/order_management">Order Management</Link>
            </Menu.Item>
          )}
          {permissions.find((permission) => {
            return permission == "Fulfillment Management";
          }) && (
            <Menu.Item key="fulfillment">
              <Link to="/fulfillment">Fullfillment</Link>
            </Menu.Item>
          )}

          {permissions.find((permission) => {
            return permission == "Customer Management";
          }) && (
            <Menu.Item key="customer_management">
              <Link to="/customer_management">Customer management</Link>
            </Menu.Item>
          )}

          {permissions.find((permission) => {
            return permission == "Customer Management";
          }) && (
            <Menu.Item key="customer_management">
              <Link to="/customer_management">Customer management</Link>
            </Menu.Item>
          )}

          {/* {permissions.find((permission) => {
            return permission == "Customer Management";
          }) && (
            <Menu.Item key="discount_users">
              <Link to="/discount_users">Discount Users</Link>
            </Menu.Item>
          )} */}

          {permissions.find((permission) => {
            return permission == "Advert Management";
          }) && (
            <Menu.Item key="image_advert">
              <Link to="/image_advert">Image Advert</Link>
            </Menu.Item>
          )}

          {permissions.find((permission) => {
            return permission == "Supplier Management";
          }) && (
            <Menu.Item key="supplier_management">
              <Link to="/supplier_management">Supplier Management</Link>
            </Menu.Item>
          )}
          {permissions.find((permission) => {
            return permission == "Notification Management";
          }) && (
            <Menu.Item key="push_notification">
              <Link to="/push_notification">Push Notification</Link>
            </Menu.Item>
          )}

          {permissions.find((permission) => {
            return permission == "Setting Management";
          }) && (
            <Menu.Item key="app_settings">
              <Link to="/app_settings">Site Settings</Link>
            </Menu.Item>
          )}

          {permissions.find((permission) => {
            return permission == "Recycler Management";
          }) && (
            <Menu.Item key="recycler">
              <Link to="/recycler">ሪሳይክለር</Link>
            </Menu.Item>
          )}

          {permissions.find((permission) => {
            return permission == "Report Management";
          }) && (
            <Menu.Item key="reports">
              <Link to="/report">ሪፖርት</Link>
            </Menu.Item>
          )}
        </Menu>
      </div>
    </>
  );
}

export default AdminNav;
