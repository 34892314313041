/* eslint-disable */
import React, { useState } from "react";
import api from "../adapter/base";
import AdminNav from "./admin_nav";
import {
  Row,
  Col,
  Card,
  Input,
  Button,
  Drawer,
  Modal,
  Pagination,
  message,
  Image,
  Spin,
  Carousel,
  Switch,
  Badge,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { useDebounce } from "../CorporateManager/hooks/useDebounce";
import LastSideNav from "../components/side_nav";
import EditProduct from "../drawer/edit_product";
import RegisterProduct from "../drawer/register_product";
import NoImage from "../images/No-Image.png";
import {
  useProducts,
  useActivateDeactivateProduct,
  useUpdateProductStockStatus,
} from "../hooks/useProduct";
import { useQueryClient } from "react-query";
const { Meta } = Card;

export default function NewProducts() {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [productName, setProductName] = useState();
  const [visible_, setVisible_] = useState(false);

  //pagination
  const [editVisible, setEditVisible] = useState(false);
  const [productToEdit, setProductToEdit] = useState();
  const [downloadload, setDownloadLoad] = useState(false);

  // variables to manage the category_nav_bar and products and their pagination
  const [category_id, setCategoryId] = useState();
  const [pages, setPages] = useState(1);
  const [name, setName] = useState("");
  //query client
  const client = useQueryClient();

  const [paginate, setPaginate] = useState(12);
  const debouncedValue = useDebounce(name, 400);

  // fetch products based on the passed parameters
  const { data: productss, isLoading: load_products } = useProducts(
    category_id,
    pages,
    debouncedValue,
    paginate
  );

  // mutation to update the status of product make it visible/invisible to clients
  const { mutate: activateDeactivateProduct, isLoading: load_update_product } =
    useActivateDeactivateProduct();
  // mutation to update the stockstatus of product
  const { mutation, error } = useUpdateProductStockStatus();
  const handleActivateDeactivateProductVisiblity = (product) => {
    activateDeactivateProduct(product, {
      onSuccess: (data) => {
        const name = data?.data?.data?.name;
        const status = data?.data?.data?.is_active
          ? "Activated"
          : "Deactivated";
        if (status === "Activated") {
          message.success(`${name} ${status} Successfully`);
        } else {
          message.warning(`${name} ${status}`);
        }
        client.invalidateQueries("products");
      },
      onError: (err) => {
        message.warning(err?.response?.data?.message);
      },
    });
  };

  const handlePageChange = (page, pageSize) => {
    if (pages !== page) {
      setPages(page);
    }
    if (paginate !== pageSize) {
      setPaginate(pageSize);
      setPages(1);
    }
  };

  // handle search for products
  const handleSearch = (name) => {
    setName(name);
    setPages(1);
    setCategoryId(null);
  };

  //Download All Product in Excel File
  const DownloadProducts = async () => {
    setDownloadLoad(true);
    await api
      .get("/products/export", { responseType: "blob" })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `All-products-${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        message.error("Check For Your Connection");
      })
      .finally(() => {
        setDownloadLoad(false);
      });
  };

  const ProductToEdit = (product) => {
    setProductToEdit(product);
  };
  const closeEditModal = () => {
    setEditVisible(false);
  };

  const onClose_ = () => {
    setVisible_(false);
  };
  const { Search } = Input;

  const onSucess = () => {
    message.success("ከፊት ለፊት ገጽ በተሳካ ሁኔታ ሰርዘዋል፡፡");
  };
  const onError = () => {
    message.error(
      <>
        ስራው አልተሰራም መሰረዙ አልተሳካም{" "}
        <i
          className="fa fa-exclamation-triangle"
          style={{ color: "red", font: 15 }}
        ></i>
      </>
    );
  };

  const handleOk = () => {
    setIsDeleteModalVisible(false);
    delete_product_(deleteId);
  };

  const handleCancel = () => {
    setIsDeleteModalVisible(false);
    onError();
  };

  const delete_product_ = async (id) => {
    const data = await api.delete(`products/${id}`).then((response) => {
      onSucess();
      window.location.reload(false);
    });
    return data;
  };

  // manage category changes
  const handleClick = (id) => {
    if (id === "all") {
      setCategoryId(null);
      setPages(1);
    } else {
      setCategoryId(id);
      setPages(1);
    }
  };
  const [loadingIndex, setLoadingIndex] = useState(-1);

  const handleMutate = async (product, index) => {
    setLoadingIndex(index);
    await mutation.mutate(product);
    setTimeout(() => setLoadingIndex(-1), 500); // Reset loading state after the mutation is done
  };

  return (
    <>
      <div className="new_products">
        <div className="container_fluid">
          <div className="tabs">
            <Button
              type="warning"
              style={{
                marginTop: "3%",
                marginLeft: "5%",
                backgroundColor: "#FAAD14",
              }}
              onClick={() => setVisible_(true)}
            >
              + አዲስ እቃ
            </Button>
            <Button
              style={{
                marginLeft: "2px",
                backgroundColor: "#FAAD14",
              }}
              loading={downloadload}
              onClick={() => DownloadProducts()}
            >
              <DownloadOutlined />
              All Product in Excel
            </Button>
            <Search
              placeholder="ይፈልጉ"
              enterButton
              onChange={(e) => handleSearch(e.target.value)}
              className="search-input"
              style={{
                marginTop: 40,
                marginLeft: 100,
                width: 400,
                height: 60,
                borderColor: "#F4AD33",
              }}
            />
            <div className="items">
              <Row gutter={[16, 11]}>
                <Col lg={4}>
                  <LastSideNav id={handleClick} />
                </Col>
                <Col span={20}>
                  <Row gutter={[16, 11]}>
                    {load_products ? (
                      <Spin
                        size="default"
                        color="#FAAD14"
                        style={{ marginTop: "15%", marginLeft: "25%" }}
                      />
                    ) : (
                      productss?.data?.map((product, index) => {
                        return (
                          <Col
                            xs={10}
                            md={6}
                            lg={4}
                            offset={1 / 2}
                            key={product.id}
                          >
                            <div
                              key={product.id}
                              className="hover:cursor-pointer"
                              onClick={() => handleMutate(product, index)}
                            >
                              <Badge.Ribbon
                                text={
                                  product?.is_non_stocked
                                    ? "Non-Stocked"
                                    : "Stocked"
                                }
                                color={
                                  product?.is_non_stocked
                                    ? "#1890FF"
                                    : "#FAAD14"
                                }
                                className="hover:cursor-pointer absolute top-3 right-0 bottom-0 z-10 w-[60%]" // Adjust the position and dimensions
                                onClick={(e) => e.stopPropagation()} // Prevent click event on the Badge.Ribbon from propagating
                              />
                            </div>
                            <Card
                              hoverable
                              key={index}
                              style={{ maxWidth: 214, minHeight: 400 }}
                              loading={loadingIndex === index}
                            >
                              <Image.PreviewGroup>
                                <Carousel autoplay infinite={false}>
                                  {product?.image_paths.length ? (
                                    product?.image_paths?.map(
                                      (imagepath, imgindex) => {
                                        return (
                                          <Image
                                            key={imgindex}
                                            src={imagepath}
                                            onClick={(e) => e.stopPropagation()}
                                          />
                                        );
                                      }
                                    )
                                  ) : (
                                    <Image
                                      src={NoImage}
                                      width="150"
                                      height="150"
                                      onClick={(e) => e.stopPropagation()}
                                    />
                                  )}
                                </Carousel>
                              </Image.PreviewGroup>
                              <Meta title={"# " + product.cnet_id} />
                              <Meta
                                title={product.name}
                                description={
                                  product.description?.length > 20
                                    ? product.description.slice(0, 20) + " ..."
                                    : product.description
                                }
                              />
                              <span style={{ display: "inline-block" }}>
                                <Meta
                                  title={
                                    <div className="flex items-center w-full my-3 gap-x-5">
                                      <Switch
                                        size="small"
                                        checked={product?.is_active}
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={() => {
                                          handleActivateDeactivateProductVisiblity(
                                            product
                                          );
                                        }}
                                      />
                                      <EditOutlined
                                        style={{
                                          color: "green",
                                        }}
                                        className="text-green-500 text-xl"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          ProductToEdit(product);
                                          setEditVisible(true);
                                        }}
                                      />
                                      <span></span>
                                      {/* <DeleteOutlined
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setDeleteId(product.id);
                                          setIsDeleteModalVisible(true);
                                          setProductName(product.name);
                                        }}
                                        style={{
                                          color: "red",
                                          fontSize: 14,
                                        }}
                                      /> */}
                                    </div>
                                  }
                                />
                              </span>
                              <Meta
                                description={[
                                  <div style={{ color: "green" }}>
                                    {parseFloat(product.discount) +
                                      parseFloat(product.price) !==
                                    parseFloat(product.price) ? (
                                      <>
                                        <strike style={{ color: "red" }}>
                                          {[
                                            (
                                              parseFloat(product.discount) +
                                              parseFloat(product.price)
                                            ).toFixed(2),
                                            "ብር",
                                          ].join(" ")}
                                        </strike>
                                        <br />
                                      </>
                                    ) : (
                                      ``
                                    )}
                                    {product.price + " ብር"}
                                    <br />
                                    {product.image_paths.length === 1 ||
                                    product.image_paths.length === 0
                                      ? product.image_paths.length + " image"
                                      : product.image_paths.length + " images"}
                                    <br />
                                  </div>,
                                ]}
                              />
                              <Meta
                                description={
                                  product?.left_in_stock + " ስቶክ ላይ የቀረ"
                                }
                              />
                              {product.size ? (
                                <Meta description={"መጠን፡" + product.size} />
                              ) : null}
                            </Card>
                          </Col>
                        );
                      })
                    )}
                  </Row>
                </Col>
              </Row>
              <Row gutter={[10, 15]} style={{ marginTop: "12px" }}>
                <Col span={16} offset={8}>
                  <div>
                    {productss?.meta?.last_page > 1 ? (
                      <Pagination
                        size="small"
                        showSizeChanger={true}
                        pageSize={paginate}
                        total={productss?.meta?.last_page * paginate}
                        onChange={(page, pageSize) => {
                          handlePageChange(page, pageSize);
                        }}
                        current={productss?.meta?.current_page}
                      />
                    ) : (
                      ``
                    )}
                  </div>
                </Col>
              </Row>

              <Modal
                className="delete_modal"
                closable={false}
                okText="እሽ"
                cancelText="ይቅር"
                open={isDeleteModalVisible}
                title={
                  <div>
                    <i
                      className="fa fa-trash"
                      aria-hidden="true"
                      style={{ fontSize: 20, color: "red" }}
                    ></i>{" "}
                    <span>ለማጥፋት ወስነዋል?</span>{" "}
                  </div>
                }
                onOk={handleOk}
                onCancel={handleCancel}
              >
                <p style={{ fontSize: 15 }}>
                  <b>{productName}</b> ከገጽዎ ይጥፋ?
                </p>
              </Modal>
            </div>
          </div>
        </div>

        <Drawer
          placement="right"
          onClose={onClose_}
          closable={false}
          open={visible_}
          width={800}
        >
          <div className="drawer_">
            <RegisterProduct />
          </div>
        </Drawer>
        <Drawer
          placement="right"
          onClose={() => closeEditModal()}
          closable={false}
          open={editVisible}
          width={800}
        >
          <EditProduct
            ProductToEdit={productToEdit}
            onChange={closeEditModal}
          />
        </Drawer>
      </div>
    </>
  );
}
