import AdminNav from "./admin_nav";
import AdminHeader from "./admin_header";
import { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Input,
  Space,
  message,
  Table,
  Popconfirm,
  InputNumber,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import api from "../adapter/base";
import { useQuery, useQueryClient } from "react-query";
const DeliveryType = () => {
  const [visible, setVisible] = useState(false);
  const [upd_modal, setUpd_modal] = useState(false);
  const [deliveryType, setDeliveryType] = useState();
  const { data: delivers } = useQuery("delivery-types", () => {
    return api.get("delivery-types");
  });
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [priceRanges, setPriceRanges] = useState([]);
  const [status, setStatus] = useState("");
  const [delivery_cost_ranges, setDelivery_cost_ranges] = useState([]);
  const query_client = useQueryClient();
  const [load, setLoad] = useState(false);
  const handleAdd = () => {
    if (
      deliveryType?.minimum_order_cost === undefined ||
      deliveryType?.minimum_order_cost === ""
    ) {
      setStatus("error");
      return;
    } else {
      const arr = [...priceRanges, []];
      if (arr.length > 1)
        arr[arr.length - 1]["min"] = arr[arr.length - 2]["max"];
      if (arr.length === 1)
        arr[arr.length - 1]["min"] = deliveryType.minimum_order_cost;
      setPriceRanges(arr);
      setStatus("");
    }
  };
  const clrRange = () => {
    setPriceRanges([]);
  };
  const handlechange = (values, i, minMax) => {
    const arr = [...priceRanges];
    if (minMax === 2) {
      arr[i]["max"] = values;
      if (arr.length > 1)
        arr[i]["min"] = parseFloat(arr[i - 1]["max"]) + " br ";
      if (arr.length === 1)
        arr[0]["min"] = parseFloat(deliveryType.minimum_order_cost) + " br";
    }
    if (minMax === 3) arr[i]["percentage"] = values;
    setPriceRanges(arr);
    if (arr.length > 1) arr[i]["min"] = parseFloat(arr[i - 1]["max"]) + " br";
    if (arr.length === 1)
      arr[0]["min"] = parseFloat(deliveryType.minimum_order_cost) + " br";
  };
  const delete_ = async (id) => {
    await api
      .delete(`delivery-types/${id}`)
      .then((res) => {
        message.warn("Delivery Type Deleted!!");
        query_client.invalidateQueries("delivery-types");
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  };
  const update = async () => {
    await api
      .put(`delivery-types/${deliveryType.id}`, deliveryType)
      .then((res) => {
        message.success("Delivery Type Updated Succesfully!!");
        query_client.invalidateQueries("delivery-types");
        setUpd_modal(() => false);
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return (
          String(record.name)
            .toLowerCase()
            .includes(String(value).toLowerCase()) ||
          String(record.am_name)
            .toLowerCase()
            .includes(String(value).toLowerCase()) ||
          String(record.description)
            .toLowerCase()
            .includes(String(value).toLowerCase()) ||
          String(record.am_description)
            .toLowerCase()
            .includes(String(value).toLowerCase())
        );
      },
    },
    {
      title: "Amharic Name",
      dataIndex: "am_name",
      key: "am_name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Amharic Description",
      dataIndex: "am_description",
      key: "am_description",
    },
    {
      title: "Delivery Cost Range",
      dataIndex: "delivery_cost_ranges",
      key: "delivery_cost_ranges",
    },

    {
      key: "actions",
      render: (records) => {
        return (
          <>
            <span>
              <EditOutlined
                style={{
                  color: "#F4AD33",
                  paddingLeft: "3%",
                  marginLeft: 20,
                  fontSize: 20,
                }}
                onClick={() => {
                  setDeliveryType(records?.delivery_);
                  priceRanges.length = 0;
                  setUpd_modal(true);
                }}
              />
              <Popconfirm
                title="Are You Sure To Delete?"
                onConfirm={() => delete_(records.id)}
                onCancel={() => setOpen(false)}
                okText="ሰርዝ"
                cancelText="ይቅር"
                key={records.id}
              >
                <DeleteOutlined
                  style={{ color: "red", marginLeft: 20, fontSize: 20 }}
                  onClick={(e) => e.stopPropagation()}
                />
              </Popconfirm>
            </span>
          </>
        );
      },
    },
  ];
  useEffect(() => {
    setLoad(true);
    setData([]);
    delivers?.data?.data.forEach((delivery) => {
      let cost_range = (
        <>
          {delivery["delivery_cost_ranges"]?.map((delivery_cost) => {
            if (delivery_cost.end === null) {
              return (
                <p>
                  Above {delivery_cost.start + " br"} -{" "}
                  {delivery_cost.cost_percentage + " %"}
                </p>
              );
            }
            return (
              <p>
                From {delivery_cost.start + " br"} to{" "}
                {delivery_cost.end + " br"} -{" "}
                {delivery_cost.cost_percentage + " %"}
              </p>
            );
          })}
        </>
      );
      setData((pre) => {
        return [
          ...pre,
          {
            id: delivery["id"],
            name: delivery["name"],
            am_name: delivery["name_am"],
            description: delivery["description"],
            am_description: delivery["description_am"],
            delivery_cost_ranges: cost_range,
            delivery_: delivery,
          },
        ];
      });
    });
    if (delivers) setLoad(false);
  }, [delivers]);
  useEffect(() => {
    setDelivery_cost_ranges([]);
    priceRanges?.map((pricerng) => {
      return setDelivery_cost_ranges((pre) => {
        return [
          ...pre,
          {
            cost:
              typeof pricerng.max === "undefined" ||
              typeof pricerng.max === "null" ||
              typeof pricerng.max === ""
                ? null
                : pricerng.max,
            percentage: pricerng.percentage,
          },
        ];
      });
    });
    setDeliveryType((pre) => {
      return { ...pre, delivery_cost_ranges: delivery_cost_ranges };
    });
  }, [priceRanges, delivery_cost_ranges]);
  const handleOk = () => {
    api
      .post("delivery-types", deliveryType)
      .then((res) => {
        message.success("Delivery Type Registered Successfully");
        query_client.invalidateQueries("delivery-types");
        setVisible(false);
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
    setVisible(false);
  };
  return (
    <div>
      <div className="container-fluid">
        <div className="new_company">
          <Button
            className="new_comp"
            onClick={() => {
              setDeliveryType();
              priceRanges.length = 0;
              setVisible(true);
            }}
            style={{
              marginTop: "3%",
              marginLeft: "5%",
              backgroundColor: "#FAAD14",
            }}
          >
            + አዲስ ዴሊቨሪ
          </Button>
        </div>
        <div className="container">
          <Input.Search
            onSearch={(value) => {
              setSearchText(value);
            }}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            placeholder="Search Here"
          />
          <Table dataSource={data} columns={columns} loading={load} />
        </div>
      </div>

      <Modal
        title="+ አዲስ ዴሊቨሪ"
        style={{
          top: 20,
        }}
        mask={false}
        open={visible}
        destroyOnClose={true}
        okText="ይመዝገብ"
        cancelText="ይቅር"
        onOk={() => handleOk()}
        onCancel={() => setVisible(false)}
        closable={false}
      >
        <Space direction="vertical" size={5} style={{ width: "100%" }}>
          <Input
            required
            addonBefore="Name"
            placeholder="Delivery Type Name"
            value={deliveryType?.name}
            onChange={(e) => {
              setDeliveryType((prev) => {
                return { ...prev, name: e.target.value };
              });
            }}
          />
          <Input
            required
            addonBefore="ስም በአማርኛ"
            placeholder="Delivery Name Amharic"
            value={deliveryType?.name_am}
            onChange={(e) => {
              setDeliveryType((prev) => {
                return { ...prev, name_am: e.target.value };
              });
            }}
          />
          <Input
            required
            addonBefore="Description"
            placeholder="Description"
            value={deliveryType?.description}
            onChange={(e) => {
              setDeliveryType((prev) => {
                return { ...prev, description: e.target.value };
              });
            }}
          />
          <Input
            required
            addonBefore="መግለጫ"
            placeholder="Description in Amharic"
            value={deliveryType?.description_am}
            onChange={(e) => {
              setDeliveryType((prev) => {
                return { ...prev, description_am: e.target.value };
              });
            }}
          />
          <Input
            required
            addonBefore="ከምን ያህል ቀን በኋላ ይደርሳል?"
            placeholder="Max Delay days"
            value={deliveryType?.number_of_days}
            onChange={(e) => {
              setDeliveryType((prev) => {
                return { ...prev, number_of_days: e.target.value };
              });
            }}
          />
          <Input
            required
            addonBefore="ትንሹ ዋጋ ለትራንስፖርት"
            status={status}
            placeholder="Minimum Cost For Transport Fee"
            value={deliveryType?.minimum_order_cost}
            onChange={(e) => {
              setDeliveryType((prev) => {
                return { ...prev, minimum_order_cost: e.target.value };
              });
            }}
          />
          <p>
            <Button onClick={handleAdd}>
              <PlusOutlined style={{ color: "#F4AD33", fontSize: 20 }} />
              Add Ranges
            </Button>
            {priceRanges.length > 1 && (
              <Button onClick={clrRange}>
                <MinusOutlined
                  style={{ color: "#F4AD33", fontSize: 20, maginLeft: 10 }}
                />
                Clear Ranges
              </Button>
            )}
          </p>

          {priceRanges.map((data, i) => {
            return (
              <div className="range-input-number-main" key={i}>
                <span className="range-span">From</span>
                <InputNumber
                  key={i}
                  className="min-input-main"
                  min={0}
                  value={data["min"]}
                  disabled={true}
                />
                <span className="range-span">to/Above</span>
                <InputNumber
                  className="min-input-main"
                  min={0}
                  onChange={(e) => {
                    handlechange(e, i, 2);
                  }}
                />
                <span className="range-span">Percentage</span>
                <InputNumber
                  className="min-input-main"
                  min={0}
                  onChange={(e) => {
                    handlechange(e, i, 3);
                  }}
                />
              </div>
            );
          })}
        </Space>
      </Modal>

      <Modal
        title="+ ዴሊቨሪ ማስተካከያ ቅጽ"
        style={{
          top: 20,
        }}
        mask={false}
        open={upd_modal}
        destroyOnClose={true}
        okText="ይስተካከል"
        cancelText="ይቅር"
        onOk={() => update()}
        onCancel={() => {
          setUpd_modal(false);
          setDeliveryType(null);
          priceRanges.length = 0;
        }}
      >
        <Space direction="vertical" size={5} style={{ width: "100%" }}>
          <Input
            addonBefore="Name"
            placeholder="Delivery Type Name"
            value={deliveryType?.name}
            onChange={(e) => {
              setDeliveryType((prev) => {
                return { ...prev, name: e.target.value };
              });
            }}
          />
          <Input
            addonBefore="ስም በአማርኛ"
            placeholder="Delivery Name Amharic"
            value={deliveryType?.name_am}
            onChange={(e) => {
              setDeliveryType((prev) => {
                return { ...prev, name_am: e.target.value };
              });
            }}
          />
          <Input
            addonBefore="Description"
            placeholder="Description"
            value={deliveryType?.description}
            onChange={(e) => {
              setDeliveryType((prev) => {
                return { ...prev, description: e.target.value };
              });
            }}
          />
          <Input
            addonBefore="መግለጫ"
            placeholder="Description in Amharic"
            value={deliveryType?.description_am}
            onChange={(e) => {
              setDeliveryType((prev) => {
                return { ...prev, description_am: e.target.value };
              });
            }}
          />
          <Input
            addonBefore="ከምን ያህል ቀን በኋላ ይደርሳል?"
            placeholder="Max Delay Days"
            value={deliveryType?.number_of_days}
            onChange={(e) => {
              setDeliveryType((prev) => {
                return { ...prev, number_of_days: e.target.value };
              });
            }}
          />
          <Input
            addonBefore="ትንሹ ዋጋ ለትራንስፖርት"
            status={status}
            placeholder="Minimum Cost For Transport Fee"
            value={deliveryType?.minimum_order_cost}
            onChange={(e) => {
              setDeliveryType((prev) => {
                return { ...prev, minimum_order_cost: e.target.value };
              });
            }}
          />
          <p>
            <Button onClick={handleAdd}>
              <PlusOutlined style={{ color: "#F4AD33", fontSize: 20 }} />
              Add Ranges
            </Button>
            {priceRanges.length > 1 && (
              <Button onClick={clrRange}>
                <MinusOutlined
                  style={{ color: "#F4AD33", fontSize: 20, maginLeft: 10 }}
                />
                Clear Ranges
              </Button>
            )}
          </p>
          {priceRanges.map((data, i) => {
            return (
              <div className="range-input-number-main" key={i}>
                <span className="range-span">From</span>
                <InputNumber
                  key={i}
                  className="min-input-main"
                  min={0}
                  value={data["min"]}
                  disabled={true}
                />
                <span className="range-span">to/Above</span>
                <InputNumber
                  className="min-input-main"
                  min={0}
                  onChange={(e) => {
                    handlechange(e, i, 2);
                  }}
                />
                <span className="range-span">Percentage</span>
                <InputNumber
                  className="min-input-main"
                  min={0}
                  onChange={(e) => {
                    handlechange(e, i, 3);
                  }}
                />
              </div>
            );
          })}
        </Space>
      </Modal>
    </div>
  );
};

export default DeliveryType;
