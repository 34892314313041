import axios from 'axios'
import store from '../store/index'
const api=axios.create(
    {
        baseURL:'https://seregelagebeya.com/api/v1/corporate-manager'
    }
);

api.interceptors.request.use(
    res=>{
      const state=store.getState();
      const token=state?.auth?.user?.access_token;
      if(token) res.headers["Authorization"]=`Bearer ${token}`;
      return res;
    },err=>{
        return Promise.reject(err);
    })
export default api