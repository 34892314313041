const TimesAgoUtil = (created_at) => {
  const currentDate = new Date();
  const createdDate = new Date(created_at);
  const diffTime = Math.abs(currentDate - createdDate);
  const diffSeconds = Math.floor(diffTime / 1000);
  const diffMinutes = Math.floor(diffSeconds / 60);
  const diffHours = Math.floor(diffMinutes / 60);
  const diffDays = Math.floor(diffHours / 24);
  const diffMonths = Math.floor(diffDays / 30);
  const diffYears = Math.floor(diffMonths / 12);

  if (diffSeconds < 60) {
    return diffSeconds + (diffSeconds === 1 ? " second ago" : " seconds ago");
  } else if (diffMinutes < 60) {
    return diffMinutes + (diffMinutes === 1 ? " minute ago" : " minutes ago");
  } else if (diffHours < 24) {
    return diffHours + (diffHours === 1 ? " hour ago" : " hours ago");
  } else if (diffDays < 30) {
    return diffDays + (diffDays === 1 ? " day ago" : " days ago");
  } else if (diffMonths < 12) {
    return diffMonths + (diffMonths === 1 ? " month ago" : " months ago");
  } else {
    return diffYears + (diffYears === 1 ? " year ago" : " years ago");
  }
};

export default TimesAgoUtil;
