import AdminHeader from "./admin_header";
import AdminNav from "./admin_nav";
import "../style/style.css";
import { Card, Row, Col, Divider } from "antd";
import { FiUserCheck } from "react-icons/fi";
import { MdCorporateFare } from "react-icons/md";
import { AiOutlinePullRequest } from "react-icons/ai";
import { GiSwordsPower } from "react-icons/gi";
import { CiDeliveryTruck } from "react-icons/ci";
import { BiStore } from "react-icons/bi";
import { TfiPackage } from "react-icons/tfi";
import { BsBank } from "react-icons/bs";
const LandingPage = () => {
  const { Meta } = Card;
  var data = [
    {
      name: "Customer",
      icon: <FiUserCheck />,
      description:
        "Customer management software, also referred to as customer relationship management (CRM) software, is a tool that allows businesses to better manage their customer data and interactions. ",
    },
    {
      name: "Corporate",
      icon: <MdCorporateFare />,
      description:
        "Corporate management software is a type of computer program used to help organize the operations of a company",
    },
    {
      name: "Order",
      icon: <AiOutlinePullRequest />,
      description:
        "Order Management Software is a collection of tools and systems used by businesses to handle the entire life cycle of an order.",
    },
    {
      name: "Permissions",
      icon: <GiSwordsPower />,
      description:
        "RBAC stands for Role-Based Access Control. It is a framework that allows administrators to define roles and access rights associated with those roles in order to control which users have access to particular system functions",
    },
    {
      name: "Delivery",
      icon: <CiDeliveryTruck />,
      description:
        "Delivery management software is designed to make it easier for businesses to manage a fleet of vehicles and the associated operational logistics",
    },
    {
      name: "Store",
      icon: <BiStore />,
      description:
        "Store Management in Software is a set of tools used to manage and organize data related to incoming and outgoing merchandise, customers, and store inventory.",
    },
    {
      name: "Package",
      icon: <TfiPackage />,
      description: `Some common features of a package management include the ability to:-
    - Track sales data,and orders
    - Manage inventory levels
    - Provide product and price updates to customers`,
    },
    {
      name: "Bank",
      icon: <BsBank />,
      description: `used to manage which bank works with which corporate 
    `,
    },
  ];

  return (
    <div className="landing_page">
      <div className="main">
        <h3 style={{ color: "#F9AD15" }}>Welcome To Seregela Gebeya Admin</h3>
        <div style={{ width: "60%", marginLeft: "20%" }}></div>
        <p>We serve You best</p>
      </div>
      <Divider>
        <h6>Admin Can Manage</h6>
      </Divider>

      <div>
        <Row>
          {data?.map((dt) => {
            return (
              <Col span={5} offset={1} key={dt.name}>
                <Card
                  style={{
                    width: 300,
                    marginTop: 16,
                    borderRadius: 5,
                  }}
                  loading={false}
                >
                  <Meta
                    avatar={dt.icon}
                    title={dt.name}
                    description={dt?.description}
                  />
                </Card>
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
};

export default LandingPage;
