/* eslint-disable */
import { Avatar, Tag } from "antd";

import { FieldTimeOutlined } from "@ant-design/icons";
import TimesAgoUtil from "./utils/timesAgo";

export const columns = [
  {
    dataIndex: "id",
    render: (request_id) => {
      return `# ${request_id}`;
    },
  },
  {
    title: "Customer",
    render: (record) => {
      return (
        <div className="flex gap-3">
          <Avatar size="medium">{record?.user?.profile_image_path}</Avatar>
          <div className="flex flex-col gap-1">
            <span className="text-sm">{record?.user?.name}</span>
            <span className="text-xs text-gray-500">
              registered {TimesAgoUtil(record?.user?.created_at)}
            </span>
          </div>
        </div>
      );
    },
  },
  {
    title: "Phone Number",
    dataIndex: ["user", "phone_number"],
    align: "center",
  },
  {
    title: "Approved By",
    dataIndex: ["approver", "name"],
    align: "center",
    render: (approver) => {
      return approver ?? "---";
    },
  },
  {
    title: "Requested",
    dataIndex: "created_at",
    render: (free_trip_request_date) => {
      return (
        <div className="flex items-center gap-2">
          <FieldTimeOutlined />
          {TimesAgoUtil(free_trip_request_date)}
        </div>
      );
    },
  },
  {
    align: "center",
    render: (record) => {
      if (!record?.approved_at && !record?.disapproved_at) {
        return <Tag color="gray">On Board</Tag>;
      } else {
        const status = record?.approved_at ? "Approved" : "Rejected";
        const color = status === "Approved" ? "yellow" : "red";
        return <Tag color={color}>{status}</Tag>;
      }
    },
  },
];
