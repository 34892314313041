/* eslint-disable */
import React from "react";
import api from "../adapter/base";
import { useQuery } from "react-query";
export default function LastSideNav(props) {
  const handleClick = (id) => {
    props.id(id);
  };
  const { data: home_category } = useQuery("home_cat", () => {
    return api.get(`categories`);
  });
  return (
    <div className="last_side_nav">
      <ul>
        <li className="sub">
          <i className="fa-solid fa-bars"></i>
          <a href="#">ምድቦች</a>
        </li>
        <li>
          <i
            className="fa-sharp fa-solid fa-arrow-right"
            style={{ fontSize: "10px" }}
          ></i>
          <a onClick={() => handleClick("all")}>All Products</a>
        </li>
        {home_category?.data?.data?.map((category) => {
          return (
            <li>
              <i
                className="fa-sharp fa-solid fa-arrow-right"
                style={{ fontSize: "10px" }}
              ></i>
              <a
                onClick={() => {
                  handleClick(category.id);
                }}
              >
                {category.name}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
