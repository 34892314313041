import { useEffect } from "react";
import { List, Avatar } from "antd";
const WareHouseProducts = (props) => {
  console.log(props.products);
  const data = Array.from({
    length: props.products?.length,
  }).map((_, i) => ({
    href: props.products[i].image_paths[0],
    title: props.products[i].name,
    img: props.products[i].image_paths[0],
    description: "Price " + Math.round(props.products[i].price) + " Br",
    content: props.products[i].description,
  }));
  useEffect(() => {}, [props.product]);
  return (
    <div>
      <List
        itemLayout="vertical"
        size="large"
        pagination={{
          onChange: (page) => {
            console.log(page);
          },
          pageSize: 3,
        }}
        dataSource={data}
        footer={
          <div>
            <b>Available Products in this package</b>
          </div>
        }
        renderItem={(item) => (
          <List.Item
            key={item.title}
            extra={
              <img
                width={272}
                alt="products available in package"
                src={item.img}
              />
            }
          >
            <List.Item.Meta
              avatar={<Avatar src={item.img} />}
              title={<a href={item.href}>{item.title}</a>}
              description={item.description}
            />
            {item.content}
          </List.Item>
        )}
      />
    </div>
  );
};

export default WareHouseProducts;
