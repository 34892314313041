import api from "../../adapter/base";
import { useQuery, useMutation } from "react-query";
import { message } from "antd";
// filter order based on admin inputs
const fetchSearchedOrders = async (filters = null, page = 1) => {
  const response = await api.get(
    `orders?user${filters?.order_id ? `&id=${filters?.order_id}` : ``}${
      filters?.customer_name ? `&customer_name=${filters?.customer_name}` : ``
    }${
      filters?.customer_phone_number
        ? `&customer_phone_number=${filters?.customer_phone_number}`
        : ``
    }${filters?.status ? `&status=${filters?.status}` : ``}${
      filters?.last_x_minutes
        ? `&last_x_minutes=${filters?.last_x_minutes}`
        : ``
    }${
      filters?.dateRange
        ? `&date%5Bfrom%5D=${
            filters?.dateRange[0].format().split("T")[0]
          }&date%5Buntil%5D=${filters?.dateRange[1].format().split("T")[0]}`
        : ``
    }&page=${page}`
  );
  const data = await response.data;
  return data;
};

// pending pickup orders
const fetchPendingPickUpOrders = async (page, delivery_id) => {
  const response = await api.get(
    `orders?user&unaccepted&status=pending-pickup&page=${page}${
      delivery_id ? `&delivery_type_id=${delivery_id}` : ``
    }`
  );
  return response.data;
};

// fetch pending delivery
const fetchPendingDelivery = async (page) => {
  const response = await api.get(
    `orders?user&status=pending-delivery&page=${page}`
  );
  return response.data;
};

// fetch successfully delivered orders
const fetchSuccessfullyDelivered = async (page) => {
  const response = await api.get(`orders?user&status=successful&page=${page}`);
  return response.data;
};

// fetch accepted orders
const fetchAcceptedOrders = async (page) => {
  const response = await api.get(`accepted-orders?user&page=${page}`);
  return response.data;
};

// accept orders

const acceptOrders = (id) => {
  return api.post(`orders/${id}/accept-pickup`);
};

//check delivery status
const checkDeliveryStatus = async (order_id) => {
  const data = await api.get(`orders/${order_id}/delivery-status`);
  return data;
};

// retry pickup
const retryPickUpDelivery = (order) => {
  if (order?.values?.phone_number) {
    return api.post(`orders/${order.id}/retry-pickup-delivery`, {
      driver_phone_number: order.values.phone_number,
    });
  } else {
    return api.post(`orders/${order.id}/retry-pickup-delivery`, "");
  }
};

// check trip status
const viewTripStatus = async (order_id) => {
  const tripStatus = await api.get(`orders/${order_id}/view-trip-status`);
  return tripStatus;
};

// fetch delivery-types

const deliveryTypes = async () => {
  const deliveryTypes = await api.get("delivery-types");
  return deliveryTypes.data;
};

// fetch store to approve orders
const fetchStores = async () => {
  const storess = await api.get("stores?all");
  return storess?.data?.data;
};

// approve orders
const approveOrder = (order) => {
  return api.post(`orders/${order.orderId}/approve-pickup`, order.detail);
};

// download filtered orders
const downloadFilteredOrdersInExcel = (filters = null) => {
  return api.get(
    `orders/export?${filters?.order_id ? `&id=${filters?.order_id}` : ``}${
      filters?.customer_name ? `&customer_name=${filters?.customer_name}` : ``
    }${
      filters?.customer_phone_number
        ? `&customer_phone_number=${filters?.customer_phone_number}`
        : ``
    }${filters?.status ? `&status=${filters?.status}` : ``}${
      filters?.last_x_minutes
        ? `&last_x_minutes=${filters?.last_x_minutes}`
        : ``
    }${
      filters?.dateRange
        ? `&date%5Bfrom%5D=${
            filters?.dateRange[0].format().split("T")[0]
          }&date%5Buntil%5D=${filters?.dateRange[1].format().split("T")[0]}`
        : ``
    }`,
    { responseType: "blob" }
  );
};

//download unaccepted pending pickup
const downloadUnacceptedPendingPickUpOrders = () => {
  return api.get(`orders/export?status=pending-pickup&unaccepted`, {
    responseType: "blob",
  });
};

//download accepted pending pickup
const downloadAcceptedPendingPickUpOrders = () => {
  return api.get(`orders/export?status=pending-pickup&accepted`, {
    responseType: "blob",
  });
};

//download pending delivery orders
const downloadPendingDeliveryOrders = () => {
  return api.get(`orders/export?status=pending-delivery`, {
    responseType: "blob",
  });
};

//download successfully delivered orders
const downloadSuccessfullyDelivered = async () => {
  return await api.get(`orders/export?status=successful`, {
    responseType: "blob",
  });
};

export const useOrders = (filters, page) => {
  return useQuery(["orders", page, filters], () =>
    fetchSearchedOrders(filters, page)
  );
};

export const usePendingPickupOrders = (page, delivery_id) => {
  return useQuery(["pendingPickUporders", page, delivery_id], () =>
    fetchPendingPickUpOrders(page, delivery_id)
  );
};
export const usePendingDelivery = (page) => {
  return useQuery(["pendingDeliveryOrders", page], () =>
    fetchPendingDelivery(page)
  );
};

export const useSuccessfullyDeliveredOrder = (page) => {
  return useQuery(["successfullyDeliveredOrders", page], () =>
    fetchSuccessfullyDelivered(page)
  );
};

export const useAcceptedOrders = (page) => {
  return useQuery(["acceptedOrders", page], () => fetchAcceptedOrders(page));
};

export const useAcceptOrders = () => {
  return useMutation(acceptOrders);
};

export const useDeliveryStatus = () => {
  return useMutation(checkDeliveryStatus);
};

export const useRetryPickUpDelivery = () => {
  return useMutation(retryPickUpDelivery);
};

export const useViewTripStatus = () => {
  return useMutation(viewTripStatus);
};

export const useDeliveryTypes = () => {
  return useQuery("deliveryTypes", deliveryTypes);
};

export const useAllStores = () => {
  return useQuery("allStores", fetchStores);
};

export const useApproveOrder = () => {
  return useMutation(approveOrder);
};

// use to download filtered Order in Excel
export const useExportExcel = () => {
  return useMutation(downloadFilteredOrdersInExcel);
};

// used to download unaccepted pending pickup

export const useExportUnAcceptedPendingPickUp = () => {
  return useMutation(downloadUnacceptedPendingPickUpOrders, {
    onSuccess: (data) => {
      const url = window.URL.createObjectURL(new Blob([data.data]));
      const link = document.createElement("a");
      link.href = url;

      // descriptive name for excel file
      link.setAttribute("download", "Pending Pickup orders.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    onError: (err) => {
      message.warning(err?.response?.data?.message);
    },
  });
};

// used to download accepted pending pickup

export const useExportAcceptedPendingPickUp = () => {
  return useMutation(downloadAcceptedPendingPickUpOrders, {
    onSuccess: (data) => {
      const url = window.URL.createObjectURL(new Blob([data.data]));
      const link = document.createElement("a");
      link.href = url;

      // descriptive name for excel file
      link.setAttribute("download", "Accepted Pending PickUp Orders.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    onError: (err) => {
      message.warning(err?.response?.data?.message);
    },
  });
};
// used to download  pending delivery orders

export const useExportPendingDelivery = () => {
  return useMutation(downloadPendingDeliveryOrders, {
    onSuccess: (data) => {
      const url = window.URL.createObjectURL(new Blob([data.data]));
      const link = document.createElement("a");
      link.href = url;

      // descriptive name for excel file
      link.setAttribute("download", "pending Delivery orders.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    onError: (err) => {
      message.warning(err?.response?.data?.message);
    },
  });
};

// used to download successfully delivered orders
export const useExportSuccessfullyDelivered = () => {
  return useMutation(downloadSuccessfullyDelivered, {
    onSuccess: (data) => {
      const url = window.URL.createObjectURL(new Blob([data.data]));
      const link = document.createElement("a");
      link.href = url;

      // descriptive name for excel file
      link.setAttribute("download", "successfully delivered orders.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    onError: (err) => {
      message.warning(err?.response?.data?.message);
    },
  });
};
