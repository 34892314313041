import { useEffect, useState } from "react";
import {
  Col,
  Row,
  Table,
  Button,
  Divider,
  Form,
  Input,
  message,
  Radio,
} from "antd";
import { useQuery, useQueryClient } from "react-query";
import api from "../adapter/base";
import TextArea from "antd/lib/input/TextArea";
const PushNotification = () => {
  const query_client = useQueryClient();
  let [dataSource, setDataSource] = useState([]);
  const [load, setLoad] = useState(false);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState();
  const [pageLoad, setPageLoad] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [form] = Form.useForm();
  const {
    data: notifications,
    isLoading: notification_loader,
    refetch: notification_refetcher,
  } = useQuery("all_adverts", () => {
    return api.get("/adverts", {
      params: {
        page: page,
        search: searchText,
      },
    });
  });

  const postNotification = (notification) => {
    setLoad(true);
    if (notification.audience =="all_users") {
      api
        .post("/adverts", notification)
        .then((res) => {
          message.success("Message sent successfully");
          query_client.invalidateQueries("all_adverts");
          form.resetFields();
        })
        .catch((err) => {
          message.error(err?.response?.data?.message);
        })
        .finally(() => {
          setLoad(false);
        });
    }

    if (notification.audience == "registered_users") {
      api
        .post("/notifications", notification, { params: { paginate: 9 } })
        .then((res) => {
          message.success("Message sent successfully");
          query_client.invalidateQueries("all_adverts");
          form.resetFields();
        })
        .catch((err) => {
          message.error(err?.response?.data?.message);
        })
        .finally(() => {
          setLoad(false);
        });
    }
  };

  const onFinish = (values) => {
    postNotification({
      title: values.title,
      message: values.message,
      audience: values.audience,
    });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    setDataSource([]);
    setLastPage(notifications?.data?.meta?.last_page);
    notifications?.data?.data?.map((notification) => {
      setDataSource((pre) => {
        return [
          ...pre,
          {
            key: notification?.id,
            title: notification?.title,
            date: notification?.created_at.split("T")[0],
            sent_to: notification?.is_notification
              ? "Only Registered"
              : "All Users",
            message: notification?.message,
          },
        ];
      });
    });
    setPageLoad(false);
  }, [notifications]);

  useEffect(() => {
    notification_refetcher();
  }, [page]);
  useEffect(() => {
    setPage(1);
    notification_refetcher();
  }, [searchText]);

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Sent To",
      dataIndex: "sent_to",
      key: "sent_to",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
  ];

  return (
    <div>
      <Row style={{ marginTop: "1%" }}>
        <Col span={12}>
          <Divider>Send Notification</Divider>
          <Form
            name="basic"
            labelCol={{
              span: 6,
            }}
            wrapperCol={{
              span: 10,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            form={form}
            autoComplete="off"
          >
            <Form.Item
              name="audience"
              label="Audience"
              rules={[
                {
                  required: true,
                  message: "Please Choice Your Audiences",
                },
              ]}
            >
              <Radio.Group style={{ width: "100%" }}>
                <Row>
                  <Col span={12}>
                    <Radio value="all_users">All users</Radio>
                  </Col>
                  <Col span={12}>
                    <Radio value="registered_users">Only Registered</Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please input title!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Message"
              name="message"
              rules={[
                {
                  required: true,
                  message: "Please input Message!",
                },
              ]}
            >
              <TextArea />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 6,
                span: 16,
              }}
            >
              <Button
                type="submit"
                htmlType="submit"
                loading={load}
                style={{
                  width: "50%",
                  backgroundColor: "#F8AD16",
                  borderColor: "#252733",
                }}
              >
                send
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col span={12}>
          <Divider>All Pushed Notification</Divider>
          <div className="container">
            <Input.Search
              onSearch={(value) => {
                setSearchText(value);
              }}
              onKeyUp={(e) => {
                e.key === "Enter" && setSearchText(e.target.value);
              }}
              onChange={(e) => {
                e.target.value.length == 0 && setSearchText("");
              }}
              placeholder="Search Message"
            />
            <Table
              size="small"
              loading={notification_loader || pageLoad}
              dataSource={dataSource}
              columns={columns}
              pagination={{
                total: lastPage * 10,
                showSizeChanger: false,
                onChange: (page) => {
                  setPage(page);
                  setPageLoad(true);
                },
              }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PushNotification;
