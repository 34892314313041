import api from "../adapter/base";
import { useEffect, useState } from "react";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import AdminNav from "./admin_nav";
import AdminHeader from "./admin_header";
import {
  Button,
  Table,
  Space,
  Input,
  Modal,
  message,
  Tooltip,
  Drawer,
  Tag,
} from "antd";
import { useQuery } from "react-query";
import MapSelector from "../common/mapselector";
import UpdateStore from "../drawer/updateStore";
// import GoogleMap from "../AdminComponent/google_map";
import MapBox from "../AdminComponent/mapbox";
import WareHouseProducts from "../AdminComponent/wareHouseProducts";
import qs from "qs";
const WareHouse = () => {
  const [Visible, setVisible] = useState(false);
  const [warehouse, setWarehouse] = useState();
  const [address, setAddress] = useState();
  const [data, setData] = useState([]);

  // update visiblity for edit stores
  const [vis, setVis] = useState(false);
  const [storeToupdate, setStoreToUpdate] = useState();
  const [mapModal, setMapModal] = useState(false);
  const [location, setLocation] = useState();

  const [productVisible, setProductVisible] = useState(false);
  const [product, setProduct] = useState();
  const [adddrivervisible, setAdddrivervisible] = useState(false);
  const [driver, setDriver] = useState();
  const [mobilewarehouse, setMobileWarehouse] = useState();
  const [page, setPage] = useState(null);
  const [load, setLoad] = useState(false);
  const [total_page, setTotal_page] = useState(1);

  const [searchtxt, setSearchtxt] = useState("");

  const { data: store_lists, refetch: store_lists_refetch } = useQuery(
    "store_lists",
    () => {
      return api.get("stores", {
        params: {
          user: null,
          page: page,
          name: searchtxt,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { strictNullHandling: true });
        },
      });
    }
  );
  const getplace = (place) => {
    setAddress((prev) => {
      return { ...prev, latitude: place.lat, longitude: place.lng };
    });
  };
  const deleteStore = (id) => {
    api
      .delete(`stores/${id}`)
      .then((res) => {
        message.warning("Deleted Successfully");
        window.location.reload(false);
      })
      .catch((err) => {
        message.error("ምንም ስራ አልተሰራም፡፡");
      });
  };
  const columns = [
    {
      key: "1",
      title: "Name",
      dataIndex: "name",
    },
    {
      key: "2",
      title: "Phone Number",
      dataIndex: "phone",
    },
    {
      key: "3",
      title: "Type",
      dataIndex: "type",
      render: (tag) => {
        const color = tag.includes("mobile") ? "blue" : "#F3AC33";
        return (
          <Tag color={color} key={tag}>
            {tag}
          </Tag>
        );
      },
    },
    {
      key: "4",
      title: "Latitude",
      dataIndex: "lat",
    },
    {
      key: "5",
      title: "Longitude",
      dataIndex: "long",
    },
    {
      key: "6",
      title: "Address",
      dataIndex: "address",
    },
    {
      key: "7",
      title: "Products Count",
      dataIndex: "product_count",
    },
    {
      key: "8",
      render: (records) => {
        return (
          <>
            <Tooltip title="Edit Store Detail">
              <EditOutlined
                style={{
                  color: "#F4AD33",
                  marginLeft: 20,
                  fontSize: 20,
                  marginTop: "-40px",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setStoreToUpdate(records.id);
                  setVis(true);
                }}
              />
            </Tooltip>
            <Tooltip title="Delete Store">
              <DeleteOutlined
                style={{ color: "red", marginLeft: 20, fontSize: 20 }}
                onClick={(e) => {
                  e.stopPropagation();
                  deleteStore(records.id);
                }}
              />
            </Tooltip>
            {records.lat && records.long && (
              <Tooltip title="Show Location in Map">
                <i
                  className="fa-solid fa-location-dot"
                  style={{ color: "#F4AD33", marginLeft: 20, fontSize: 19 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setLocation([records.lat, records.long]);
                    setMapModal(true);
                  }}
                ></i>
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];
  const registerDriver = () => {
    setMobileWarehouse((prev) => {
      return { ...prev, driver: driver };
    });
    api
      .post("mobile-stores", mobilewarehouse)
      .then((res) => {
        message.success(driver?.first_name + " Registered Succesfully");
        window.location.reload(false);
      })
      .catch((err) => {
        message.error(err.response.data.message, 10);
      });
  };
  const registerWarehouse = () => {
    api
      .post("/stores", warehouse)
      .then((res) => {
        message.success("Registered Successfully");
        window.location.reload(false);
      })
      .catch((err) => {
        message.warning(err?.response?.data?.message);
      });
  };
  useEffect(() => {
    setMobileWarehouse((prev) => {
      return { ...prev, driver: driver };
    });
  }, [driver]);
  useEffect(() => {
    setLoad(true);
    setData([]);
    setTotal_page(store_lists?.data?.meta?.last_page);
    store_lists?.data?.data?.map((store) => {
      setData((pre) => {
        return [
          ...pre,
          {
            id: store.id,
            name: store.name,
            phone: store?.phone_number
              ? store?.phone_number
              : store?.driver?.phone_number,
            type: store?.type,
            lat: store?.address?.latitude,
            long: store?.address?.longitude,
            address: store.address.city ? store.address.city : "?",
            product_count: store?.number_of_products,
            products: store?.products,
          },
        ];
      });
    });
    if (store_lists) setLoad(false);
  }, [store_lists]);

  useEffect(() => {
    store_lists_refetch();
  }, [page]);

  useEffect(() => {
    setPage(1);
    store_lists_refetch();
  }, [searchtxt]);
  const onRow = (record) => {
    return {
      onClick: (event) => {
        setProduct(record.products);
        setProductVisible(true);
      },
    };
  };
  return (
    <div>
      <div>
        <Button
          style={{
            marginTop: "3%",
            marginLeft: "5%",
            backgroundColor: "#FAAD14",
          }}
          onClick={() => {
            setVisible(true);
          }}
        >
          + አዲስ ዌር ሀውስ ይጨምሩ
        </Button>
        <Button
          style={{
            marginTop: "3%",
            marginLeft: "5%",
            backgroundColor: "#FAAD14",
          }}
          onClick={() => {
            setAdddrivervisible(true);
          }}
        >
          + ሹፌር ይጨምሩ
        </Button>
      </div>
      <div style={{ marginTop: "3%", width: "90%", marginLeft: "5%" }}>
        <Input.Search
          placeholder="search here"
          onChange={(e) => {
            e.target.value.length == 0 && setSearchtxt(null);
          }}
          onKeyUp={(e) => {
            e.key === "Enter" && setSearchtxt(e.target.value);
          }}
        />
        <Table
          columns={columns}
          dataSource={data}
          loading={load}
          onRow={onRow}
          pagination={{
            total: total_page * 10,
            showSizeChanger: false,
            hideOnSinglePage: true,
            onChange: (page) => {
              setPage(page);
              setLoad(true);
              // refetch();
            },
          }}
        />
      </div>

      <Modal
        title="Register WareHouse"
        style={{ top: 20 }}
        open={Visible}
        onOk={() => {
          registerWarehouse();
        }}
        onCancel={() => {
          message.warning("ምንም ስራ አልተሰራም");
          setVisible(false);
        }}
        closable={false}
        okText="ይመዝገብ"
        cancelText="ይቅር"
      >
        <Space direction="vertical" style={{ width: "80%" }}>
          <MapSelector onChanges={getplace} />
          <Input
            placeholder="የዌር ሀውስ ስም"
            onChange={(e) => {
              setWarehouse((pre) => {
                return { ...pre, name: e.target.value };
              });
            }}
          />
          <Input
            placeholder="ኢሜል"
            onChange={(e) => {
              setWarehouse((pre) => {
                return { ...pre, email: e.target.value };
              });
            }}
          />
          <Input
            placeholder="ስልክ ቁጥር"
            showCount
            maxLength={12}
            onChange={(e) => {
              setWarehouse((pre) => {
                return { ...pre, phone_number: e.target.value };
              });
            }}
          />
          <Input
            placeholder="ከተማ"
            onChange={(e) => {
              setAddress((pre) => {
                return { ...pre, city: e.target.value };
              });
              setWarehouse((pre) => {
                return { ...pre, address };
              });
            }}
          />
          <Input
            placeholder="ክፍለ ከተማ"
            onChange={(e) => {
              setAddress((pre) => {
                return { ...pre, sub_city: e.target.value };
              });
              setWarehouse((pre) => {
                return { ...pre, address };
              });
            }}
          />
          <Input
            placeholder="ወረዳ"
            onChange={(e) => {
              setAddress((pre) => {
                return { ...pre, woreda: e.target.value };
              });
              setWarehouse((pre) => {
                return { ...pre, address };
              });
            }}
          />
          <Input
            placeholder="ጎረቤት"
            onChange={(e) => {
              setAddress((pre) => {
                return { ...pre, neighborhood: e.target.value };
              });
              setWarehouse((pre) => {
                return { ...pre, address };
              });
            }}
          />
          <Input
            placeholder="የቤት ቁጥር"
            onChange={(e) => {
              setAddress((pre) => {
                return { ...pre, neighborhood: e.target.value };
              });
              setWarehouse((pre) => {
                return { ...pre, address };
              });
            }}
          />
        </Space>
      </Modal>
      <Drawer open={vis} onClose={() => setVis(false)} closable={false}>
        <UpdateStore data={storeToupdate} />
      </Drawer>
      <Modal
        title="Store On Map"
        style={{
          top: 20,
        }}
        open={mapModal}
        onOk={() => setMapModal(() => false)}
        onCancel={() => setMapModal(() => false)}
        destroyOnClose={true}
        mask={false}
      >
        {location?.length > 0 && (
          <MapBox
            latitude={parseFloat(location[0])}
            longitude={parseFloat(location[1])}
          />
        )}
      </Modal>

      <Modal
        title="+ በዌርሃውሱ ውስጥ ያሉ እቃዎች"
        style={{
          top: 20,
        }}
        width="90%"
        open={productVisible}
        onCancel={() => setProductVisible(false)}
        footer={null}
      >
        <WareHouseProducts products={product} />
      </Modal>
      <Modal
        title="Store On Map"
        style={{
          top: 20,
        }}
        width="80%"
        open={mapModal}
        onOk={() => setMapModal(false)}
        onCancel={() => setMapModal(false)}
        destroyOnClose={true}
      >
        {location?.length > 0 && (
          <MapBox latitude={location[0]} longitude={location[1]} />
        )}
      </Modal>

      <Modal
        title="+ ሹፌር መመዝገብያ ፎርም"
        style={{
          top: 20,
        }}
        width="40%"
        open={adddrivervisible}
        okText="ይመዝገብ"
        cancelText="ይቅር"
        onOk={() => registerDriver()}
        onCancel={() => {
          setAdddrivervisible(false);
        }}
      >
        <Space direction="vertical" style={{ marginLeft: "10%", width: "80%" }}>
          <Input
            addonBefore="ስም"
            placeholder="Name"
            onChange={(e) => {
              setMobileWarehouse((pre) => {
                return { ...pre, name: e.target.value };
              });
            }}
          />
          <Input
            addonBefore="የመጀመሪያ ስም"
            placeholder="Driver First Name"
            onChange={(e) => {
              setDriver((pre) => {
                return { ...pre, first_name: e.target.value };
              });
            }}
          />
          <Input
            addonBefore="የመጨረሻ ስም"
            placeholder="Driver Last Name"
            onChange={(e) => {
              setDriver((pre) => {
                return { ...pre, last_name: e.target.value };
              });
            }}
          />
          <Input
            addonBefore="ስልክ ቁጥር"
            placeholder="Phone Number"
            showCount
            maxLength={12}
            onChange={(e) => {
              setDriver((pre) => {
                return { ...pre, phone_number: e.target.value };
              });
            }}
          />
          <Input
            addonBefore="ኢሜል"
            placeholder="Email"
            onChange={(e) => {
              setDriver((pre) => {
                return { ...pre, email: e.target.value };
              });
            }}
          />
          <Input
            addonBefore="ፓስዎርድ"
            placeholder="Password"
            onChange={(e) => {
              setDriver((pre) => {
                return { ...pre, password: e.target.value };
              });
            }}
          />
          <Input
            addonBefore="እንደገና ፓስዎርድ"
            placeholder="Confirm Password"
            onChange={(e) => {
              setDriver((pre) => {
                return { ...pre, password_confirmation: e.target.value };
              });
            }}
          />
        </Space>
      </Modal>
    </div>
  );
};

export default WareHouse;
