import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import api from "../adapter/base";
import TimeCal from "../common/timecal";
import {
  Card,
  Pagination,
  List,
  Avatar,
  Modal,
  Input,
  message,
  Space,
} from "antd";
import { useNavigate } from "react-router-dom";
import {
  EditOutlined,
  DeleteOutlined,
  MoreOutlined,
  CaretDownOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import AdminHeader from "./admin_header";
import AdminNav from "./admin_nav";
import { useQueryClient } from "react-query";
const SupplierManagement = () => {
  const { data: suppliers } = useQuery("all_suppliers", () => {
    return api.get("suppliers");
  });
  const [data, setData] = useState([]);

  const [visible, setVisible] = useState();
  const [deletevisible, setDeletevisible] = useState();

  const [addVisible, setAddVisible] = useState(false);
  // const [supplierId, setSupplierId] = useState();

  const [supplier, setSupplier] = useState();
  const client = useQueryClient();
  const setmodalvisible = () => {
    setVisible(true);
  };
  const closeModalVisible = () => {
    setVisible(false);
  };

  // sort users alphabetically
  const sortlocal = () => {
    const sortedData = data.sort((a, b) => a.name.localeCompare(b.name));
    setSupplier(sortedData);
  };

  // add supplier modal visiblity controls
  const showaddVisible = () => {
    setAddVisible(true);
  };

  const closeaddVisible = () => {
    setAddVisible(false);
  };

  //delete modal visiblity button controls
  const showDeleteModal = () => {
    setDeletevisible(true);
  };

  const closeDeleteModal = () => {
    setDeletevisible(false);
  };
  //delete supplier
  const deletesupplier = async () => {
    await api
      .delete(`/suppliers/${supplier.id}`)
      .then((res) => {
        message.success("Deleted Successfully");
        client.invalidateQueries("all_suppliers");
        closeDeleteModal();
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
      });
  };
  // supplier information updated here
  const updatesupplier = async () => {
    await api
      .put(`suppliers/${supplier.id}`, supplier)
      .then((res) => {
        message.success("Updated successfully");
        client.invalidateQueries("all_suppliers");
        closeModalVisible();
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  };

  const registerSupplier = () => {
    api
      .post("suppliers", supplier)
      .then((res) => {
        message.success("registered succesfully");
        client.invalidateQueries("all_suppliers");
        closeaddVisible();
      })
      .catch((err) => {
        console.log(err);
        message.warning(err?.response?.data?.message);
      });
  };
  useEffect(() => {
    setData([]);
    suppliers?.data?.data?.map((supplier) => {
      return setData((pre) => {
        return [...pre, supplier];
      });
    });
  }, [suppliers]);
  return (
    <div>
      <Card
        className="admin_table"
        style={{
          maxHeight: 660,
          marginTop: "2.5%",
          marginLeft: "12%",
          borderRadius: 10,
          borderColor: "#DFE0EB",
        }}
      >
        <div className="topHeader">
          <h6 style={{ color: "#252733", fontWeight: "bold", fontSize: 18 }}>
            Merchants
          </h6>
          <div className="admin_table_icons" style={{ marginLeft: "87%" }}>
            <i
              style={{ color: "#C5C7CD" }}
              className="fa-solid fa-sort"
              onClick={sortlocal}
            ></i>
            <span style={{ color: "#4B506D" }}>Sort</span>
            <div style={{ paddingLeft: "20%", display: "inline" }}></div>
            <i style={{ color: "#C5C7CD" }} className="fa-solid fa-filter"></i>
            <span style={{ color: "#4B506D" }}>Filter</span>
          </div>
        </div>
        <div className="table_column">
          <h6
            style={{
              color: "#C5C7CD",
              marginLeft: "0%",
              fontSize: 14,
              marginTop: "5%",
            }}
          >
            Merchant Name
          </h6>
          <h6
            style={{
              color: "#C5C7CD",
              marginTop: -27,
              marginLeft: "69%",
              fontSize: 14,
            }}
          >
            Edit
          </h6>
          <h6
            style={{
              color: "#C5C7CD",
              marginTop: -25,
              marginLeft: "82%",
              fontSize: 14,
            }}
          >
            Delete
          </h6>
          <h6
            style={{
              color: "#F4AD33",
              marginTop: -35,
              marginLeft: "95%",
              fontSize: 20,
            }}
          >
            <PlusOutlined
              onClick={() => {
                showaddVisible();
                setSupplier(null);
              }}
            />
          </h6>

          {/* <h6 style={{color:'#C5C7CD',marginTop:-23,marginLeft:"85%", fontSize:14}} >Deactivate</h6>   */}
        </div>
        <div className="bottom_border"></div>
        <List
          itemLayout="horizontal"
          dataSource={data}
          style={{ marginTop: "1%", width: "100%" }}
          loading={data.length !== 0 ? false : true}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar
                    src={item.image_path}
                    style={{ width: 40, height: 40 }}
                  />
                }
                title={item.name}
                description={
                  <h6 style={{ fontSize: 11, color: "#C5C7CD" }}>
                    {TimeCal(item.created_at)}
                  </h6>
                }
              />
              <EditOutlined
                style={{ color: "#F4AD33", marginLeft: "-42%", fontSize: 20 }}
                onClick={() => {
                  setSupplier(item);
                  setmodalvisible();
                }}
              />
              <DeleteOutlined
                style={{ color: "#F4AD33", fontSize: 20 }}
                onClick={() => {
                  setSupplier(item);
                  showDeleteModal();
                }}
              />
              {/* <Switch style={{backgroundColor: '#BFBFBF',fontSize:15}} defaultChecked/> */}
              <MoreOutlined style={{ fontSize: 20, color: "#C5C7CD" }} />
            </List.Item>
          )}
        />
        <div className="bottom_border"></div>
        <div className="admin_pagination" style={{ marginLeft: "67%" }}>
          <Pagination
            style={{ color: "#BFBFBF", marginTop: "12px", marginLeft: "55%" }}
            size="small"
            total={20}
          />
        </div>
      </Card>

      <Modal
        className="supplierEditModal"
        title="Edit Merchants"
        okText="ይስተካከል"
        cancelText="ይቅር"
        style={{ textAlign: "center" }}
        closable={false}
        open={visible}
        destroyOnClose={true}
        onOk={() => updatesupplier()}
        onCancel={() => {
          message.warning("ምንም ስራ አልተሰራም");
          setVisible(false);
        }}
      >
        <Space direction="vertical">
          <Input
            addonBefore="ስም"
            value={supplier?.name}
            onChange={(e) => {
              setSupplier({ ...supplier, name: e.target.value });
            }}
          />
          <Input
            addonBefore="ስም በአማርኛ"
            value={supplier?.name_am}
            onChange={(e) => {
              setSupplier({ ...supplier, name_am: e.target.value });
            }}
          />
          <Input
            addonBefore="ስልክ ቁጥር"
            value={supplier?.phone_number}
            onChange={(e) => {
              setSupplier({ ...supplier, phone_number: e.target.value });
            }}
          />
          <Input
            addonBefore="ከተማ"
            value={supplier?.address?.city}
            onChange={(e) => {
              setSupplier({
                ...supplier,
                address: { ...supplier.address, city: e.target.value },
              });
            }}
          />
          <Input
            addonBefore="ክፍለ ከተማ"
            value={supplier?.address?.sub_city}
            onChange={(e) => {
              setSupplier({
                ...supplier,
                address: { ...supplier.address, sub_city: e.target.value },
              });
            }}
          />
          <Input
            addonBefore="ወረዳ"
            value={supplier?.address?.woreda}
            onChange={(e) => {
              setSupplier({
                ...supplier,
                address: { ...supplier.address, woreda: e.target.value },
              });
            }}
          />
          <Input
            addonBefore="ጎረቤት"
            value={supplier?.address?.neighborhood}
            onChange={(e) => {
              setSupplier({
                ...supplier,
                address: { ...supplier.address, neighborhood: e.target.value },
              });
            }}
          />
          <Input
            addonBefore="የቤት ቁጥር"
            value={supplier?.address?.house_number}
            onChange={(e) => {
              setSupplier({
                ...supplier,
                address: { ...supplier.address, house_number: e.target.value },
              });
            }}
          />
        </Space>
      </Modal>

      <Modal
        title="Add Merchants"
        okText="ይጨምሩ"
        cancelText="ይቅር"
        style={{ textAlign: "center" }}
        closable={false}
        open={addVisible}
        onOk={() => registerSupplier()}
        destroyOnClose={true}
        onCancel={() => {
          message.warning("ምንም ስራ አልተሰራም");
          setAddVisible(false);
        }}
      >
        <Space direction="vertical">
          <Input
            addonBefore="ስም"
            onChange={(e) =>
              setSupplier((pre) => {
                return { ...pre, name: e.target.value };
              })
            }
          />
          <Input
            addonBefore="ስም በአማርኛ"
            onChange={(e) =>
              setSupplier((pre) => {
                return { ...pre, name_am: e.target.value };
              })
            }
          />
          <Input
            addonBefore="ስልክ ቁጥር"
            placeholder="2519...."
            maxLength={12}
            showCount
            onChange={(e) =>
              setSupplier((pre) => {
                return { ...pre, phone_number: e.target.value };
              })
            }
          />
          <Input
            addonBefore="ከተማ"
            onChange={(e) => {
              setSupplier({
                ...supplier,
                address: { ...supplier.address, city: e.target.value },
              });
            }}
          />
          <Input
            addonBefore="ክፍለ ከተማ"
            onChange={(e) => {
              setSupplier({
                ...supplier,
                address: { ...supplier.address, sub_city: e.target.value },
              });
            }}
          />
          <Input
            addonBefore="ወረዳ"
            onChange={(e) => {
              setSupplier({
                ...supplier,
                address: { ...supplier.address, woreda: e.target.value },
              });
            }}
          />
          <Input
            addonBefore="ጎረቤት"
            onChange={(e) => {
              setSupplier({
                ...supplier,
                address: { ...supplier.address, neighborhood: e.target.value },
              });
            }}
          />
          <Input
            addonBefore="የቤት ቁጥር"
            onChange={(e) => {
              setSupplier({
                ...supplier,
                address: { ...supplier.address, house_number: e.target.value },
              });
            }}
          />
        </Space>
      </Modal>

      <Modal
        title={<h4>Sure To Delete Merchant?</h4>}
        style={{ textAlign: "center" }}
        okText="ይጥፋ"
        cancelText="ይቅር"
        closable={false}
        open={deletevisible}
        destroyOnClose={true}
        onOk={() => deletesupplier()}
        onCancel={() => {
          message.warning("ምንም ስራ አልተሰራም");
          closeDeleteModal();
        }}
      >
        <p>
          Are you sure you want to delete{" "}
          <b style={{ color: "#FAAD14" }}>{supplier?.name}</b> account?
        </p>
      </Modal>
    </div>
  );
};

export default SupplierManagement;
