/* eslint-disable */
import api from "../../adapter/base";
import { useQuery, useMutation } from "react-query";
// fetch customers
const customers = async (
  queryParam = "name",
  page = 1,
  searchTerm = "",
  byPass,
  specialDiscountUser
) => {
  if (!specialDiscountUser) {
    const res = await api.get(
      `customers?page=${page}${
        searchTerm ? `&${queryParam}=${searchTerm}` : ``
      }${byPass ? `&bypass_product_quantity_restriction=1` : ``}${
        specialDiscountUser ? `&discount_type=3` : ``
      }`
    );
    return res.data;
  } else {
    const res = await api.get(`/discount-types/3/users`);
    return res.data;
  }
};

// update customer information
const updateCustomer = (user) => {
  return api.put(`users/${user.id}`, user.data);
};

//export customer info into excel
const downloadCustomerInformation = async () => {
  const res = await api.get("users/export", { responseType: "blob" });
  return res;
};

// acivate and deactivate customer
const activateDeactivate = (customer) => {
  const updateCustomerStatus = { is_active: !customer.is_active ? 1 : 0 };
  return api.put(`users/${customer?.id}`, updateCustomerStatus);
};

// by pass max buying limit
const byPassMaxBuyingLimit = (customer) => {
  const updatedByPassQuantityRestriction = {
    bypass_product_quantity_restriction:
      !customer.bypass_product_quantity_restriction ? 1 : 0,
  };
  return api.put(`users/${customer?.id}`, updatedByPassQuantityRestriction);
};
// used to activate/deactivate special discount for customers
const activateDeactivateSpecialDiscount = (customer) => {
  return !customer?.special_discount
    ? api.post(`/discount-types/3/attach-users`, { user_ids: [customer.id] })
    : api.post(`/discount-types/3/detach-users`, { user_ids: [customer.id] });
};

// query to fetch customers
export const useCustomers = (
  queryParam,
  page,
  term,
  bypass,
  specialDiscountUser
) => {
  return useQuery(
    ["customers", queryParam, page, term, bypass, specialDiscountUser],
    () => customers(queryParam, page, term, bypass, specialDiscountUser)
  );
};

// query to update customer
export const useEditUser = () => {
  return useMutation(updateCustomer);
};

// query to activate/deactivate customer
export const useActivateDeactivateCustomer = () => {
  return useMutation(activateDeactivate);
};

// query to download customer information
export const useDownloadCustomerInformation = () => {
  return useMutation(downloadCustomerInformation);
};

// query to update bypass_quantity_restriction

export const usebyPassMaxBuyingLimit = () => {
  return useMutation(byPassMaxBuyingLimit);
};

export const useUpdateCustomerSpecialDiscount = () => {
  return useMutation(activateDeactivateSpecialDiscount);
};
