/* eslint-disable */
import { Form, Input, Button, Divider, message } from "antd";
import { useEffect, useState } from "react";
import { useEditUser } from "../hooks/useCustomer";
import { useQueryClient } from "react-query";
const UpdateCustomer = ({ customerForOperations, closeEditCustomerModal }) => {
  const [form] = Form.useForm();
  const [initialData, setInitialData] = useState();

  //mutation used to update customer information
  const { mutate: updateCustomer, isLoading: load } = useEditUser();

  const client = useQueryClient();

  useEffect(() => {
    setInitialData(customerForOperations);
    form.setFieldsValue(initialData);
  }, [form, initialData, customerForOperations]);

  const onFinish = (values) => {
    Object?.keys(values)?.map((key) => {
      if (
        Object.is(values[key], null) ||
        Object.is(values[key], undefined) ||
        Object.is(values[key], "")
      ) {
        delete values[key];
        return;
      }
    });

    if (values?.phone_number?.length < 12) {
      message.warning("phone Number Must be 12 digits(2519.....(No '+' sign))");
      return;
    }

    updateCustomer(
      { id: customerForOperations?.id, data: values },
      {
        onSuccess: () => {
          message.success("Customer Information Updated Successfully");
          client.invalidateQueries("customers");
          closeEditCustomerModal();
        },
        onError: (error) => {
          message.error(error?.response?.data?.message);
        },
      }
    );
  };

  return (
    <Form onFinish={onFinish} form={form} layout="vertical" className="-mt-5">
      <Form.Item
        name="user_name"
        label="User Name"
        rules={[
          {
            required: true,
            message: "User Name is Required",
          },
          {
            validator: (_, value) =>
              !value?.includes(" ")
                ? Promise.resolve()
                : Promise.reject(new Error("No spaces allowed For User Name")),
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="first_name"
        label="First Name"
        rules={[
          {
            required: true,
            message: "First Name is Required",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="last_name"
        label="Last Name"
        rules={[
          {
            required: true,
            message: "Last Name is Required",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="phone_number"
        label="Phone Name"
        rules={[
          {
            required: true,
            message: "Phone Number is Required",
          },
        ]}
      >
        <Input maxLength={12} showCount />
      </Form.Item>
      <Form.Item name="email" label="Email">
        <Input disabled />
      </Form.Item>
      <Divider>Customer Address</Divider>
      <Form.Item name={["address", "city"]} label="City">
        <Input />
      </Form.Item>
      <Form.Item name={["address", "sub_city"]} label="SubCity">
        <Input />
      </Form.Item>
      <Form.Item name={["address", "woreda"]} label="Woreda">
        <Input />
      </Form.Item>
      <Form.Item name={["address", "neighborhood"]} label="Neighborhood">
        <Input />
      </Form.Item>
      <Form.Item name={["address", "house_number"]} label="House Number">
        <Input />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" loading={load}>
          update
        </Button>
      </Form.Item>
    </Form>
  );
};

export default UpdateCustomer;
