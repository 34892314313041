/* eslint-disable */
import { useEffect, useState } from "react";
import { Input, Select, DatePicker, Tooltip, Form } from "antd";
import tw from "tailwind-styled-components";
import { useDebounce } from "./hooks/useDebounce";
const { Option } = Select;
const { RangePicker } = DatePicker;

const StyledInputContainer = tw.div`
  flex flex-col mr-2 -mb-2 sm:w-auto 
`;

const StyledInput = tw(Input)`
  block w-full px-4 py-2 placeholder-gray-400 transition duration-150 ease-in-out border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-primary focus:border-primary sm:text-sm sm:leading-5
  ::placeholder {
    color: gray;
    opacity: 0.6;
  }
`;

const StyledSelect = tw(Select)`
  w-full px-2
`;

const SearchBar = ({ onFiltersChange }) => {
  const [form] = Form.useForm();
  const [searchTerms, setSearchTerm] = useState();
  const debouncedValue = useDebounce(searchTerms, 400);
  const handleFormChange = (values) => {
    const newFilters = {
      ...form.getFieldsValue(),
      ...values,
    };
    setSearchTerm(newFilters);
  };

  useEffect(() => {
    onFiltersChange(debouncedValue);
  }, [debouncedValue]);

  return (
    <div className="w-full flex">
      <div className="flex flex-row justify-start items-center w-full sm:max-w-md md:max-w-7xl ">
        <Form
          onFieldsChange={handleFormChange}
          form={form}
          initialValues={{}}
          // layout="inline"
          className="flex flex-row w-full md:flex-col"
        >
          <StyledInputContainer>
            <Form.Item name="status">
              <StyledSelect placeholder="Order Status">
                <Option value="">All</Option>
                <Option value="pending-pickup">Pending Pickup</Option>
                <Option value="pending-delivery">Pending Delivery</Option>
                <Option value="unpaid">Unpaid</Option>
                <Option value="successful">Successful</Option>
              </StyledSelect>
            </Form.Item>
          </StyledInputContainer>
          <StyledInputContainer>
            <Form.Item name="customer_name">
              <StyledInput placeholder="Customer Name" />
            </Form.Item>
          </StyledInputContainer>
          <StyledInputContainer>
            <Form.Item name="order_id">
              <StyledInput placeholder="Order ID" />
            </Form.Item>
          </StyledInputContainer>
          <StyledInputContainer>
            <Tooltip title="See Orders in the specified minutes">
              <Form.Item name="last_x_minutes">
                <StyledInput placeholder="Orders In minutes" />
              </Form.Item>
            </Tooltip>
          </StyledInputContainer>
          <StyledInputContainer>
            <Form.Item name="customer_phone_number">
              <StyledInput placeholder="Phone Number" />
            </Form.Item>
          </StyledInputContainer>
          <StyledInputContainer>
            <Form.Item name="dateRange">
              <RangePicker />
            </Form.Item>
          </StyledInputContainer>
        </Form>
      </div>
    </div>
  );
};

export default SearchBar;
