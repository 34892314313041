/* eslint-disable */
import { Row, Col, Form, Input, Button, message, Divider, Select } from "antd";
import { useBanks } from "../../hooks/useBankData";
import { useAddCorporateData } from "../hooks/useCorporates";
import { useQueryClient } from "react-query";
const RegisterCorporate = () => {
  const { mutate: addCorporate, isLoading: loading } = useAddCorporateData();
  const { allBanks, bankLoad } = useBanks();
  const [form] = Form.useForm();
  const { Option } = Select;
  const client = useQueryClient();

  const onFinish = (values) => {
    Object.keys(values).map((key) => {
      if (
        Object.is(values[key], null) ||
        Object.is(values[key], undefined) ||
        Object.is(values[key], "")
      ) {
        delete values[key];
        return;
      }
    });
    const address = {};
    Object.keys(values).map((key) => {
      if (
        key == "city" ||
        key == "sub_city" ||
        key == "house_number" ||
        key == "woreda"
      ) {
        address[key] = values[key];
        delete values[key];
      }
    });
    values.address = address;
    addCorporate(values, {
      onSuccess: (data) => {
        message.success(
          data?.data?.data?.name + " Corporate Registered Successfully"
        );
        client.invalidateQueries("corporates");
        form.resetFields();
      },
      onError: (err) => {
        message.error(err?.response?.data?.message);
      },
    });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <div>
        <Form
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
          layout="vertical"
        >
          <h2>+ አዲስ ድርጅት ምዝገባ</h2>
          <Divider />
          <h4>መግቢያ</h4>

          <Row>
            <Col span={11}>
              <Form.Item
                label="የድርጅቱ ስም"
                name="name"
                rules={[
                  {
                    required: true,
                    message:
                      "Please input Corporate Name and It must be unique!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} offset={1}>
              <Form.Item
                label="ዘርፍ"
                name="type"
                rules={[
                  {
                    required: true,
                    message: "Please input corporate type",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item
                label="የሰራተኛ ብዛት"
                name="number_of_employees"
                rules={[
                  {
                    required: true,
                    message:
                      "Please Enter Number of employees the field is required",
                  },
                ]}
              >
                <Input type="number" min={1} />
              </Form.Item>
            </Col>
          </Row>

          <h4>አድራሻ</h4>

          <Row>
            <Col span={11}>
              <Form.Item
                label="የከተማ ስም"
                name="city"
                rules={[
                  {
                    required: true,
                    message: "Please input City Name it is required field",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} offset={1}>
              <Form.Item
                label="ክፍለ ከተማ"
                name="sub_city"
                rules={[
                  {
                    required: true,
                    message: "Please input Subcity Name it is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={11}>
              <Form.Item label="ወረዳ" name="woreda">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} offset={1}>
              <Form.Item label="የቤት ቁጥር" name="house_number">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item
                label="ስልክ ቁጥር"
                name="phone_number"
                rules={[
                  {
                    required: true,
                    message: "Please input phone Number it is required field",
                  },
                ]}
              >
                <Input maxLength={12} showCount placeholder="2519..." />
              </Form.Item>
            </Col>
          </Row>

          <h4>ስለ ድርጅቱ</h4>
          <Row>
            <Col span={11}>
              <Form.Item label="የካፕ መጠን" name="loan_cap">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} offset={1}>
              <Form.Item label="አብሮ ሚሰራ ባንክ" name="bank_id">
                <Select loading={bankLoad}>
                  {allBanks?.map((bank) => {
                    return (
                      <Option value={bank.id} key={bank.id}>
                        {bank.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="ሁኔታ" name="status">
                <Select>
                  <Option value="accepted">ተፈቅዷል</Option>
                  <Option value="pending">እይታ ላይ</Option>
                  <Option value="rejected">የታገደ</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={6}></Col>
            <Col span={12}>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ backgroundColor: "#F4AD33", width: "100%" }}
                  loading={loading}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    fill="currentColor"
                    className="bi bi-save"
                    viewBox="0 0 16 16"
                  >
                    {" "}
                    <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z" />{" "}
                  </svg>
                  መዝግብ
                </Button>
              </Form.Item>
            </Col>
            <Col span={6}></Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default RegisterCorporate;
