/* eslint-disable */
import { Modal, Table, Divider } from "antd";
import tw from "tailwind-styled-components";
import { packageColumns, productColumns } from "./static/TableColumns";
const StyledTable = tw(Table)`
  mt-4
`;

const OrderedProducts = ({ visible, onClose, recordData }) => {
  const foolter = (
    <div className="grid grid-cols-6 gap-2">
      <div className="text-xs font-bold w-[140%]">
        {recordData?.discount_type_name ?? ``}
      </div>
      <div className="text-xs font-bold text-center">
        {recordData?.discount_percentage
          ? [recordData?.discount_percentage, "%"].join(" ")
          : ``}
      </div>
      <div />
      <div className="text-base font-bold text-center">የሁሉም ድምር</div>
      <div className="text-base font-bold w-[160%]">
        {recordData?.total_cost.toFixed(2)} ብር
      </div>
      <div />
    </div>
  );
  return (
    <Modal open={visible} onCancel={onClose} footer={foolter} mask={false}>
      {recordData?.products?.length > 0 ? (
        <div>
          <Divider>
            <div className="text-base font-bold text-center ">
              የተገዙ እቃዎች ዝርዝር
            </div>
          </Divider>
          <StyledTable
            columns={productColumns}
            dataSource={recordData?.products?.map((dt) => ({
              ...dt,
              key: dt.id,
            }))}
            pagination={false}
            size="small"
          />
        </div>
      ) : (
        ` `
      )}
      {recordData?.packages?.length > 0 ? (
        <div>
          <Divider>
            <div className="text-base font-bold text-center ">
              የተገዙ ፓኬጆች ዝርዝር
            </div>
          </Divider>
          <StyledTable
            columns={packageColumns}
            dataSource={recordData?.packages?.map((dt) => ({
              ...dt,
              key: dt.id,
            }))}
            pagination={false}
            size="small"
          />
        </div>
      ) : (
        ` `
      )}
    </Modal>
  );
};

export default OrderedProducts;
