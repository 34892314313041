import api from '../adapter/base'


export const postUser=(userData)=>{
    return api.post('/system-users',userData);
}

export const updateUser=(id,userData)=>{
    return api.post(`/system-users/${id}`,userData);
}

export const deleteUser=(id)=>{
    return api.delete(`/system-users/${id}`);
}



