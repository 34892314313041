/* eslint-disable */
import { useEffect } from "react";
import { Button, Table, message, Form, Popover, Input } from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";
import tw from "tailwind-styled-components";
import { tabColumns } from "./static/TableColumns";
import OrderedProdcutsAndPackage from "./orderedProductForExpandable";
import {
  usePendingDelivery,
  useDeliveryStatus,
  useRetryPickUpDelivery,
  useViewTripStatus,
  useExportPendingDelivery,
} from "./hooks/useOrderData";
import { FileExcelOutlined } from "@ant-design/icons";
import { useState } from "react";
import MapBox from "./map/mapbox";
import { useSelector } from "react-redux";
import echo from "../ws_config/ws";
import { useQueryClient } from "react-query";
const StyledTableContainer = tw.div`
  mx-auto max-w-screen
`;
const StyledTable = tw(Table)`
  overflow-x-auto w-full
`;

const PendingDeliverys = () => {
  const [page, setPage] = useState(1);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const client = useQueryClient();
  const permissions = useSelector((state) => state.permission.permission);

  const handleLocation = (location) => {
    setSelectedLocation(location);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const { data, isLoading: load } = usePendingDelivery(page);
  // handle pagination changes
  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };

  // mutation for table buttons
  const { mutate: check_delivery_status, isLoading: load_delivery_status } =
    useDeliveryStatus();
  const { mutate: retry_pickup_delivery, isLoading: load_retry_pickup } =
    useRetryPickUpDelivery();
  const { mutate: viewTrip, isLoading: load_view_trip } = useViewTripStatus();
  // mutation to download excel file
  const { mutate: download_excel, isLoading: load_excel } =
    useExportPendingDelivery();

  // functions to handle mutation responses

  // check status of delivery
  const checkStatus = (order_id) => {
    check_delivery_status(order_id, {
      onSuccess: (data) => {
        const res_message = data?.data?.status.includes("_")
          ? data?.data?.status.split("_").join(" ").toLowerCase()
          : data?.data?.status;
        message.success(res_message);
      },
      onError: (err) => {
        message.warning(err?.response?.data?.message);
      },
    });
  };

  // retry pickup
  const retryPickup = (order) => {
    retry_pickup_delivery(
      { values: order.values, id: order.id },
      {
        onSuccess: (data) => {
          message.success(data?.data?.message);
        },
        onError: (error) => {
          message.warning(error?.response?.data?.message);
        },
      }
    );
  };

  // handle view trips
  const handleViewTrip = (order_id) => {
    viewTrip(order_id, {
      onSuccess: (data) => {
        const res_message = data?.data?.message?.includes(":")
          ? data?.data?.message.split(":")[1]
          : data?.data?.message;
        if (data?.data?.message?.includes(":")) message.warning(res_message);
        else window.open(data?.data?.url, "_blank");
      },
      onError: (err) => {
        message.warning(err?.response?.data?.message);
      },
    });
  };

  // variable and form to add diver phoneNumber inOrder to retry pickup
  const [form] = Form.useForm();

  // add driver phone number to retry pickup
  const content = (id) => {
    return (
      <Form
        onFinish={(e) => retryPickup({ values: e, id: id })}
        form={form}
        layout="vertical"
      >
        <Form.Item name="phone_number" label="Driver Phone Number(Optional)">
          <Input
            maxLength={12}
            showCount={true}
            title="you can retry pickup  without driver phone number (it is an optional field)"
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        </Form.Item>
        <Form.Item>
          <Button
            htmlType="submit"
            onClick={(e) => {
              e.stopPropagation();
            }}
            loading={load_retry_pickup}
          >
            Retry Pickup
          </Button>
        </Form.Item>
      </Form>
    );
  };

  //table column
  const columns = [
    ...tabColumns,
    {
      title: "Delivery",
      dataIndex: ["delivery_type", "name"],
      key: "delivery_type",
    },
    {
      title: "ApprovedBy",
      dataIndex: ["approver", "name"],
      key: "approved_by",
      render: (approver) => {
        return approver ? approver : "?";
      },
    },
    {
      title: "ApprovedAt",
      dataIndex: "approved_at",
      key: "approved_at",
    },
    {
      title: "Location",
      key: "",
      render: (record) => (
        <EnvironmentOutlined
          style={{ fontSize: 24, color: "#F1AC1D", cursor: "pointer" }}
          title="Click to view location on Map"
          aria-label="Location marker"
          onClick={() => {
            handleLocation({
              lat: parseFloat(record.shipping_detail.latitude),
              lng: parseFloat(record.shipping_detail.longitude),
            });
          }}
        />
      ),
    },
    {
      width: "32%",
      title: (
        <div>
          {permissions.find((permission) => {
            return permission == "Report Management";
          }) && (
            <FileExcelOutlined
              title="Download Excel File"
              style={{ fontSize: "22px", color: "#79BF59" }}
              onClick={() => download_excel()}
            />
          )}
        </div>
      ),
      dataIndex: "id",
      key: "Actions",
      render: (order_id, index) => {
        return (
          <div key={index}>
            <Button
              key={index}
              onClick={(e) => {
                e.stopPropagation();
                checkStatus(order_id);
              }}
            >
              CheckStatus
            </Button>
            <Popover content={content(order_id)} trigger="click">
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                RetryPickUp
              </Button>
            </Popover>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                handleViewTrip(order_id);
              }}
            >
              ViewTrip
            </Button>
          </div>
        );
      },
    },
  ];

  // listen for approved orders socket
  useEffect(() => {
    echo.private(`orders.status-change`).listen("OrderApproved", (data) => {
      client.invalidateQueries("pendingDeliveryOrders");
    });

    return () => {
      echo.leave(`orders.status-change`);
    };
  }, []);

  return (
    <StyledTableContainer>
      <StyledTable
        columns={columns}
        rowKey="id"
        dataSource={data?.data?.map((dt) => ({ ...dt, key: dt.id, ID: dt.id }))}
        pagination={
          data?.meta?.last_page > 1
            ? {
                current: page,
                position: "both center",
                total: data?.meta?.last_page * 10,
                showSizeChanger: false,
              }
            : false
        }
        onChange={handleTableChange}
        loading={
          load ||
          load_delivery_status ||
          load_retry_pickup ||
          load_view_trip ||
          load_excel
        }
        size="small"
        expandable={{
          expandedRowRender: (record) => (
            <OrderedProdcutsAndPackage recordData={record} />
          ),
        }}
      />
      {selectedLocation && modalVisible && (
        <MapBox
          visible={modalVisible}
          latitude={selectedLocation.lat}
          longitude={selectedLocation.lng}
          onClose={handleCloseModal}
        />
      )}
    </StyledTableContainer>
  );
};

export default PendingDeliverys;
