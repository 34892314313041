import { useEffect, useState } from "react";
import { List, Avatar, Popover, Table } from "antd";
const PackageInfo = (props) => {
  console.log(props.package);
  const data = Array.from({
    length: props.package.products.length,
  }).map((_, i) => ({
    href: props.package.products[i].image_paths[0],
    title: props.package.products[i].name,
    img: props.package.products[i].image_paths[0],
    description: "Price " + Math.round(props.package.products[i].price) + " Br",
    content: props.package.products[i].description,
    quantity: props.package.products[i]?.pivot.quantity,
    store: props.package.products[i].stores,
  }));

  const columns = [
    {
      key: "1",
      title: "ID",
      dataIndex: "id",
    },
    {
      key: "2",
      title: "Store Name",
      dataIndex: "name",
    },
    {
      key: "3",
      title: "Quantity",
      dataIndex: "quantity",
    },
    {
      key: "4",
      title: "Store Type",
      dataIndex: "type",
    },
  ];
  const [dataset, setDataset] = useState([]);
  const productStore = (
    <div className="container">
      <Table columns={columns} dataSource={dataset} />
    </div>
  );

  const storeProduct = (pro) => {
    setDataset([]);
    pro?.store?.map((store) => {
      return setDataset((pre) => {
        return [
          ...pre,
          {
            id: store.id,
            name: store.name,
            quantity: store?.pivot?.quantity,
            type: store?.type,
          },
        ];
      });
    });
  };

  useEffect(() => {}, [props.package]);
  return (
    <div>
      <List
        itemLayout="vertical"
        size="large"
        pagination={{
          onChange: (page) => {
            console.log(page);
          },
          pageSize: 3,
        }}
        dataSource={data}
        footer={
          <div>
            <b>Available Products in this package</b>
          </div>
        }
        renderItem={(item) => (
          <List.Item
            key={item.title}
            extra={
              <img
                width={272}
                alt="products available in package"
                src={item.img}
              />
            }
          >
            <List.Item.Meta
              avatar={
                <Avatar
                  src={item.img}
                  onClick={() => {
                    storeProduct(item);
                  }}
                />
              }
              title={
                <Popover content={productStore} trigger="click">
                  <a
                    onClick={() => {
                      storeProduct(item);
                    }}
                  >
                    {item.quantity + " " + item.title}
                  </a>
                </Popover>
              }
              description={item.description}
            />
            {item.content}
          </List.Item>
        )}
      />
    </div>
  );
};

export default PackageInfo;
