import { Outlet } from "react-router-dom";
import Header from "./components/header";
import Nav from "./components/nav";
const Layout = () => {
  return (
    <>
      <Header />
      <Nav />
      <Outlet />
    </>
  );
};
export default Layout;
