/* eslint-disable */
import { useState } from "react";
import { Table, Spin, Popconfirm, Typography, message } from "antd";
import { useFetchFreeTripRequests } from "./hooks/useFreeTripData";
import { columns } from "./column";
import {
  useApproveFreeTripRequest,
  useRejectFreeTripRequest,
} from "./hooks/useFreeTripData";

import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

const FreeTripRequests = () => {
  const [page, setPage] = useState(1);
  const onTableChange = (pagination) => {
    setPage(pagination.current);
  };
  const { data: free_trip_requests, isLoading: loading } =
    useFetchFreeTripRequests(page);
  const { Title } = Typography;
  const { Paragraph } = Typography;

  const { approveFreeTripMutation } = useApproveFreeTripRequest();
  const { rejectFreeTripRequest } = useRejectFreeTripRequest();
  // used to handle accept "approve free trip"
  const handleApproveConfirm = (free_trip_request) => {
    console.log(free_trip_request);
    approveFreeTripMutation.mutate(free_trip_request.id);
  };
  // used to handle cancel "approve free trip"
  const handleApproveCancel = () => {
    message.warning("Nothing Done!");
  };
  // used to handle accept "reject free trip"
  const handleApproveReject = (free_trip_request) => {
    rejectFreeTripRequest.mutate(free_trip_request.id);
  };

  // used to handle cancel "reject free trip"
  const handleCancelReject = () => {
    message.warning("Nothing Done!");
  };
  const freeTripRequestColumn = [
    ...columns,
    ,
    {
      render: (record) => {
        return record?.disapproved_at || record?.approved_at ? null : (
          <div className="flex gap-3">
            <Popconfirm
              title={
                <div className="flex flex-col gap-2">
                  <Title level={5}>Are You Sure to Approve?</Title>
                  <Paragraph>
                    Once you approve the free trip request, the money will not
                    be refunded to the customer's wallet account.
                  </Paragraph>
                </div>
              }
              onConfirm={() => handleApproveConfirm(record)}
              onCancel={handleApproveCancel}
              okText="Yes"
              cancelText="No"
            >
              <CheckCircleOutlined
                className="text-xl hover:cursor-pointer"
                style={{ color: "#FAAD14" }}
                loading={approveFreeTripMutation?.isLoading}
              />
            </Popconfirm>
            {/* <Popconfirm
              title={
                <div className="flex flex-col gap-2">
                  <Title level={5}>Are You Sure to Reject? </Title>
                  <Paragraph>
                    Once you reject the free trip request, the money will not be
                    refunded to the customer's wallet account. the action is not
                    reversable.
                  </Paragraph>
                </div>
              }
              onConfirm={(e) => handleApproveReject(record)}
              onCancel={handleCancelReject}
              okText="Yes"
              cancelText="No"
              disabled={true}
            >
              <CloseCircleOutlined
                className="text-xl hover:cursor-pointer"
                disabled={true}
                style={{ color: "red" }}
                loading={rejectFreeTripRequest?.isLoading}
              />
            </Popconfirm> */}
          </div>
        );
      },
    },
  ];
  return (
    <div className="max-w-5xl justify-between mx-auto">
      <Table
        className="mt-3"
        dataSource={free_trip_requests?.data}
        columns={freeTripRequestColumn}
        loading={{ indicator: <Spin size="small" />, spinning: loading }}
        size="small"
        pagination={
          free_trip_requests?.meta?.last_page > 1
            ? {
                current: page,
                position: "both center",
                total: free_trip_requests?.meta?.last_page * 10,
                showSizeChanger: false,
              }
            : false
        }
        onChange={onTableChange}
      />
    </div>
  );
};

export default FreeTripRequests;
