import api from "../../adapter/cor_mana_base";
import { useQuery, useMutation } from "react-query";
const corporateEmployees = async (name) => {
  const data = await api.get(`users${name ? `?name=${name}` : ``}`);
  return data?.data?.data;
};

const updatePinCode = (employee) => {
  return api.put(`users/${employee.id}/change-pin-code`, employee.pinCode);
};

const resetPinCode = (employeeId) => {
  console.log(employeeId);
  return api.put(`users/${employeeId}/reset-pin-code`);
};

const registerEmployee = (data) => {
  return api.post("users", data);
};

export const useRegisterEmployee = () => {
  return useMutation(registerEmployee);
};

export const useEmployees = (name) => {
  return useQuery(["corporate_employees", name], () =>
    corporateEmployees(name)
  );
};

export const useUpdatePin = () => {
  return useMutation(updatePinCode);
};

export const useResetPinCode = () => {
  return useMutation(resetPinCode);
};
