import api from "../adapter/base";
import { message } from "antd";
import { useMutation, useQuery, useQueryClient } from "react-query";
// fetch discount-types
const discountTypes = async () => {
  const res = await api.get("discount-types");
  const response = res?.data?.data;
  return response;
};
// update discount-types
const updateDiscounts = (gift) => {
  return api.put(`discount-types/${gift.id}`, gift.data);
};

//fetch sms message
const smsMessage = async () => {
  const data = await api.get("sms-messages");
  return data?.data?.data;
};
// update sms message
const updateSMSMessage = (message) => {
  console.log(message);
  return api.put(`/sms-messages/${message.id}`, message);
};

// get gifts
const gifts = async () => {
  const res = await api.get("/gifts");
  const data = res?.data?.data;
  return data;
};

//update gifts
const updateGifts = (gift) => {
  return api.put(`/gifts/${gift.id}`, gift);
};

// get features
const getFeatures = async () => {
  const dt = await api.get("features");
  return dt.data;
};

// update features
const updateFeatures = (features) => {
  const updatedFeature = { is_active: features?.is_active ? 1 : 0 };
  return api.put(`features/${features.id}`, updatedFeature);
};

// update wallet feature
const updateWalletFeature = async (features) => {
  const updatedFeature = { data: features };
  const response = await api.put(`features/${features.id}`, updatedFeature);
  return response;
};

export const useGiftTypes = () => {
  return useQuery("Gift-types", discountTypes);
};

export const useUpdateGiftType = () => {
  return useMutation(updateDiscounts);
};
// sms module
export const useSMSMessages = () => {
  return useQuery("smsMessages", smsMessage);
};

export const useUpdateSMSMessage = () => {
  return useMutation(updateSMSMessage);
};

// gift module

export const useGifts = () => {
  return useQuery("Gifts", gifts);
};

export const useUpdateGift = () => {
  return useMutation(updateGifts);
};

export const useFeatures = () => {
  return useQuery("features", getFeatures);
};

export const useActivateDeactivateFeature = () => {
  return useMutation(updateFeatures);
};
//wallet module
export const useUpdateWalletFeature = () => {
  const client = useQueryClient();
  const updateWalletFeatureMutation = useMutation(updateWalletFeature, {
    onSuccess: (data) => {
      client.invalidateQueries("features");
      message.success("wallet feature updated!");
    },
    onError: (error) => {
      if (error?.response?.status === 500) {
        message.error("Server Error!");
      } else {
        message.error(error?.response?.data?.message);
      }
    },
  });
  return { updateWalletFeatureMutation };
};
