/* eslint-disable */
import { useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Upload,
  InputNumber,
  message,
} from "antd";
import { useQueryClient } from "react-query";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { useQuery } from "react-query";
import api from "../adapter/base";
import qs from "qs";
const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

const uploadButton = (
  <div>
    <PlusOutlined />
    <div
      style={{
        marginTop: 8,
      }}
    >
      Upload
    </div>
  </div>
);

const { Option } = Select;
const RegisterPackages = () => {
  const [load, setLoad] = useState(false);
  const client = useQueryClient();
  const [form] = Form.useForm();

  const { data: products, isLoading: loading_product } = useQuery(
    "products_for_dropdown",
    () => {
      let res = api
        .get("products", {
          params: { all: null },
          paramsSerializer: (params) => {
            return qs.stringify(params, {
              strictNullHandling: true,
            });
          },
        })
        .then((dt) => {
          return dt?.data?.data;
        })
        .catch((err) => {
          return err;
        });
      return res;
    }
  );
  // variables and functions to search product from selectbox
  const [productSearchTerm, setProductSearchTerm] = useState("");
  const searchedProduct = products?.filter(
    (product) =>
      product.name.toLowerCase().indexOf(productSearchTerm.toLowerCase()) > -1
  );

  const handleProductSearch = (value) => {
    setProductSearchTerm(value);
  };
  const { data: tags, isLoading: loading_tag } = useQuery("all_tags", () => {
    return api.get("tags");
  });
  const onFinish = (values) => {
    setLoad(true);
    Object.keys(values).map((key) => {
      if (
        Object.is(values[key], null) ||
        Object.is(values[key], undefined) ||
        Object.is(values[key], " ")
      ) {
        delete values[key];
        return;
      }
    });
    console.log(values);
    const fd = new FormData();
    Object?.keys(values)?.map((key) => {
      if (key === "upload") {
        values["upload"].map((image) => {
          fd.append("images[]", image.originFileObj, image.originFileObj.name);
        });
        delete values["upload"];
        return;
      }
      if (key === "products") {
        for (let i = 0; i < values["products"]?.length; i++) {
          Object.keys(values["products"][i]).forEach((key) => {
            fd.append(`products[${i}][${key}]`, values["products"][i][key]);
          });
        }
        delete values["products"];
        return;
      }

      // if (
      //   key === "max_quantity_per_order" ||
      //   typeof values["max_quantity_per_order"] === "object"
      // ) {
      //   delete values["max_quantity_per_order"];
      //   return;
      // }
      // console.log([...fd]);
      fd.append(key, values[key]);
    });

    api
      .post("packages", fd)
      .then((res) => {
        message.success("Packages Registered succesfully");
        client.invalidateQueries("packages");
        form.resetFields();
      })
      .catch((err) => {
        if (
          err?.response?.data?.message.includes(
            "The store quantities field is required."
          )
        ) {
          message.error("Add Product sizes by pressing 'Add Product' Button");
        } else {
          message.error(err?.response?.data?.message);
        }
      })
      .finally(() => {
        setLoad(false);
      });
  };

  return (
    <Form layout="vertical" onFinish={onFinish} form={form}>
      <Row>
        <Col span={12}>
          <Form.Item
            name="name"
            label="Package Name"
            rules={[
              {
                required: true,
                message: "Please input Package Name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={11} offset={1}>
          <Form.Item
            name="name_am"
            label="ስም በአማርኛ"
            rules={[
              {
                required: true,
                message: "Please input Package Name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item
            label="Tag"
            name="tag_id"
            rules={[
              {
                required: true,
                message: "Please Select tag!",
              },
            ]}
          >
            <Select loading={loading_tag}>
              {tags?.data?.data?.map((tag) => {
                return <Option value={tag.id}>{tag.name}</Option>;
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={11} offset={1}>
          <Form.Item
            name="price"
            label="Price"
            rules={[
              {
                required: true,
                message: "Please input package price!",
              },
            ]}
          >
            <InputNumber min={0} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item
            name="discount"
            label="Discount"
            rules={[
              {
                required: true,
                message: "Please Enter Discount!",
              },
            ]}
          >
            <InputNumber min={0} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={11} offset={1}>
          <p style={{ text: "center" }}>How much products in the packages</p>
          <Form.List name="products">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        {...restField}
                        name={[name, "id"]}
                        rules={[
                          {
                            required: true,
                            message: "Please Select Product",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Product"
                          loading={loading_product}
                          onSearch={handleProductSearch}
                          showSearch
                          filterOption={false}
                          optionFilterProp="children"
                        >
                          {searchedProduct?.map((product) => {
                            return (
                              <Option key={product.id} value={product.id}>
                                {product?.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={4} offset={1}>
                      <Form.Item
                        {...restField}
                        name={[name, "quantity"]}
                        rules={[
                          {
                            required: true,
                            message: "Enter Product size to make this package",
                          },
                        ]}
                      >
                        <InputNumber
                          placeholder="ብዛት"
                          min={0}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={2} offset={5}>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Col>
                  </Row>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Product
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Col>
        <Col span={12}>
          <Form.Item
            name="upload"
            label="Images Of Package"
            valuePropName="fileList"
            accept=".jpg, .jpeg, .png, .bmp, .gif, .svg,.webp"
            getValueFromEvent={normFile}
            rules={[
              {
                required: true,
                message: "እባኮን ቢያንስ አንድ ፎቶ ይጨምሩ/this field is required",
              },
            ]}
          >
            <Upload
              name="images"
              listType="picture-card"
              beforeUpload={(file) => {
                return false;
              }}
            >
              {uploadButton}
            </Upload>
          </Form.Item>
        </Col>
        <Col span={11} offset={1}>
          <Form.Item
            name="max_quantity_per_order"
            label="ትልቁ የግዢ መጠን በአንዴ"
            rules={[
              {
                required: false,
                message: "Enter Product size to make this package",
              },
            ]}
          >
            <InputNumber min={0} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        wrapperCol={{
          span: 6,
          offset: 6,
        }}
      >
        <Button
          type="primary"
          htmlType="submit"
          style={{ backgroundColor: "#F4AD33", width: "100%" }}
          loading={load}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            fill="currentColor"
            className="bi bi-save"
            viewBox="0 0 16 16"
          >
            {" "}
            <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z" />{" "}
          </svg>
          ፓኬጁ ይመዝግብ
        </Button>
      </Form.Item>
    </Form>
  );
};
export default RegisterPackages;
