import React, { useState, useEffect } from "react";
import {
  Card,
  Button,
  Input,
  Row,
  Col,
  Avatar,
  Select,
  Modal,
  Space,
  message,
  Table,
  Upload,
  DatePicker,
  Badge,
  List,
  Drawer,
  notification,
} from "antd";
import {
  SaveOutlined,
  DeleteOutlined,
  PlusOutlined,
  EditOutlined,
} from "@ant-design/icons";
import AdminHeader from "./admin_header";
import AdminNav from "./admin_nav";

import { useQuery } from "react-query";
import {
  deleteRoles,
  getRole,
  getRoles,
  updateRole,
  postRole,
  getRoleWithUser,
  getRolesWithUser,
  permissionGroups,
} from "../services/role_service";
import { deleteUser, postUser, updateUser } from "../services/user_service";
function RBAC() {
  const [admins, setAdmins] = useState([]);
  const [managers, setManagers] = useState([]);
  const [viewers, setViewers] = useState([]);
  const { data: permissions } = useQuery("permission-groups", () =>
    permissionGroups()
  );
  // console.log()
  // const  {data:permissions}=useQuery('permissions',()=>{return api.get('permissions',{credentials: 'include',params:{all:{}}})},{ staleTime: Infinity});
  const { data: roles, refetch } = useQuery(
    "all_roles",
    () => {
      return getRoles();
    },
    { staleTime: Infinity }
  );
  const { data: allUserWithRole, refetch: alL_user_refetch } = useQuery(
    "all_users_with_roles",
    () => {
      return getRolesWithUser();
    },
    { staleTime: Infinity }
  );
  const [permission, setPermission] = useState();
  const [roleTitle, setRoleTitle] = useState();

  const [usertoRegister, setUsertoRegister] = useState();

  const [loading, setLoading] = useState(true);
  const [selectedkeys, setSelectedkeys] = useState();
  const [roleid, setRoleId] = useState();

  const { Option } = Select;

  //data for table
  const [data, setData] = useState([]);

  //data for RoleUsers
  const [roleUsers, setRoleUsers] = useState([]);
  const [visible, setVisible] = useState(false);
  const [headerTitle, setHeaderTitle] = useState("");
  const [updateSysUserUpdateVis, setUpdateSysUserUpdateVis] = useState();

  const [sysUser, setSysUser] = useState();
  const [img_change, setImg_change] = useState(false);
  const [fileList, setFileList] = useState([]);
  const editSysUser = (user) => {
    setTimeout(() => {
      setUpdateSysUserUpdateVis(true);
    }, 10);
    setVisible(false);
    setFileList([]);
    setFileList((pre) => {
      return [
        ...pre,
        {
          uid: "-1",
          name: "image.png",
          status: "done",
          url: user?.profile_image_path,
        },
      ];
    });
    setSysUser(user);
  };

  const handleupdateChanges = ({ fileList: newFileList }) => {
    setFileList([]);
    setFileList(newFileList);
    setImg_change(true);
  };

  const updateSysUser = () => {
    const fd = new FormData();

    Object.keys(sysUser).forEach((key) => {
      if (
        key === "profile_image_path" ||
        key === "profile_thumbnail_path" ||
        key === "created_at" ||
        key === "updated_at" ||
        key === "deleted_at"
      ) {
        return;
      }
      fd.append(key, sysUser[key]);
    });
    if (img_change) {
      console.log(img_change);
      fd.append(
        "profile_image",
        fileList[0].originFileObj,
        fileList[0].originFileObj.name
      );
    }
    fd.append("_method", "put");

    updateUser(sysUser.id, fd)
      .then((res) => {
        message.success("System User Updated Successfully");
        refetch();
        alL_user_refetch();
        setTimeout(() => {
          setUpdateSysUserUpdateVis(false);
        }, 10);
        setFileList([]);
      })
      .catch((err) => {
        message.error(err.response.data.message);
        message.error("Make a correction and try again!");
        setFileList([]);
      });
  };

  const deleteSysUser = (id) => {
    deleteUser(id)
      .then((res) => {
        message.warning("Deleted Successfully");
        refetch();
        alL_user_refetch();
        setVisible(false);
      })
      .catch((err) => {
        message.error("Unable To Delete!");
        message.error(err?.response?.data?.message);
      });
  };

  const UpdateRolePermission = (id) => {
    if (!id) {
      message.warning("Please Select Role Type First");
      return;
    }
    setLoading(true);
    updateRole(id, { permission_groups: selectedkeys })
      .then((res) => {
        message.success("Role Permission Updated successfully");
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const deleteRole = (id) => {
    if (!id) {
      message.warning("Please Select Role Type First");
      return;
    }
    setLoading(true);
    deleteRoles(id)
      .then((res) => {
        message.success("Deleted Successfully", 7);
        refetch();
        alL_user_refetch();
      })
      .catch((err) => {
        message.error(err.response.data.message, 15);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const RolesPermission = (id) => {
    setLoading(true);
    setSelectedkeys([]);
    getRole(id)
      .then((res) => {
        res?.data?.data?.permission_groups?.map((permission) => {
          return setSelectedkeys((prev) => {
            return [...prev, permission?.id];
          });
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const dateChange = (date, dateString) => {
    setUsertoRegister((pre) => {
      return { ...pre, role_expire_at: dateString };
    });
  };
  const RegisterUser = () => {
    const fd = new FormData();
    Object.keys(usertoRegister).forEach((key) => {
      fd.append(key, usertoRegister[key]);
    });
    fd.append(
      "profile_image",
      fileList[0].originFileObj,
      fileList[0].originFileObj.name
    );
    postUser(fd)
      .then((res) => {
        message.success("User With Given Role Registered Successfully", 15);
        setAdduservis(false);
        refetch();
        alL_user_refetch();
      })
      .catch((err) => {
        message.error(err.response.data.message);
        setAdduservis(false);
      });
  };

  const [modvis, setModVis] = useState(false);
  const [adduservis, setAdduservis] = useState(false);
  const handleChange = (value) => {
    setPermission(value);
  };
  const handleChanges = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  //table coumns
  const columns = [
    {
      key: "1",
      title: "Permission Id",
      dataIndex: "id",
    },
    {
      key: "2",
      title: "Permission",
      dataIndex: "title",
    },
  ];

  const AddRole = () => {
    setModVis(true);
  };
  const RegisterRole = () => {
    postRole({ title: roleTitle, permission_groups: permission })
      .then((res) => {
        message.success("Roles Registered Successfully");
        setModVis(false);
        refetch();
      })
      .catch((err) => {
        message.error(err?.response?.data?.message, 16);
      });
  };
  const checkClikes = (event, id) => {
    switch (event.detail) {
      case 1: {
        RolesPermission(id);
        break;
      }
      case 2: {
        getRoleWithUser(id)
          .then((res) => {
            setRoleUsers([]);
            if (res?.data?.data?.users?.length === 0) {
              message.warning("No user registered in this role!");
              return;
            }
            setHeaderTitle(res?.data?.data?.title);
            res?.data?.data?.users?.map((user) => {
              // return setRoleUsers(pre=>{return [...pre,{title:user?.first_name+' '+ user?.last_name,image:user?.profile_image_path,description:user?.email}]})
              return setRoleUsers((pre) => {
                return [...pre, user];
              });
            });
            setVisible(true);
          })
          .catch((err) => {
            console.log(err);
          });

        // console.log("Double click handled");
        break;
      }
      default: {
        getRoleWithUser(id)
          .then((res) => {
            setRoleUsers([]);
            res?.data?.data?.users?.map((user) => {
              return setRoleUsers((pre) => {
                return [
                  ...pre,
                  {
                    title: user?.first_name + " " + user?.last_name,
                    image: user?.profile_image_path,
                    description: user?.email,
                  },
                ];
              });
            });
            setVisible(true);
          })
          .catch((err) => {
            console.log(err);
          });

        // console.log("Double click handled");
        break;
      }
    }
  };
  useEffect(() => {
    allUserWithRole?.data?.data?.map((role) => {
      if (role.title === "Admin") {
        return setAdmins(role?.users);
      } else if (role.title === "Manager") {
        return setManagers(role?.users);
      } else if (role.title === "Viewer") {
        return setViewers(role?.users);
      }
    });
    // console.log(permissions?.data?.data);
    setData([]);
    permissions?.data?.data?.map((permission) => {
      return setData((prev) => {
        return [
          ...prev,
          {
            key: permission.id,
            id: permission.id,
            title: permission.title,
          },
        ];
      });
    });
    setLoading(false);
  }, [permissions, allUserWithRole]);
  useEffect(() => {
    const args = {
      type: "info",
      message: "Who Registered In The Role",
      description:
        "Double Click `Role Name` To See Registered User Of That Role",
      duration: 2,
    };
    notification.open(args);
  }, []);
  return (
    <div className="rbac_main">
      <Button
        className="new_comp"
        onClick={() => AddRole()}
        style={{
          marginTop: "3%",
          marginLeft: "5%",
          backgroundColor: "#FAAD14",
        }}
      >
        + አዲስ Role
      </Button>
      <Button
        className="new_comp"
        onClick={() => {
          setAdduservis(true);
        }}
        style={{
          marginTop: "3%",
          marginLeft: "1%",
          backgroundColor: "#FAAD14",
        }}
      >
        + አዲስ User
      </Button>
      <h6
        style={{
          fontSize: 16,
          fontWeight: "bold",
          color: "#252733",
          marginTop: 30,
          marginLeft: "8%",
        }}
      >
        ሃላፊነቶች
      </h6>
      <Row gutter={[16, 16]} style={{ marginLeft: "15%" }}>
        <Col xs={16} md={12} xl={8}>
          <Card
            hoverable
            style={{ width: 320, height: 100, borderColor: "#DFE0EB" }}
          >
            <h6 style={{ fontSize: 13, marginTop: -5, marginLeft: 5 }}>
              {admins?.length} accounts
            </h6>
            <h6 style={{ fontSize: 23, marginTop: -2, marginLeft: 5 }}>
              Admin
            </h6>

            <div className="acc_imgs">
              <Avatar.Group
                maxCount={3}
                maxStyle={{
                  color: "#FFF",
                  backgroundColor: "#FAAD07",
                }}
              >
                {admins?.map((admin) => {
                  return <Avatar src={admin?.profile_thumbnail_path} />;
                })}
              </Avatar.Group>
            </div>
          </Card>
        </Col>
        <Col xs={16} md={12} xl={8}>
          <Card
            hoverable
            style={{ width: 320, height: 100, borderColor: "#DFE0EB" }}
          >
            <h6 style={{ fontSize: 13, marginTop: -5, marginLeft: 5 }}>
              {managers?.length} accounts
            </h6>
            <h6 style={{ fontSize: 23, marginTop: -2, marginLeft: 5 }}>
              Manager
            </h6>
            <div className="acc_imgs">
              <Avatar.Group
                maxCount={3}
                maxStyle={{
                  color: "#FFF",
                  backgroundColor: "#FAAD07",
                }}
              >
                {managers?.map((manager) => {
                  return <Avatar src={manager?.profile_thumbnail_path} />;
                })}
              </Avatar.Group>
            </div>
          </Card>
        </Col>
        <Col xs={16} md={12} xl={8}>
          <Card
            hoverable
            style={{ width: 320, height: 100, borderColor: "#DFE0EB" }}
          >
            <h6 style={{ fontSize: 13, marginTop: -5, marginLeft: 5 }}>
              {viewers?.length} accounts
            </h6>
            <h6 style={{ fontSize: 23, marginTop: -2, marginLeft: 5 }}>
              Viewer
            </h6>
            <div className="acc_imgs">
              <Avatar.Group
                maxCount={3}
                maxStyle={{
                  color: "#FFF",
                  backgroundColor: "#FAAD07",
                }}
              >
                {viewers?.map((viewer) => {
                  return <Avatar src={viewer?.profile_thumbnail_path} />;
                })}
              </Avatar.Group>
            </div>
          </Card>
        </Col>
      </Row>
      <div style={{ marginLeft: "5%" }}>
        {roles?.data?.data?.map((role) => {
          return (
            <Button
              onClick={(e) => {
                checkClikes(e, role.id);
                setRoleId(role.id);
              }}
              style={{
                marginTop: "5%",
                marginLeft: "1%",
                backgroundColor: "white",
              }}
            >
              <span>
                <Badge
                  count={role?.users_count}
                  style={{
                    fontSize: "13px",
                    color: "white",
                    backgroundColor: "#F8AB07",
                  }}
                />
                {role?.title}
              </span>
            </Button>
          );
        })}
      </div>
      <div className="container" style={{ marginTop: "1%" }}>
        <Button
          onClick={() => {
            UpdateRolePermission(roleid);
          }}
          style={{
            marginTop: "3%",
            marginLeft: "80%",
            backgroundColor: "white",
          }}
        >
          <SaveOutlined style={{ fontSize: 18, color: "#FAAD14" }} />
          Save
        </Button>
        <Button
          onClick={() => {
            deleteRole(roleid);
          }}
          style={{
            marginTop: "3%",
            marginLeft: "2%",
            backgroundColor: "white",
          }}
        >
          <DeleteOutlined style={{ fontSize: 18, color: "red" }} />
          Role
        </Button>
        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          pagination={{ pageSize: 20 }}
          rowSelection={{
            type: "checkbox",
            selectedRowKeys: selectedkeys,
            onChange: (keys) => {
              setSelectedkeys(keys);
            },
          }}
        />
      </div>

      <Modal
        title="+ አዲስ ሮል መጨመሪያ"
        style={{
          top: 20,
        }}
        visible={modvis}
        okText="ጨምር"
        cancelText="ይቅር"
        onOk={() => RegisterRole()}
        onCancel={() => {
          setModVis(false);
        }}
        closable={false}
        mask={false}
      >
        <Space direction="vertical" size={5} style={{ width: "100%" }}>
          <Input
            addonBefore="Title"
            placeholder="Role Title"
            onChange={(e) => setRoleTitle(e.target.value)}
          />
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            onChange={handleChange}
            placeholder="Select Permissions"
          >
            {permissions?.data?.data.map((permission) => {
              return <Option value={permission.id}>{permission.title}</Option>;
            })}
          </Select>
        </Space>
      </Modal>

      <Modal
        title="+ አዲስ ተጠቃሚ መጨመሪያ"
        style={{
          top: 20,
        }}
        visible={adduservis}
        okText="ጨምር"
        cancelText="ይቅር"
        onOk={() => RegisterUser()}
        onCancel={() => {
          setAdduservis(false);
        }}
        closable={false}
        mask={false}
      >
        <Space direction="vertical" size={5} style={{ width: "100%" }}>
          <Upload
            listType="picture-card"
            fileList={fileList}
            onChange={handleChanges}
            accept=".jpg, .jpeg, .png, .bmp, .gif, .svg,.webp"
            beforeUpload={(file) => {
              return false;
            }}
            style={{ marginLeft: "50%" }}
          >
            {fileList.length === 1 ? null : uploadButton}
          </Upload>
          <Input
            addonBefore="User Name"
            placeholder="User Name"
            onChange={(e) =>
              setUsertoRegister((pre) => {
                return { ...pre, user_name: e.target.value };
              })
            }
          />
          <Input
            addonBefore="First Name"
            placeholder="First Name"
            onChange={(e) =>
              setUsertoRegister((pre) => {
                return { ...pre, first_name: e.target.value };
              })
            }
          />
          <Input
            addonBefore="Last Name"
            placeholder="Last Name"
            onChange={(e) =>
              setUsertoRegister((pre) => {
                return { ...pre, last_name: e.target.value };
              })
            }
          />
          <Input
            addonBefore="Phone Number"
            showCount
            maxLength={12}
            placeholder="Phone Number 251912...."
            onChange={(e) =>
              setUsertoRegister((pre) => {
                return { ...pre, phone_number: e.target.value };
              })
            }
          />
          <Input
            addonBefore="Email"
            placeholder="Ex John@gmail.com"
            onChange={(e) =>
              setUsertoRegister((pre) => {
                return { ...pre, email: e.target.value };
              })
            }
          />
          <Input.Password
            addonBefore="Password"
            placeholder="PassWord"
            onChange={(e) =>
              setUsertoRegister((pre) => {
                return { ...pre, password: e.target.value };
              })
            }
          />
          <Input.Password
            addonBefore="Confirm Password"
            placeholder="Password confirm"
            onChange={(e) =>
              setUsertoRegister((pre) => {
                return { ...pre, password_confirmation: e.target.value };
              })
            }
          />

          <Select
            style={{ width: "100%" }}
            onChange={(value) => {
              setUsertoRegister((pre) => {
                return { ...pre, role_id: value };
              });
            }}
            placeholder="Select User Role"
          >
            {roles?.data?.data.map((role) => {
              return <Option value={role.id}>{role.title}</Option>;
            })}
          </Select>
          <DatePicker
            onChange={dateChange}
            placeholder="Select Expiry Date"
            style={{ width: "100%" }}
          />
        </Space>
      </Modal>

      <Modal
        title="+ ነባር ተጠቃሚ ማስተካከያ"
        style={{
          top: 20,
        }}
        mask={false}
        visible={updateSysUserUpdateVis}
        okText="አስተካክል"
        cancelText="ይቅር"
        onOk={() => updateSysUser()}
        onCancel={() =>
          setTimeout(() => {
            setUpdateSysUserUpdateVis(false);
            setFileList([]);
          }, 10)
        }
        closable={false}
      >
        <Space direction="vertical" size={5} style={{ width: "100%" }}>
          <Upload
            listType="picture-card"
            fileList={fileList}
            onChange={handleupdateChanges}
            accept=".jpg, .jpeg, .png, .bmp, .gif, .svg,.webp"
            beforeUpload={(file) => {
              return false;
            }}
            style={{ marginLeft: "50%" }}
          >
            {fileList.length === 1 ? null : uploadButton}
          </Upload>
          <Input
            addonBefore="User Name"
            placeholder="User Name"
            value={sysUser?.user_name}
            onChange={(e) =>
              setSysUser((pre) => {
                return { ...pre, user_name: e.target.value };
              })
            }
          />
          <Input
            addonBefore="First Name"
            placeholder="First Name"
            value={sysUser?.first_name}
            onChange={(e) =>
              setSysUser((pre) => {
                return { ...pre, first_name: e.target.value };
              })
            }
          />
          <Input
            addonBefore="Last Name"
            placeholder="Last Name"
            value={sysUser?.last_name}
            onChange={(e) =>
              setSysUser((pre) => {
                return { ...pre, last_name: e.target.value };
              })
            }
          />
          <Input
            addonBefore="Phone Number"
            showCount
            maxLength={12}
            value={sysUser?.phone_number}
            placeholder="Phone Number 251912...."
            onChange={(e) =>
              setSysUser((pre) => {
                return { ...pre, phone_number: e.target.value };
              })
            }
          />
          <Input
            addonBefore="Email"
            placeholder="Ex John@gmail.com"
            value={sysUser?.email}
            onChange={(e) =>
              setSysUser((pre) => {
                return { ...pre, email: e.target.value };
              })
            }
          />
          <Input.Password
            addonBefore="Password"
            placeholder="PassWord"
            onChange={(e) =>
              setSysUser((pre) => {
                return { ...pre, password: e.target.value };
              })
            }
          />
          <Input.Password
            addonBefore="Confirm Password"
            placeholder="Password confirm"
            onChange={(e) =>
              setSysUser((pre) => {
                return { ...pre, password_confirmation: e.target.value };
              })
            }
          />

          <Select
            style={{ width: "100%" }}
            placeholder="Select User Role"
            onChange={(value) => {
              setSysUser((pre) => {
                return { ...pre, role_id: value };
              });
            }}
          >
            {roles?.data?.data.map((role) => {
              return <Option value={role.id}>{role.title}</Option>;
            })}
          </Select>
          <DatePicker
            onChange={(date, dateString) => {
              setSysUser((pre) => {
                return { ...pre, role_expire_at: dateString };
              });
            }}
            placeholder="Select Expiry Date"
            style={{ width: "100%" }}
          />
        </Space>
      </Modal>

      <Drawer
        visible={visible}
        closable={false}
        onClose={() => setVisible(false)}
        placement="right"
        zIndex={0}
        mask={true}
        keyboard={true}
      >
        <List
          itemLayout="horizontal"
          dataSource={roleUsers}
          header={
            <div>
              <h4>Users Registered As {headerTitle} </h4>
            </div>
          }
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src={item.profile_image_path} />}
                title={<a href="#">{item.first_name + " " + item.last_name}</a>}
                description={item.email}
              />
              <EditOutlined onClick={() => editSysUser(item)} />
              <DeleteOutlined onClick={() => deleteSysUser(item.id)} />
            </List.Item>
          )}
        />
      </Drawer>
    </div>
  );
}
export default RBAC;
