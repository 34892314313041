import { Input, Form, Button, Upload, Select, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import api from "../adapter/base";
import { useEffect, useLayoutEffect, useState } from "react";
import { useQueryClient } from "react-query";
const onFinish = (values) => {
  console.log("Success:", values);
};
const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};
const UpdatePackage = (props) => {
  const [products, setProducts] = useState();
  const [tags, setTags] = useState();
  const { Option } = Select;
  const [load, setLoad] = useState(false);
  var children = [];
  const [pack, setPack] = useState();
  const [fileList, setFileList] = useState([]);
  const [img_change, setImg_change] = useState(false);
  const client = useQueryClient();
  products?.forEach((product) =>
    children.push(<Option key={product.id}>{product.name}</Option>)
  );
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    setImg_change(true);
  };
  const update = async () => {
    setLoad(true);
    const fd = new FormData();
    Object.keys(pack).forEach((key) => {
      if (
        key === "max_quantity_per_order" &&
        typeof pack["max_quantity_per_order"] === "object"
      ) {
        delete pack["max_quantity_per_order"];
        return;
      }
      if (
        key === "products" ||
        key === "image_paths" ||
        key === "image_path" ||
        key === "left_in_stock" ||
        key === "total_quantity" ||
        key === "stores" ||
        key === "created_at" ||
        key === "updated_at" ||
        key === "deleted_at"
      ) {
        return;
      }
      fd.append(key, pack[key]);
    });
    if (img_change) {
      fd.append(
        "image",
        fileList[0].originFileObj,
        fileList[0].originFileObj.name
      );
      fd.append("remove_image", 1);
    }
    fd.append("_method", "put");
    await api({
      method: "post",
      url: `packages/${pack.id}`,
      data: fd,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        message.success("Package Updated Succesfully");
        client.invalidateQueries("packages");
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
      })
      .finally(() => {
        setLoad(false);
      });
  };
  useLayoutEffect(() => {
    setPack(props.package);
    setFileList([
      {
        uid: -1,
        status: "done",
        url: props.package?.image_paths
          ? props.package?.image_paths[0]
          : props.package?.image_path,
      },
    ]);
  }, [props.package]);
  useEffect(() => {
    const fetchProduct = async () => {
      setProducts([]);
      setTags([]);
      await api
        .get("products")
        .then((res) => {
          setProducts(res.data.data);
        })
        .catch((err) => {
          message.error(err.response.data.message);
        });
    };
    const fetchTag = async () => {
      await api
        .get("tags")
        .then((res) => {
          setTags(res.data.data);
        })
        .catch((err) => {
          message.error(err.response.data.message);
        });
    };
    fetchProduct();
    fetchTag();
  }, [props.package]);
  return (
    <div style={{ marginTop: "10px" }}>
      <h4 style={{}}>Update Package</h4>
      <Form
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Package Name"
          rules={[
            {
              required: true,
              message: "Please input Package Name!",
            },
          ]}
        >
          <Input
            value={pack?.name}
            onChange={(e) => {
              setPack((prev) => {
                return { ...prev, name: e.target.value };
              });
            }}
          />
        </Form.Item>
        <Form.Item
          label="ስም በአማርኛ"
          rules={[
            {
              required: true,
              message: "Please input Package Name!",
            },
          ]}
        >
          <Input
            value={pack?.name_am}
            onChange={(e) => {
              setPack((prev) => {
                return { ...prev, name_am: e.target.value };
              });
            }}
          />
        </Form.Item>
        <Form.Item
          label="Tag"
          rules={[
            {
              required: true,
              message: "Please Select tag!",
            },
          ]}
        >
          <Select
            onChange={(value) => {
              setPack((prev) => {
                return { ...prev, tag_id: value };
              });
            }}
          >
            {tags?.map((tag) => {
              return <Option value={tag.id}>{tag.name}</Option>;
            })}
          </Select>
        </Form.Item>

        {/* <Form.Item 
      label="Products"
      rules={[
        {
          required: true,
          message: 'Please Select tag!',
        },
      ]}>
           <Select
      mode="multiple"
      allowClear
      style={{
        width: '100%',
      }}
      placeholder="Please select"
      onChange={(value)=>{setCheck(bol=>!bol);handleChange(value)}}
    >
      {children}
    </Select>
        </Form.Item> */}

        <Form.Item
          label="Price"
          rules={[
            {
              required: true,
              message: "Please input package price!",
            },
          ]}
        >
          <Input
            value={pack?.price}
            onChange={(e) => {
              setPack((prev) => {
                return { ...prev, price: e.target.value };
              });
            }}
          />
        </Form.Item>
        <Form.Item
          label="Discount"
          rules={[
            {
              required: true,
              message: "Please Enter Discount!",
            },
          ]}
        >
          <Input
            value={pack?.discount}
            onChange={(e) => {
              setPack((prev) => {
                return { ...prev, discount: e.target.value };
              });
            }}
          />
        </Form.Item>
        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Form.Item label="Package Image" valuePropName="fileList">
            <Upload
              listType="picture-card"
              fileList={fileList}
              onChange={onChange}
              beforeUpload={(file) => {
                return false;
              }}
              accept=".jpg, .jpeg, .png, .bmp, .gif, .svg,.webp"
            >
              {fileList?.length === 1 ? null : (
                <div>
                  <PlusOutlined />
                  <div
                    style={{
                      marginTop: 8,
                    }}
                  >
                    Upload
                  </div>
                </div>
              )}
            </Upload>
          </Form.Item>
          <Button type="primary" onClick={() => update()} loading={load}>
            Update
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default UpdatePackage;
