import { Form, Input, Divider, Button } from "antd";
import AlertError from "../Alert/alert";
import { useRegisterBank } from "../hooks/usebank";
import { useEffect } from "react";
const RegisterBank = ({ onClose }) => {
  const { mutation, error } = useRegisterBank(onClose);
  const onFinish = (values) => {
    mutation.mutate(values);
  };
  const onReset = () => {
    form.resetFields();
  };

  const [form] = Form.useForm();

  useEffect(() => {
    if (mutation.isSuccess) {
      form.resetFields();
    }
  }, [mutation.isSuccess, form]);

  return (
    <Form onFinish={onFinish} form={form} layout="vertical">
      <Form.Item
        label="Bank Name"
        rules={[
          {
            required: true,
            message: "Bank Name is required",
          },
        ]}
        name="name"
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Phone Number"
        rules={[
          {
            min: 9,
            message: "Invalid Phone Number",
          },
        ]}
        name="phone_number"
      >
        <Input addonBefore="+251" placeholder="912.." />
      </Form.Item>

      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            type: "email",
            message: "invalid email format",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Divider>Bank Address</Divider>

      <Form.Item label="City" name={["address", "city"]}>
        <Input />
      </Form.Item>

      <Form.Item label="Sub City" name={["address", "sub_city"]}>
        <Input />
      </Form.Item>

      <Form.Item label="Woreda" name={["address", "woreda"]}>
        <Input />
      </Form.Item>

      <Form.Item label="house_number" name={["address", "house_number"]}>
        <Input />
      </Form.Item>
      {mutation.isError && <AlertError error={error} />}
      <Form.Item>
        <div className="space-x-4 reverse">
          <Button htmlType="button" onClick={onReset}>
            Reset
          </Button>
          <Button
            style={{ backgroundColor: "#FAAD14" }}
            htmlType="submit"
            loading={mutation.isLoading}
          >
            Register
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default RegisterBank;
