import api from "../../adapter/base";
import { useQuery, useMutation } from "react-query";
const getDiscountUser = async (discount_type_id) => {
  try {
    const data = await api.get(`/discount-types/${discount_type_id}/users`);
    return data.data;
  } catch (err) {
    throw err;
  }
};

export const useDiscountUser = (id) => {
  return useQuery("discountUsers", () => getDiscountUser(id));
};

const updateDiscountUser = async ({ discount_type_id, users }) => {
  try {
    const response = await api.post(
      `/discount-types/${discount_type_id}/users`,
      { user_ids: users }
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const useUpdateDiscountUsers = () => {
  return useMutation(updateDiscountUser);
};
