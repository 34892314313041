/* eslint-disable */
import { useState, useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  message,
  Switch,
  Radio,
  Divider,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import api from "../adapter/base";
import {
  useGiftTypes,
  useUpdateGiftType,
  useGifts,
  useSMSMessages,
  useUpdateSMSMessage,
  useUpdateGift,
  useFeatures,
  useActivateDeactivateFeature,
  useUpdateWalletFeature,
} from "../hooks/useGiftData";

const SiteSetting = () => {
  const [load, setload] = useState(false);
  const [radioValue, setRadioValue] = useState(1);
  const { data: mobile_app } = useQuery("mobile-app-settings", () => {
    return api.get("mobile-app");
  });
  // fetched data from custom hooks
  const { data: gift_type } = useGiftTypes();
  const { data: smsMessage } = useSMSMessages();
  const { data: gifts } = useGifts();
  const { data: features } = useFeatures();

  const { mutate: updateGiftType, isLoading: load_update_gift_type } =
    useUpdateGiftType();
  const { mutate: updateGift, isLoading: load_update_gift } = useUpdateGift();
  const { mutate: updateSmS, isLoading: load_sms_update } =
    useUpdateSMSMessage();
  const {
    mutate: activateDeativateFeature,
    isLoading: load_activate_deactivate_feature,
  } = useActivateDeactivateFeature();

  const { updateWalletFeatureMutation } = useUpdateWalletFeature();

  //initialDatas
  const [initialData, setInitialData] = useState();
  const [initialDataOfGift, setInitialDataOfGift] = useState();
  const [initialDataOfSMS, setInitialDataOfSMS] = useState();
  const [initialDataOfGifts, setInitialDataOfGifts] = useState();
  const [initialDataOfFeatures, setInitialDataOfFeatures] = useState();

  const query_client = useQueryClient();
  // forms
  const [form] = Form.useForm();
  const [giftForm] = Form.useForm();
  const [smsForm] = Form.useForm();
  const [giftsForm] = Form.useForm();
  const [featuresForm] = Form.useForm();
  const [walletFeatureForm] = Form.useForm();

  const onFinish = (values) => {
    setload(true);
    api
      .put("mobile-app", values)
      .then((res) => {
        message.success("Updated Successfully");
        query_client.invalidateQueries("mobile-app-settings");
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
      })
      .finally(() => {
        setload(false);
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinishGift = (values) => {
    Object.keys(values).map((key) => {
      if (key === "is_active") {
        values[key] = values[key] ? 1 : 0;
        return;
      }
    });
    updateGiftType(
      { id: radioValue, data: values },
      {
        onSuccess: (data) => {
          message.success("Updated Successfully");
          query_client.invalidateQueries("Gift-types");
        },
        onError: (error) => {
          message.warning(error?.response?.data?.message);
        },
      }
    );
  };

  const onFinishSms = (values) => {
    updateSmS(values, {
      onSuccess: () => {
        message.success("Saved Successfully");
        query_client.invalidateQueries("smsMessages");
      },
      onError: (err) => {
        message.warning(err?.response?.data?.message);
      },
    });
  };
  const onFinishGifts = (values) => {
    updateGift(values, {
      onSuccess: () => {
        message.success("Saved Successfully");
        query_client.invalidateQueries("gifts");
      },
      onError: (err) => {
        message.warning(err?.response?.data?.message);
      },
    });
  };

  const onFinishFeature = (values) => {
    activateDeativateFeature(values, {
      onSuccess: (data) => {
        const status = data?.data?.data.is_active;
        status
          ? message.success("Feature Activated !")
          : message.warning("Feature Deactivated !");
        query_client.invalidateQueries("features");
      },
      onError: (err) => {
        message.error(err?.response?.data?.message);
      },
    });
  };

  const updateWallet = (values) => {
    updateWalletFeatureMutation.mutate(values);
  };

  useEffect(() => {
    setInitialData(mobile_app?.data?.data);
    form.setFieldsValue(initialData);
  }, [form, mobile_app, initialData]);

  const onChange = (e) => {
    setRadioValue(e.target.value);
  };

  useEffect(() => {
    if (gift_type) {
      if (radioValue == 1) {
        setInitialDataOfGift(gift_type[0]);
        giftForm.setFieldsValue(initialDataOfGift);
      } else if (radioValue == 2) {
        setInitialDataOfGift(gift_type[1]);
        giftForm.setFieldsValue(initialDataOfGift);
      } else {
        setInitialDataOfGift(gift_type[2]);
        giftForm.setFieldsValue(initialDataOfGift);
      }
    }
  }, [radioValue, giftForm, gift_type, initialDataOfGift]);

  useEffect(() => {
    if (smsMessage) {
      setInitialDataOfSMS(smsMessage[0]);
      smsForm.setFieldsValue(initialDataOfSMS);
    }
  }, [smsMessage, initialDataOfSMS, smsForm]);

  useEffect(() => {
    if (gifts) {
      setInitialDataOfGifts(gifts[0]);
      giftsForm.setFieldsValue(initialDataOfGifts);
    }
  }, [gifts, initialDataOfGifts, giftsForm]);

  useEffect(() => {
    setInitialDataOfFeatures(features?.data[0]);
    featuresForm.setFieldsValue(initialDataOfFeatures);
  }, [featuresForm, initialDataOfFeatures, features]);

  useEffect(() => {
    walletFeatureForm.setFieldsValue(features?.data[1]?.data);
    walletFeatureForm.setFieldsValue({ id: features?.data[1].id });
  }, [walletFeatureForm, features]);

  return (
    <>
      <div className="container" style={{ marginTop: "1%" }}>
        <Form
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
          initialValues={initialData}
          layout="vertical"
        >
          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <h5>APP SETTINGS</h5>
                </Col>
                <Col span={24}>
                  <span>Set the settings related app version</span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={6}></Col>
            <Col span={8}>
              <Row>
                <Col span={24}></Col>
                <Col span={24}>
                  <Form.Item
                    label="App Name"
                    name="app_name"
                    rules={[
                      {
                        required: true,
                        message: "Please input App Name!",
                      },
                    ]}
                  >
                    <Input value={mobile_app?.name} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={8} offset={1}>
              <Form.Item label="Privacy Policy Link" name="privacy_policy_link">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={6}></Col>
            <Col span={8}>
              <Row>
                <Col span={24}></Col>
                <Col span={24}>
                  <Form.Item
                    label="Terms And Conditions Link"
                    name="terms_and_conditions_link"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={8} offset={1}>
              <Form.Item
                label="Latest Android Version"
                name="latest_android_version"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}></Col>
            <Col span={8}>
              <Row>
                <Col span={24}></Col>
                <Col span={24}>
                  <Form.Item
                    label="Least Supported Android Version"
                    name="least_supported_android_version"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={8} offset={1}>
              <Form.Item label="Play Store Link" name="play_store_link">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}></Col>
            <Col span={8}>
              <Row>
                <Col span={24}></Col>
                <Col span={24}>
                  <Form.Item
                    label="Latest IOS Version"
                    name="latest_ios_version"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={8} offset={1}>
              <Form.Item
                label="Least Supported IOS Version"
                name="least_supported_ios_version"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={6}></Col>
            <Col span={8}>
              <Row>
                <Col span={24}></Col>
                <Col span={24}>
                  <Form.Item label="App Store Link" name="app_store_link">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={8} offset={1}></Col>
          </Row>

          <Row>
            <Col span={6}></Col>
            <Col span={8}>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ backgroundColor: "#F4AD33" }}
                  loading={load}
                >
                  Save
                </Button>
              </Form.Item>
            </Col>
            <Col span={8}></Col>
          </Row>
        </Form>
        <Form onFinish={onFinishGift} form={giftForm} layout="vertical">
          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <h5>SetUp Discount</h5>
                </Col>
                <Col span={24}>
                  <span>Set the Discount type and the percentage</span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: "4px", marginBottom: 10 }}>
            <Col span={6}></Col>
            <Col span={18}>
              <Radio.Group
                style={{ width: "100%" }}
                onChange={onChange}
                value={radioValue}
              >
                <Row>
                  <Col span={8} offset={2}>
                    <Radio value={1}>AirTime Discount</Radio>
                  </Col>
                  <Col span={8}>
                    <Radio value={2}>Order Discount</Radio>
                  </Col>
                  <Col span={5}>
                    <Radio value={3}>Special Order Discount</Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </Col>
          </Row>
          <Row>
            <Col span={6}></Col>
            <Col span={8}>
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: "Name field is required" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8} offset={1}>
              <Form.Item
                name="name_am"
                label="Name In Amharic"
                rules={[
                  { required: true, message: "Name in Amharic is required" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}></Col>
            <Col span={8}>
              <Form.Item
                name="description"
                label="Description"
                rules={[
                  { required: true, message: "Description field is required" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8} offset={1}>
              <Form.Item
                name="description_am"
                label="Description In Amharic"
                rules={[
                  {
                    required: true,
                    message: "Description in Amharic is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={6}></Col>
            <Col span={8}>
              <Form.Item
                name="percentage"
                label="Percentage"
                rules={[
                  { required: true, message: "Percentage field is required" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={4} offset={1}>
              <Form.Item
                label="Activate"
                name="is_active"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}></Col>
            <Col span={6}>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ backgroundColor: "#F4AD33" }}
                  loading={load_update_gift_type}
                >
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Form onFinish={onFinishSms} form={smsForm} layout="vertical">
          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <h5>SetUp SMS MESSAGE</h5>
                </Col>
                <Col span={24}>
                  <span>Set the Message and turn the message on/off</span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item name="id">
                <Input style={{ display: "none" }} />
              </Form.Item>
            </Col>
            <Col span={18}>
              <Row>
                <Col span={12}>
                  <Form.Item name="title" label="Title">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={11} offset={1}>
                  <Form.Item
                    name="message"
                    label="Message"
                    rules={[
                      { required: true, message: "This Field is required" },
                    ]}
                  >
                    <TextArea />
                  </Form.Item>
                </Col>
                <Form.Item
                  label="Activate"
                  name="is_active"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={6}></Col>
            <Col span={6}>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ backgroundColor: "#F4AD33" }}
                  loading={load_sms_update}
                >
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Form onFinish={onFinishGifts} form={giftsForm} layout="vertical">
          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <h5>SetUp Gifts On Registration</h5>
                </Col>
                <Col span={24}>
                  <span>Set the amount and control switchs</span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item name="id">
                <Input style={{ display: "none" }} />
              </Form.Item>
            </Col>
            <Col span={18}>
              <Row>
                <Col span={12}>
                  <Form.Item name="amount" label="amount">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={11} offset={1}>
                  <Form.Item
                    label="Activate"
                    name="is_active"
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={6}></Col>
            <Col span={6}>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ backgroundColor: "#F4AD33" }}
                  loading={load_update_gift}
                >
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Row>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <h5>Features</h5>
              </Col>
              <Col span={24}>
                <span>Manage Different Features here</span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Form onFinish={onFinishFeature} form={featuresForm}>
          {/* <Row>
            <Col span={6}></Col>
            <Col span={17} style={{ marginBottom: 7 }}>
              <Divider plain orientation="left">
                Manage Customer BYpass
              </Divider>
            </Col>
          </Row> */}
          <Row>
            <Col span={6}>
              <Form.Item name="id">
                <Input style={{ display: "none" }} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Buy Above Max Product Limit / ByPass"
                name="is_active"
                valuePropName="checked"
              >
                <Switch size="small" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Button
                  size="small"
                  type="primary"
                  htmlType="submit"
                  style={{ backgroundColor: "#F4AD33" }}
                  loading={load_activate_deactivate_feature}
                >
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
          {/* <Row>
            <Col span={6}></Col>
            <Col span={8}>
              <Form.Item>
                <Button
                  size="small"
                  type="primary"
                  htmlType="submit"
                  style={{ backgroundColor: "#F4AD33" }}
                  loading={load_activate_deactivate_feature}
                >
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row> */}
        </Form>
        <Form
          onFinish={updateWallet}
          form={walletFeatureForm}
          layout="vertical"
        >
          <Row>
            <Col span={6}></Col>
            <Col span={17} style={{ marginBottom: 7 }}>
              <Divider plain orientation="left">
                Manage Wallet features
              </Divider>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item name="id">
                <Input style={{ display: "none" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="number_of_orders" label="Number of Order">
                <Input />
              </Form.Item>
            </Col>
            <Col span={8} offset={1}>
              <Form.Item name="order_percentage" label="Order Percentage">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}></Col>
            <Col span={8}>
              <Form.Item
                name="wallet_amount_for_free_trip"
                label="Wallet Amount for free trip"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8} offset={1}></Col>
          </Row>
          <Row>
            <Col span={6}></Col>
            <Col span={8}>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ backgroundColor: "#F4AD33", marginBottom: 0 }}
                  loading={updateWalletFeatureMutation.isLoading}
                >
                  Save
                </Button>
              </Form.Item>
            </Col>
            <Col span={8} offset={1}></Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default SiteSetting;
