import { useEffect, useState } from "react";
import { Form, Input, Divider, Button } from "antd";
import { useUpdateBank } from "../hooks/usebank";
import AlertError from "../Alert/alert";
const UpdateBank = ({ bankForOperation, closeEditModal }) => {
  const { mutation, error } = useUpdateBank(closeEditModal);
  const onFinish = (values) => {
    mutation.mutate({ id: bankForOperation.id, ...values });
  };
  const onReset = () => {
    form.resetFields();
  };
  const [form] = Form.useForm();
  const [initialData, setInitialData] = useState(null);
  useEffect(() => {
    setInitialData(bankForOperation);
    form.setFieldsValue(initialData);
  }, [form, bankForOperation, initialData]);
  return (
    <Form onFinish={onFinish} form={form} layout="vertical">
      <Form.Item
        label="Bank Name"
        rules={[
          {
            required: true,
            message: "Bank Name is required",
          },
        ]}
        name="name"
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Phone Number"
        rules={[
          {
            min: 9,
            message: "Invalid Phone Number",
          },
        ]}
        name="phone_number"
      >
        <Input addonBefore="+251" placeholder="912.." />
      </Form.Item>

      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            type: "email",
            message: "invalid email format",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Divider>Bank Address</Divider>

      <Form.Item label="City" name={["address", "city"]}>
        <Input />
      </Form.Item>

      <Form.Item label="Sub City" name={["address", "sub_city"]}>
        <Input />
      </Form.Item>

      <Form.Item label="Woreda" name={["address", "woreda"]}>
        <Input />
      </Form.Item>

      <Form.Item label="House Number" name={["address", "house_number"]}>
        <Input />
      </Form.Item>
      <div className="mb-4">
        {mutation.isError && <AlertError error={error} />}
      </div>
      <Form.Item>
        <div className="space-x-4 reverse">
          <Button htmlType="button" onClick={onReset}>
            Reset
          </Button>
          <Button
            style={{ backgroundColor: "#FAAD14" }}
            htmlType="submit"
            loading={mutation?.isLoading}
          >
            Update
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default UpdateBank;
