import { useEffect, useState } from "react";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import AdminNav from "./admin_nav";
import AdminHeader from "./admin_header";
import {
  Button,
  Drawer,
  Input,
  message,
  Table,
  Card,
  Row,
  Col,
  Popover,
  Popconfirm,
} from "antd";
import RegisterPackages from "../drawer/register_packages";
import api from "../adapter/base";
import { useQuery } from "react-query";
import { useQueryClient } from "react-query";
import UpdatePackage from "../drawer/update_package";
import PackageInfo from "../AdminComponent/packageInfo";
const AdminPackageManagement = () => {
  const [visible, setVisible] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [visible_, setVisible_] = useState();
  const [updateId, setUpdateId] = useState();
  const [rowVis, setRowVis] = useState(false);
  const [packDetail, setPackDetail] = useState();
  const [searchText, setSearchText] = useState("");
  const client = useQueryClient();

  const deletePackage = async (id) => {
    await api
      .delete(`packages/${id}`)
      .then((res) => {
        message.warning("Package Deleted Successfully", 10);
        client.invalidateQueries("packages");
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  };
  const { data: packages } = useQuery("packages", () => {
    return api.get("packages");
  });

  const expandedRow = (packagedetail) => (
    <div style={{ marginLeft: "20%" }}>
      <div style={{ width: "60%" }}>
        <Card
          className="second_card"
          hoverable
          style={{
            width: "100%",
            height: 50,
            marginTop: 20,
            background: "#EBEBEB",
          }}
        >
          <Row
            gutter={16}
            style={{ marginTop: -10, width: "90%", marginLeft: "100px" }}
          >
            <Col className="gutter-row" span={10}>
              <div>Store Name</div>
            </Col>

            <Col className="gutter-row" span={10}>
              <div>Left In Stock</div>
            </Col>
          </Row>
        </Card>
      </div>
      {packagedetail?.stores?.map((store) => {
        return (
          <div style={{ width: "60%" }}>
            <Card
              className="second_card"
              hoverable
              style={{
                width: "100%",
                height: "50%",
                marginTop: 2,
                background: "#FFF",
              }}
            >
              <div className="new_obj_item">
                <Popover content={productStore} trigger="click">
                  <Row
                    gutter={16}
                    style={{ width: "90%", marginLeft: "100px" }}
                    onClick={() => {
                      storeProduct(store?.products);
                    }}
                  >
                    <Col className="gutter-row" span={10}>
                      <div>
                        <h6
                          style={{
                            fontSize: 13,
                            color: "grey",
                            marginleft: 10,
                          }}
                        >
                          {store?.name}{" "}
                        </h6>
                      </div>
                    </Col>
                    <Col className="gutter-row" span={10}>
                      <div>
                        <h6 style={{ fontSize: 13, color: "grey" }}>
                          {store?.pivot?.quantity}{" "}
                        </h6>
                      </div>
                    </Col>
                  </Row>
                </Popover>
              </div>
            </Card>
          </div>
        );
      })}
    </div>
  );
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return (
          String(record.name)
            .toLowerCase()
            .includes(String(value).toLowerCase()) ||
          String(record.price)
            .toLowerCase()
            .includes(String(value).toLowerCase()) ||
          String(record.left_in_stock)
            .toLowerCase()
            .includes(String(value).toLowerCase())
        );
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Left in Stock",
      dataIndex: "left_in_stock",
      key: "stock_left",
    },
    {
      key: "actions",
      render: (records) => {
        return (
          <>
            <EditOutlined
              style={{
                color: "#F4AD33",
                marginLeft: 20,
                fontSize: 20,
                marginTop: "-40px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setUpdateId(records.package);
                setVisible_(true);
              }}
            />
            <Popconfirm
              title="Are You Sure To Delete?"
              onConfirm={(e) => {
                e.stopPropagation();
                deletePackage(records.id);
              }}
              onCancel={(e) => {
                e.stopPropagation();
                message.warning("ምንም ስራ አልተሰራም");
              }}
              okText="ሰርዝ"
              cancelText="ይቅር"
            >
              <DeleteOutlined
                style={{ color: "red", marginLeft: 20, fontSize: 20 }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            </Popconfirm>
          </>
        );
      },
    },
  ];
  const onRow = (record) => {
    return {
      onClick: (event) => {
        setPackDetail(record.package);
        setRowVis(true);
      },
    };
  };
  const color = "black";
  const columns_ = [
    {
      key: "1",
      title: "ID",
      dataIndex: "id",
      render(text, record) {
        return {
          props: {
            style: {
              color: record.enough_for == "0 packages" ? "red" : "black",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      key: "2",
      title: "Product Name",
      dataIndex: "name",
      render(text, record) {
        return {
          props: {
            style: {
              color: record.enough_for == "0 packages" ? "red" : "black",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      key: "3",
      title: "Total Quantity",
      dataIndex: "store_quantity",
      render(text, record) {
        return {
          props: {
            style: {
              color: record.enough_for == "0 packages" ? "red" : "black",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      key: "4",
      title: "Enough For",
      dataIndex: "enough_for",
      render(text, record) {
        return {
          props: {
            style: {
              color: record.enough_for == "0 packages" ? "red" : "black",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
  ];
  const [dataset, setDataset] = useState([]);
  const productStore = (
    <div className="container">
      <Table columns={columns_} dataSource={dataset} />
    </div>
  );

  const storeProduct = (pro) => {
    setDataset([]);
    pro?.map((product) => {
      return setDataset((pre) => {
        return [
          ...pre,
          {
            id: product.id,
            name: product.name,
            store_quantity: product?.pivot?.quantity,
            enough_for: product?.pivot?.enough_for_package + " packages",
          },
        ];
      });
    });
  };
  useEffect(() => {
    setDataSource([]);
    packages?.data?.data.forEach((pack) => {
      setDataSource((pre) => {
        return [
          ...pre,
          {
            key: pack["id"],
            id: pack["id"],
            name: pack["name"],
            price: pack["price"] + " ብር",
            left_in_stock: pack["left_in_stock"],
            package: pack,
          },
        ];
      });
    });
  }, [packages]);
  return (
    <>
      <Button
        className="new_comp"
        onClick={() => setVisible(true)}
        style={{
          marginTop: "3%",
          marginLeft: "5%",
          backgroundColor: "#FAAD14",
        }}
      >
        + አዲስ ፓኬጅ ይመዝግቡ
      </Button>
      <div className="container" style={{ marginTop: "1%" }}>
        <Input.Search
          onSearch={(value) => {
            setSearchText(value);
          }}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          placeholder="Search here"
        />
        <Table
          dataSource={dataSource}
          columns={columns}
          onRow={onRow}
          loading={dataSource?.length === 0}
          expandable={{
            rowExpandable: (record) => true,
            expandedRowRender: (rec) => {
              return expandedRow(rec.package);
            },
          }}
        />
      </div>
      <Drawer
        open={visible}
        closable={false}
        onClose={() => setVisible(false)}
        width={800}
      >
        <RegisterPackages />
      </Drawer>
      <Drawer
        open={visible_}
        closable={false}
        onClose={() => {
          setUpdateId(null);
          setVisible_(false);
        }}
      >
        <UpdatePackage package={updateId} />
      </Drawer>
      <Drawer
        open={rowVis}
        closable={false}
        onClose={() => setRowVis(false)}
        width={800}
      >
        <PackageInfo package={packDetail} />
      </Drawer>
    </>
  );
};

export default AdminPackageManagement;
