/* eslint-disable */
import { useState, useEffect } from "react";
import { Table, Button } from "antd";
import { tabColumns } from "./static/TableColumns";
import tw from "tailwind-styled-components";
import {
  useAcceptedOrders,
  useExportAcceptedPendingPickUp,
} from "./hooks/useOrderData";
import MapBox from "./map/mapbox";
import { EnvironmentOutlined, FileExcelOutlined } from "@ant-design/icons";
import OrderedProdcutsAndPackage from "./orderedProductForExpandable";
import ApproveOrder from "./component/approveOrder";
import { useSelector } from "react-redux";
import echo from "../ws_config/ws";
import { useQueryClient } from "react-query";

const StyledTableContainer = tw.div`
  mx-auto max-w-screen
`;

const StyledTable = tw(Table)`
  overflow-x-auto w-full
`;

const AcceptedOrders = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const client = useQueryClient();
  const permissions = useSelector((state) => state.permission.permission);
  // Manage Order Approve
  const [approveModalVisible, setApproveModalVisible] = useState(false);
  const [orderId, setOrderId] = useState();

  const handleLocation = (location) => {
    setSelectedLocation(location);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleApproveModalClose = () => {
    setApproveModalVisible(false);
  };
  const handleApproveModalOpen = (orderId) => {
    setOrderId(orderId);
    setApproveModalVisible(true);
  };

  const [page, setPage] = useState(1);
  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };
  const { data, isLoading: load } = useAcceptedOrders(page);

  // to download Excel file
  const { mutate: download_excel, isLoading: load_excel } =
    useExportAcceptedPendingPickUp();

  const columns = [
    ...tabColumns,
    {
      title: "Delivery",
      dataIndex: ["delivery_type", "name"],
      key: "delivery_type",
    },
    {
      title: "Days Left to Deliver",
      dataIndex: ["days_left_for_delivery"],
      render: (days_left) => {
        return days_left < 0
          ? [-1 * days_left, " days delay"].join(" ")
          : [days_left, " days left"].join(" ");
      },
    },
    {
      title: "AcceptedBy",
      dataIndex: ["acceptor", "name"],
      key: "acceptor_name",
      render: (acceptor) => {
        return acceptor ? acceptor : "?";
      },
    },
    {
      title: "AcceptedAt",
      dataIndex: "accepted_at",
      key: "accepted_at",
    },
    {
      title: "Location",
      render: (record) => (
        <EnvironmentOutlined
          style={{ fontSize: 24, color: "#F1AC1D", cursor: "pointer" }}
          title="Click to view location on Map"
          aria-label="Location marker"
          onClick={() =>
            handleLocation({
              lat: parseFloat(record.shipping_detail.latitude),
              lng: parseFloat(record.shipping_detail.longitude),
            })
          }
        />
      ),
    },

    {
      width: "32%",
      dataIndex: "id",
      title: (
        <div>
          {permissions.find((permission) => {
            return permission == "Report Management";
          }) && (
            <FileExcelOutlined
              title="Download Excel File"
              style={{ fontSize: "22px", color: "#79BF59" }}
              onClick={() => download_excel()}
            />
          )}
        </div>
      ),
      render: (orderId) => {
        return (
          <Button onClick={() => handleApproveModalOpen(orderId)}>
            Approve
          </Button>
        );
      },
    },
  ];

  // listen for socket connections
  useEffect(() => {
    echo.private("orders.status-change").listen("OrderAccepted", (e) => {
      client.invalidateQueries("acceptedOrders");
    });
    return () => {
      echo.leave("orders.status-change");
    };
  }, []);

  return (
    <div>
      <StyledTableContainer>
        <StyledTable
          columns={columns}
          loading={load || load_excel}
          dataSource={data?.data?.map((dt) => ({ ...dt, key: dt.id }))}
          rowClassName={(record, index) =>
            record.days_left_for_delivery < 0
              ? "table-row-red"
              : record.days_left_for_delivery >= 5
              ? "table-row-green"
              : "table-row-yellow"
          }
          pagination={
            data?.meta?.last_page > 1
              ? {
                  current: page,
                  position: "both center",
                  total: data?.meta?.last_page * 10,
                  showSizeChanger: false,
                }
              : false
          }
          expandable={{
            expandedRowRender: (record) => (
              <OrderedProdcutsAndPackage recordData={record} />
            ),
          }}
          onChange={handleTableChange}
          size="small"
        />
      </StyledTableContainer>
      {selectedLocation && modalVisible && (
        <MapBox
          visible={modalVisible}
          latitude={selectedLocation.lat}
          longitude={selectedLocation.lng}
          onClose={handleCloseModal}
        />
      )}
      {approveModalVisible && (
        <ApproveOrder
          visible={approveModalVisible}
          orderId={orderId}
          onClose={handleApproveModalClose}
        />
      )}
    </div>
  );
};

export default AcceptedOrders;
