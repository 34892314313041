/* eslint-disable */
import { useState } from "react";
import {
  Button,
  Table,
  Tag,
  Tooltip,
  Upload,
  Popconfirm,
  message,
  Drawer,
  Spin,
  Input,
} from "antd";
import api from "../adapter/base";
import "../style/style.css";
import "./style/style.css";
//all icon imports
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import "../style/style.css";
import { useCorporateData, useDeleteCorporate } from "./hooks/useCorporates";
import { useQueryClient } from "react-query";
import CorporateEmployee from "./drawer/corporateEmployee";
import AdminHeader from "../Admin/admin_header";
import AdminNav from "../Admin/admin_nav";
import RegisterCorporate from "./drawer/register_corporate";
import UpdateCorporate from "./drawer/updateCorporate";
import RegisterCorporateManager from "./drawer/registerCorporateManager";
import RegisterCorporateEmployee from "./drawer/registerCorporateEmployee";
import { useDebounce } from "./hooks/useDebounce";
const MainPage = () => {
  const title = "Are you sure to delete this task?";
  const description = "Delete the task";
  const [addCorporateVisible, setaddCorporateVisible] = useState(false);
  const [updateCorporateVisible, setUpdateCorporateVisible] = useState(false);
  const [data, setData] = useState();
  const [corporate_Employees, setCorporate_Employees] = useState();
  const [corporateEmployeeVisible, setCorporateEmployee] = useState(false);
  //load flag for download template
  const [load, setLoad] = useState(false);
  //load flag for downlad employee template
  const [loademployee, setLoadEmployee] = useState(false);
  const [uploadLoad, setUploadLoad] = useState(false);
  const { mutate: deleteCorporate } = useDeleteCorporate();

  // register corporate manager drawer visiblity
  const [registerCorManager, setRegisterCorManager] = useState(false);
  // register corporate Employee modal visiblity
  const [registerCorEmployee, setRegisterCorEmployee] = useState(false);
  // corporateID to Register Single Corporate Employee from plus button
  const [corporateId, setCorporateId] = useState();

  //manage pagination
  const [page, setPage] = useState(1);
  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };

  //search controllers
  const [searchTerm, setSearchTerm] = useState("");
  const search = (term) => {
    setSearchTerm(term);
    setPage(1);
  };
  const debouncedValue = useDebounce(searchTerm, 400);

  // fetch corporates
  const { data: allcorporates, isLoading: loadCorporates } = useCorporateData(
    page,
    debouncedValue
  );

  const client = useQueryClient();
  const onClose = () => {
    setaddCorporateVisible(false);
  };
  const onCloseUpdateVisible = () => {
    setUpdateCorporateVisible(false);
  };
  const onCloseCorporateEmployee = () => {
    setCorporateEmployee(false);
  };
  const onCloseregisterCorManager = () => {
    setRegisterCorManager(false);
  };
  const onCloseregisterCorEmployee = () => {
    setRegisterCorEmployee(false);
  };

  // update CorporateId to register corporate Employee
  const UpdateCorporateIdToChildComponent = (id) => {
    setCorporateId(id);
  };

  //When Row Clicked send corporate id to drawer/corporateEmployees component
  const onRow = (records) => {
    return {
      onClick: (event) => {
        setCorporate_Employees(records.id);
        setCorporateEmployee(true);
      },
    };
  };

  //Download Excel Template
  const DownloadExcelTemplate = async () => {
    setLoad(true);
    await api
      .get("/users/download-excel-template", { responseType: "blob" })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `template_for_customer_onBoarding.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        message.error("Check Your Network");
      })
      .finally(() => {
        setLoad(false);
      });
  };

  //Corporate Update
  const updateCorporate = (data) => {
    setUpdateCorporateVisible(true);
    setData(null);
    Object.keys(data).map((key) => {
      if (key == "address") {
        Object.keys(data[key]).map((addkey) => {
          setData((prev) => {
            return { ...prev, [`${addkey}`]: data[key][addkey] };
          });
        });
        return;
      }
      if (key == "bank") {
        setData((prev) => {
          return { ...prev, bank_id: data.bank.id };
        });
        return;
      }
      setData((prev) => {
        return { ...prev, [`${key}`]: data[key] };
      });
    });
  };
  //Download Employee Excel File
  const downloadEmployee = async (id) => {
    setLoadEmployee(true);
    await api
      .get("/users/export", {
        params: {
          corporate_id: id,
        },
        responseType: "blob",
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `customer onboarding of corporate #${id}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        message.error("Check For Your Connection");
      })
      .finally(() => {
        setLoadEmployee(false);
      });
  };

  //upload employees
  const handleUpload = async (file, corporateID) => {
    setUploadLoad(true);
    const fd = new FormData();
    fd.append(
      "file",
      file.fileList[0].originFileObj,
      file.fileList[0].originFileObj.name
    );
    fd.append("corporate_id", corporateID);
    await api
      .post("/users/import", fd)
      .then((res) => {
        message.success("corporate employees updated successfully");
        client.invalidateQueries("corporates");
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
        client.invalidateQueries("corporates");
      })
      .finally(() => {
        setUploadLoad(false);
      });
  };

  // for index
  let lastIndex = 0;
  const updateIndex = () => {
    lastIndex++;
    return lastIndex;
  };

  const columns = [
    {
      key: "0",
      title: "ID",
      align: "center",
      dataIndex: "id",
      render: (id) => {
        return `# ${id}`;
      },
    },
    {
      key: "1",
      title: "የድርጅቱ ስም",
      align: "center",
      dataIndex: "name",
    },
    {
      key: "2",
      title: "የሰራተኛ ብዛት",
      align: "center",
      dataIndex: "number_of_employees",
    },
    {
      key: "3",
      title: "ካፕ",
      align: "center",
      dataIndex: "loan_cap",
    },
    {
      key: "4",
      title: "የብድር መጠን",
      align: "center",
      dataIndex: "loan_balance",
    },
    {
      key: "5",
      title: "ባንክ",
      align: "center",
      dataIndex: ["bank", "name"],
    },
    {
      key: "6",
      title: "ደረጃ",
      dataIndex: "status",
      align: "center",
      render: (tag) => {
        const color = tag?.includes("accepted")
          ? "Green"
          : tag?.includes("pending")
          ? "#595959"
          : tag?.includes("rejected")
          ? "red"
          : "blue";
        return (
          <Tag color={color} key={tag}>
            {tag.includes("pending")
              ? "እይታ ላይ"
              : tag.includes("accepted")
              ? "ተፈቅዷል"
              : "የታገደ"}
          </Tag>
        );
      },
    },
    {
      title: "ድርጅቱ",
      key: "8",
      align: "center",
      render: (records) => {
        return (
          <div>
            <Tooltip title={`Edit Corporate`}>
              <EditOutlined
                style={{ color: "#F4AD33" }}
                className="text-lg"
                onClick={(e) => {
                  e.stopPropagation();
                  updateCorporate(records);
                }}
                title={`${records.name}`}
              />
            </Tooltip>
            <Tooltip title="Delete Corporate">
              <Popconfirm
                placement="topLeft"
                title={title}
                description={description}
                onConfirm={(e) => {
                  e.stopPropagation();
                  deleteCorporate(records.id, {
                    onSuccess: (data) => {
                      message.warning("Deleted Successfully");
                      client.invalidateQueries("corporates");
                    },
                    onError: (err) => {
                      message.error(err?.response?.data?.message);
                    },
                  });
                }}
                onCancel={(e) => {
                  e.stopPropagation();
                  message.warning("ስረዛው ተቋርጧል");
                }}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined
                  style={{ color: "red" }}
                  className="text-lg"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  title={`${records.name}`}
                />
              </Popconfirm>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "የሰራተኞች መረጃ",
      key: `ids${updateIndex()}`,
      align: "center",
      dataIndex: "id",
      render: (record) => {
        return (
          <>
            <Tooltip
              title="Download Employee Information"
              key={record.toString()}
            >
              <Button
                size="small"
                key={record}
                icon={<DownloadOutlined />}
                onClick={(e) => {
                  e.stopPropagation();
                  downloadEmployee(record);
                }}
              >
                Download
              </Button>
            </Tooltip>
            <Tooltip title={`Upload Employee Information using Excel`}>
              <div
                style={{ display: "inline-block" }}
                onClick={(e) => e.stopPropagation()}
              >
                <Upload
                  onChange={(file) => {
                    handleUpload(file, record);
                  }}
                  showUploadList={false}
                  accept=".xlsx, .xls"
                  multiple={false}
                  maxCount={1}
                  beforeUpload={(file) => {
                    return false;
                  }}
                >
                  <span>
                    <Button size="small" icon={<UploadOutlined />}>
                      Upload
                    </Button>
                  </span>
                </Upload>
              </div>
            </Tooltip>
            <Tooltip title="Add Employee">
              <PlusOutlined
                style={{ color: "#F4AD33" }}
                className="text-lg"
                onClick={(e) => {
                  e.stopPropagation();
                  setRegisterCorEmployee(true);
                  UpdateCorporateIdToChildComponent(record);
                }}
              />
            </Tooltip>
          </>
        );
      },
    },
  ];

  return (
    <div>
      <div className="container">
        <div className="main_btns">
          <Button
            onClick={() => {
              setaddCorporateVisible(true);
            }}
            size="small"
          >
            + አዲስ ድርጅት ይጨምሩ
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setRegisterCorManager(true);
            }}
            size="small"
          >
            + ድርጅት አስተዳዳሪ
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              DownloadExcelTemplate();
            }}
            loading={load}
            size="small"
          >
            <DownloadOutlined />
            ኤክሴል ቴምፕሌት
          </Button>
        </div>
        <div className="ml-1 mt-2 md:ml-6 md:mt-5 w-full">
          <Input.Search
            onChange={(e) => search(e.target.value)}
            size="small"
            style={{ text: "10px", placeholder: "6px" }}
            className="w-full px-4 py-2 text-gray-900 bg-gray-100 border-gray-200 rounded-md placeholder-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-transparent"
            placeholder="የትኛውን ድርጅት ፈልገዋል ?"
          />

          <Table
            className="text-sm corporate_table"
            size="small"
            onRow={onRow}
            columns={columns}
            dataSource={allcorporates?.data?.map((dt) => ({
              ...dt,
              key: dt.id,
            }))}
            pagination={{
              current: page,
              position: ["bottomCenter"],
              total: data?.meta?.last_page * 10,
              showSizeChanger: false,
            }}
            onChange={handleTableChange}
            loading={{
              indicator: <Spin size="small" />,
              spinning: loadCorporates || loademployee || uploadLoad,
            }}
            rowKey={(record) => record.id}
          />
        </div>
      </div>

      {/* all Drawers Go here */}

      <Drawer open={addCorporateVisible} onClose={onClose} closable={false}>
        <RegisterCorporate />
      </Drawer>
      <Drawer
        open={updateCorporateVisible}
        onClose={onCloseUpdateVisible}
        closable={false}
        destroyOnClose={true}
      >
        <UpdateCorporate data={data} />
      </Drawer>
      <Drawer
        open={corporateEmployeeVisible}
        onClose={onCloseCorporateEmployee}
        closable={false}
        destroyOnClose={true}
      >
        <CorporateEmployee data={corporate_Employees} />
      </Drawer>
      <Drawer
        open={registerCorManager}
        onClose={onCloseregisterCorManager}
        closable={false}
        destroyOnClose={true}
      >
        <RegisterCorporateManager />
      </Drawer>
      <Drawer
        open={registerCorEmployee}
        onClose={onCloseregisterCorEmployee}
        closable={false}
        destroyOnClose={true}
      >
        <RegisterCorporateEmployee
          UpdateCorporateIdToChildComponent={corporateId}
        />
      </Drawer>
    </div>
  );
};

export default MainPage;
