/* eslint-disable */
import { useState } from "react";
import "../style/style.css";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Upload,
  InputNumber,
  Tag,
  message,
} from "antd";
import qs from "qs";
import { useQueryClient } from "react-query";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { useQuery } from "react-query";
import api from "../adapter/base";
import { useEffect } from "react";
const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

const uploadButton = (
  <div>
    <PlusOutlined />
    <div
      style={{
        marginTop: 8,
      }}
    >
      Upload
    </div>
  </div>
);

const { Option } = Select;
const EditProduct = ({ ProductToEdit, onChange }) => {
  const tagsData = ["የማይገለጽ", "ትልቅ", "መካከለኛ", "ትንሽ"];
  const { CheckableTag } = Tag;
  const [selectedTags, setSelectedTags] = useState([]);
  const [load, setLoad] = useState(false);
  const client = useQueryClient();
  const [form] = Form.useForm();
  const [initialData, setInitialData] = useState();
  const [fileList, setFileList] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);

  const handlePreview = (file) => {
    if (!file.url && !file.preview) {
      file.preview = URL.createObjectURL(file.originFileObj);
    }
  };
  const handleFileChange = ({ fileList }) => {
    setFileList(fileList);
    const isDisabled = fileList.some((file) => file.size > 2 * 1024 * 1024); // check for less than 2 mb;
    setIsDisabled(isDisabled);
  };

  const { data: subcategories, isLoading: loading_sub } = useQuery(
    "subcategories_for_dropdown",
    () => {
      let res = api
        .get("subcategories", {
          params: { all: null },
          paramsSerializer: (params) => {
            return qs.stringify(params, { strictNullHandling: true });
          },
        })
        .then((dt) => {
          return dt?.data?.data;
        })
        .catch((err) => {
          return err;
        });
      return res;
    }
  );
  const { data: suppliers, isLoading: loading_sup } = useQuery(
    "suppliers_for_dropdown",
    () => {
      let res = api
        .get("suppliers")
        .then((dt) => {
          return dt.data.data;
        })
        .catch((err) => {
          return err?.response?.data?.message;
        });
      return res;
    }
  );
  const { data: warehouses, isLoading: loading_ware } = useQuery(
    "warehouses_for_dropdown",
    () => {
      let res = api
        .get("stores")
        .then((dt) => {
          return dt?.data?.data;
        })
        .catch((err) => {
          return err;
        });
      return res;
    }
  );
  const handleChanges = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);
    setSelectedTags(nextSelectedTags);
  };
  const onFinish = (values) => {
    setLoad(true);
    Object.keys(values).map((key) => {
      if (
        Object.is(values[key], null) ||
        Object.is(values[key], undefined) ||
        Object.is("")
      ) {
        delete values[key];
        return;
      }
    });
    console.log(values);
    const fd = new FormData();
    Object?.keys(values)?.map((key) => {
      if (key === "upload") {
        console.log(values["upload"]);
        if (values["upload"]?.length) {
          // const updatedFiles = values["upload"].filter(
          //   (file) => file.status == "done" && file.originFileObj
          // );
          // console.log
          values["upload"].map((image) => {
            image?.originFileObj &&
              fd.append(
                "images[]",
                image.originFileObj,
                image.originFileObj.name
              );
          });
        }
        fd.append("remove_images", 1);
        delete values["upload"];
        return;
      }
      if (key === "store_quantities") {
        for (let i = 0; i < values["store_quantities"]?.length; i++) {
          Object.keys(values["store_quantities"][i]).forEach((key) => {
            fd.append(
              `store_quantities[${i}][${key}]`,
              values["store_quantities"][i][key]
            );
          });
        }
        delete values["store_quantities"];
        return;
      }
      fd.append(key, values[key]);
    });
    fd.append("_method", "put");

    if (selectedTags.length) {
      selectedTags.map((tags) => {
        return fd.append("size[]", tags);
      });
    }
    api
      .post(`products/${ProductToEdit?.id}`, fd)
      .then((res) => {
        message.success("Product Updated Succesfully");
        client.invalidateQueries("products");
        onChange();
        setFileList([]);
      })
      .catch((err) => {
        if (
          err?.response?.data?.message.includes(
            "The store quantities field is required."
          )
        ) {
          message.error(
            "Add Product availablity in different stores by pressing 'Add Store' Button"
          );
        } else {
          message.error(err?.response?.data?.message);
        }
      })
      .finally(() => {
        setLoad(false);
      });
  };
  const onFinishFailed = (err) => {
    console.log(err);
  };

  useEffect(() => {
    ProductToEdit["store_quantities"] = [];
    ProductToEdit["sub_category_id"] = ProductToEdit["subcategory_id"];
    Object.keys(ProductToEdit).map((key) => {
      if (key === "stores") {
        Object.keys(ProductToEdit["stores"]).map((stor) => {
          ProductToEdit["store_quantities"].push({
            store_id: ProductToEdit["stores"][stor].pivot.store_id,
            quantity: ProductToEdit["stores"][stor].pivot.quantity,
          });
        });
        return;
      }
    });
    // const imagepaths = ProductToEdit?.image_paths?.map((url, index) => ({
    //   uid: `${index}`,
    //   name: `image-${index}.jpg`,
    //   url,
    //   status: "done",
    // }));
    // setFileList(imagepaths);
    // console.log(ProductToEdit);
    setInitialData(ProductToEdit);
    form.setFieldsValue(initialData);
  }, [form, initialData, ProductToEdit]);

  return (
    <Form
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
      initialValues={initialData}
      layout="vertical"
    >
      <Row>
        <Col span={12}>
          <Form.Item
            name="name"
            label="የእቃው ስም"
            rules={[
              {
                required: true,
                message: "Please Enter Product Name",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={11} offset={1}>
          <Form.Item
            name="price"
            label="የእቃው ዋጋ"
            rules={[
              {
                required: true,
                message: "Price field is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item
            name="name_am"
            label="የእቃው ስም በአማርኛ"
            rules={[
              {
                required: true,
                message: "እባኮን የእቃውን ስም ያስገቡ",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={11} offset={1}>
          <Form.Item
            name="discount"
            label="ዋጋው ላይ የተደረገ ቅናሽ"
            rules={[
              {
                required: true,
                message: "Price discount",
              },
            ]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item
            name="description"
            label="Description In English"
            rules={[
              {
                required: false,
                message: "Please Enter Appropriate Description",
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
        </Col>
        <Col span={11} offset={1}>
          <Form.Item
            name="sub_category_id"
            label="ቡድን"
            rules={[
              {
                required: true,
                message: "እባኮን ቡድን ያስገቡ",
              },
            ]}
          >
            <Select placeholder="ቡድን" loading={loading_sub}>
              {subcategories?.map((sub_cat) => {
                return (
                  <Option value={sub_cat?.id} key={sub_cat?.id}>
                    {sub_cat?.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item
            name="description_am"
            label="ገለጻ ስለ እቃው(አማርኛ)"
            rules={[
              {
                required: false,
                message: "እባኮን መግለጫውን አስተካክለው ያስገቡ",
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
        </Col>
        <Col span={11} offset={1}>
          <Form.Item
            name="supplier_id"
            label="አምጪው"
            rules={[
              {
                required: true,
                message: "እባኮን አቅራቢውን ይምረጡ",
              },
            ]}
          >
            <Select placeholder="አምጪው" loading={loading_sup}>
              {suppliers?.map((sup) => {
                return (
                  <Option value={sup.id} key={sup.id}>
                    {sup?.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item
            name="upload"
            label="Images For Product"
            // valuePropName="fileList"
            accept=".jpg, .jpeg, .png, .bmp, .gif, .svg,.webp"
            getValueFromEvent={normFile}
          >
            <Upload
              listType="picture-card"
              multiple
              fileList={fileList}
              onChange={handleFileChange}
              onPreview={handlePreview}
              beforeUpload={(file) => {
                if (file.size > 2 * 1024 * 1024) {
                  message.error("Image Size is greater than 2MB");
                  return true;
                }
                return false;
              }}
            >
              {uploadButton}
            </Upload>
          </Form.Item>
        </Col>
        <Col span={11} offset={1}>
          <p style={{ text: "center" }}>
            Product availablity in different stores
          </p>
          <Form.List name="store_quantities">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        {...restField}
                        name={[name, "store_id"]}
                        rules={[
                          {
                            required: true,
                            message: "Please Select Store",
                          },
                        ]}
                      >
                        <Select placeholder="Warehouse" loading={loading_ware}>
                          {warehouses?.map((ware) => {
                            return (
                              <Option key={ware.id} value={ware.id}>
                                {ware?.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={4} offset={1}>
                      <Form.Item
                        {...restField}
                        name={[name, "quantity"]}
                        rules={[
                          {
                            required: true,
                            message:
                              "Enter how much products found in the store",
                          },
                        ]}
                      >
                        <InputNumber placeholder="ብዛት" min={0} />
                      </Form.Item>
                    </Col>
                    <Col span={2} offset={5}>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Col>
                  </Row>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Store
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Row>
            <Col span={12}>
              <Form.Item name="measurement_type" label="መለኪያ">
                <Select placeholder="መለኪያ">
                  <Option value="unit">Unit</Option>
                  <Option value="kilo">Kilo</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={11} offset={1}>
              <Form.Item name="max_quantity_per_order" label="ትልቁ የግዢ መጠን በአንዴ">
                <Input style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={18} offset={3}>
          <Form.Item>
            <span style={{ display: "block" }}>መጠን:</span>
            {tagsData?.map((tag) => (
              <CheckableTag
                style={{ marginRight: 18, marginTop: 4 }}
                key={tag}
                checked={selectedTags.indexOf(tag) > -1}
                onChange={(checked) => handleChanges(tag, checked)}
              >
                {tag}
              </CheckableTag>
            ))}
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        wrapperCol={{
          span: 7,
          offset: 6,
        }}
      >
        <Button
          type="primary"
          htmlType="submit"
          style={
            !isDisabled
              ? { backgroundColor: "#F4AD33", width: "100%" }
              : { backgroundColor: "gray", width: "150%", color: "red" }
          }
          loading={load}
          disabled={isDisabled}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            fill="currentColor"
            className="bi bi-save"
            viewBox="0 0 16 16"
          >
            {" "}
            <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z" />{" "}
          </svg>
          {!isDisabled ? "እቃው ይስተካከል" : "Change Image, size is more than 2mb"}
        </Button>
      </Form.Item>
    </Form>
  );
};
export default EditProduct;
