import React from "react";
import { RiErrorWarningLine } from "react-icons/ri";
import { DeleteOutlined, CloseCircleOutlined } from "@ant-design/icons";
const CustomPopconfirm = ({ title, message, onConfirm, onCancel }) => {
  const handleConfirm = () => {
    onConfirm();
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <div className="relative inline-block">
      <div className="fixed inset-0 flex justify-center items-center z-50 bg-black bg-opacity-50">
        <div className="bg-white min-w-3xl p-4 rounded shadow">
          <div className="font-semibold mb-2">{title}</div>
          <div className="flex items-center mb-4 text-lg gap">
            <RiErrorWarningLine className="text-yellow-500 text-2xl mr-2" />
            <div>{message}</div>
          </div>
          <div className="flex justify-end">
            <button
              className="px-3 py-2 text-white rounded mr-2 border-none"
              onClick={handleConfirm}
            >
              <span className="flex flex-row gap-1 text-red-700">
                <DeleteOutlined className="text-lg " />
                Confirm
              </span>
            </button>
            <button
              className="px-3 py-2 text-white rounded border-none"
              onClick={handleCancel}
            >
              <span className="flex flex-row gap-1 text-[#FAAD14]">
                <CloseCircleOutlined className="text-lg " />
                Cancel
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomPopconfirm;
