/* eslint-disable */
import { Button, Input, Table, Spin, Modal } from "antd";
import { columns } from "./column";
import { useBanks, useDeleteBank } from "./hooks/usebank";
import { useState } from "react";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import RegisterBank from "./modal/registerBank";
import UpdateBank from "./modal/updateBank";
import CustomPopconfirm from "./PopOver/popover";
const BankManagement = () => {
  // variables and functions to handle search
  const [searchTerm, setSearchTerm] = useState("");
  const search = (term) => setSearchTerm(term);
  const { data: banks, isLoading: load_bank } = useBanks();

  // register Modal controllers
  const [RegisterModalvisible, setRegisterModalVisible] = useState(false);
  const openRegisterModal = () => setRegisterModalVisible(true);
  const closeRegisterModal = () => setRegisterModalVisible(false);

  // edit Modal controller
  const [bank, setBank] = useState();
  const [EditModalVisible, setEditModalVisible] = useState(false);
  const openEditModal = () => setEditModalVisible(true);
  const closeEditModal = () => setEditModalVisible(false);
  const bankForOperation = (bank) => setBank(bank);

  // delete popconfirm  controller
  const { deleteBank, error } = useDeleteBank();
  const [deletePopConfirm, setDeletePopConfirm] = useState(false);
  const closeDeletePopConfirm = () => {
    setDeletePopConfirm(false);
    bankForOperation(null);
  };
  const openDeletePopConfirm = () => setDeletePopConfirm(true);
  const handleDeleteBank = () => {
    deleteBank.mutate(bank.id);
    closeDeletePopConfirm();
  };
  // manual search
  const searchedBank = banks?.data?.filter(
    (bank) => bank.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
  );

  const column = [
    ...columns,
    {
      render: (bank) => {
        return (
          <div className="space-x-3">
            <EditOutlined
              className="text-lg"
              style={{ color: "#FAAD14" }}
              title={`Edit ${bank.name}`}
              onClick={() => {
                openEditModal();
                bankForOperation(bank);
              }}
            />
            <DeleteOutlined
              className="text-lg"
              style={{ color: "red" }}
              title={`Delete ${bank.name}`}
              onClick={() => {
                openDeletePopConfirm();
                bankForOperation(bank);
              }}
            />
          </div>
        );
      },
    },
  ];
  return (
    <div className="w-[80%] ml-[5%]">
      <Button
        className="mt-[1%] "
        style={{ backgroundColor: "#FAAD14" }}
        onClick={openRegisterModal}
      >
        + አዲስ ባንክ ይመዝግቡ
      </Button>
      <div className="mt-2">
        <Input.Search onChange={(e) => search(e.target.value)} />
        <Table
          size="small"
          columns={column}
          dataSource={searchedBank?.map((dt) => ({ ...dt, key: dt.id }))}
          loading={{
            indicator: <Spin size="small" />,
            spinning: load_bank,
          }}
          pagination={{
            position: ["bottomCenter"],
            showSizeChanger: false,
          }}
        />
        <Modal
          title="Register Bank"
          open={RegisterModalvisible}
          onCancel={closeRegisterModal}
          centered
          footer={null}
          mask={false}
        >
          <RegisterBank onClose={closeRegisterModal} />
        </Modal>
        <Modal
          title="Update Bank"
          centered
          open={EditModalVisible}
          onCancel={closeEditModal}
          mask={false}
          footer={null}
        >
          <UpdateBank bankForOperation={bank} closeEditModal={closeEditModal} />
        </Modal>
        {deletePopConfirm && (
          <CustomPopconfirm
            title={<h6>Delete Bank?</h6>}
            message={
              <div className="p-0 m-0">
                Are you sure to delete
                <span className="font-medium pl-1">{bank.name}</span>
              </div>
            }
            onCancel={closeDeletePopConfirm}
            onConfirm={handleDeleteBank}
          />
        )}
      </div>
    </div>
  );
};

export default BankManagement;
