/* eslint-disable */
import React, { useState } from "react";
import {
  Card,
  Switch,
  Pagination,
  List,
  Avatar,
  Input,
  Select,
  Spin,
  Divider,
  Modal,
  message,
  Tooltip,
} from "antd";
import UpdateCustomer from "./modal/updateCustomer";
import { useQueryClient } from "react-query";
import { FileExcelOutlined } from "@ant-design/icons";
import { EditOutlined, MoreOutlined } from "@ant-design/icons";
import TimesAgoUtil from "./utils/timesAgo";
import { CiFilter } from "react-icons/ci";
// import MapSelector from "./map/MapSelector";
import {
  useCustomers,
  useActivateDeactivateCustomer,
  useDownloadCustomerInformation,
  usebyPassMaxBuyingLimit,
  useUpdateCustomerSpecialDiscount,
} from "./hooks/useCustomer";
import { useDebounce } from "./hooks/useDebounce";
const { Option } = Select;

const CustomerManager = () => {
  // pagination controller
  const [page, setPage] = useState(1);
  const changePage = (page) => {
    setPage(page);
  };
  // handle search
  const [searchTerm, setSearchTerm] = useState("");
  const search = (term) => {
    setSearchTerm(term);
    setPage(1);
    setByPass(false);
    setSpecialDiscountUser(false);
  };
  const debouncedValue = useDebounce(searchTerm, 400);

  // used to filter bypass customers and special discount users
  const [byPass, setByPass] = useState(false);
  const [specialDiscountUser, setSpecialDiscountUser] = useState(false);

  // search query params controllers
  const [queryParam, setQueryParam] = useState();

  const { data: customers, isLoading: load_customers } = useCustomers(
    queryParam,
    page,
    debouncedValue,
    byPass,
    specialDiscountUser
  );

  // manage customer update modal
  const [customerForOperation, setCustomerForOperation] = useState();
  const [editModal, setEditModal] = useState(false);
  const closeEditCustomerModal = () => setEditModal(false);
  const openEditCustomerModal = () => setEditModal(true);
  //function to send customer to edit and delete operations
  const customerForOperations = (customer) => {
    setCustomerForOperation(customer);
  };

  // initialize query client
  const client = useQueryClient();

  // manage activate/deactivate customer
  const {
    mutate: activateDeactivateCustomer,
    isLoading: load_activate_deactivate,
  } = useActivateDeactivateCustomer();
  // manage activate/deactivate customer
  const {
    mutate: activateCustomerSpecialDiscountType,
    isLoading: loadactivateCustomerSpecialDiscountType,
  } = useUpdateCustomerSpecialDiscount();

  // used to activate and deactivate customer from accessing the site
  const activateDeactivate = (customer) => {
    activateDeactivateCustomer(customer, {
      onSuccess: (data) => {
        client.invalidateQueries("customers");
        const response = data?.data?.data;
        response?.is_active
          ? message.success([response.name, "activated"].join(" "))
          : message.warning([response.name, "deactivated"].join(" "));
      },
      onError: (error) => {
        message.warning(error?.response?.data?.message);
      },
    });
  };

  // used to update customer special discount update
  const updateCustomerSpecialDiscount = (customer) => {
    activateCustomerSpecialDiscountType(customer, {
      onSuccess: (data) => {
        client.invalidateQueries("customers");
        const response = data?.data?.message;
        response?.includes("detached")
          ? message.warning(
              [
                customer?.first_name ? customer.name : customer.phone_number,
                response,
              ].join(" ")
            )
          : message.success(
              [
                customer?.first_name ? customer.name : customer.phone_number,
                response,
              ].join(" ")
            );
      },
      onError: (error) => {
        message.warning(error?.response?.data?.message);
      },
    });
  };

  // initiate a bypassProductQuantity
  const {
    mutate: updateBypassProductQuantityRestrictions,
    isLoading: loading_bypass_product_quantity,
  } = usebyPassMaxBuyingLimit();

  // used to give a bypass product selling limit
  const updateBypassProductQuantityRestriction = (customer) => {
    updateBypassProductQuantityRestrictions(customer, {
      onSuccess: (data) => {
        client.invalidateQueries("customers");
        const response = data?.data?.data;
        response?.bypass_product_quantity_restriction
          ? message.warning("Maximum buying limit revoked")
          : message.warning("Maximum buyng limit restriction setted");
      },
      onError: (error) => {
        message.warning(error?.response?.data?.message);
      },
    });
  };

  // used to download customer information into excel
  const {
    mutate: downloadCustomerInformation,
    isLoading: load_customer_download_information,
  } = useDownloadCustomerInformation();
  const downloadCustomers = () => {
    !load_customer_download_information
      ? downloadCustomerInformation(
          {},
          {
            onSuccess: (data) => {
              const url = window.URL.createObjectURL(new Blob([data.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", `seregela_gebeya_customers.xlsx`);
              document.body.appendChild(link);
              link.click();
            },
            onError: (err) => {
              message.warning(err?.response?.data?.message);
            },
          }
        )
      : message.warning("Processing the file wait a minute... ");
  };

  return (
    <div className="mt-[2.5%] ml-[15%] ">
      <Card className="max-w-[70%] border-[#DFE0EB] rounded-3xl">
        <div className="flex flex-row justify-between">
          <h6 className="text-[#252733] font-bold text-lg">Customers</h6>
        </div>
        <div className="w-full inline-block mt-[1.5%]">
          <div className="w-full flex flex-row">
            <Select
              className="w-[20%]"
              defaultValue="name"
              onChange={(value) => {
                setQueryParam(value);
              }}
            >
              <Option value="name">Name</Option>
              <Option value="phone_number">Phone Number</Option>
            </Select>
            <Input.Search
              placeholder="Customer Name, Phone Number or UserName"
              title="username search results may not be highlighted"
              onSearch={(value) => {
                search(value);
              }}
              onChange={(e) => {
                search(e.target.value);
              }}
              onKeyUp={(e) => {
                e.key === "Enter" && search(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="flex flex-row text-[#C5C7CD] text-xl mt-[2%]">
          {!load_customer_download_information ? (
            <Tooltip title="Download Customer Information">
              <FileExcelOutlined
                style={{ color: "#f9b939" }}
                onClick={downloadCustomers}
                className="hover:cursor-pointer"
                title="download customer information into excel"
              />
            </Tooltip>
          ) : (
            <Spin
              size="small"
              style={{
                color: "#f9b939",
              }}
            />
          )}
          <h6 className="ml-[3%]">Customer</h6>
          <h6 className="ml-[36%]">Edit</h6>
          <h6 className="ml-[5%]">Activate</h6>
          <Tooltip title="Pass Product buying limit">
            <div className="flex flex-row ml-[4%] gap-1">
              <h6>ByPass</h6>
              <CiFilter
                style={{ color: byPass ? "#F6B738" : "" }}
                onClick={() => {
                  setByPass((pass) => !pass);
                  setSpecialDiscountUser(false);
                  setPage(1);
                  setSearchTerm("");
                }}
              />
            </div>
          </Tooltip>
          <Tooltip title="Activate Customers Special Discount Type">
            <div className="flex flex-row ml-[2%] gap-0">
              <h6>
                Special
                <span className="ml-2">
                  <CiFilter
                    style={{ color: specialDiscountUser ? "#F6B738" : "" }}
                    onClick={() => {
                      setSpecialDiscountUser((discount) => !discount);
                      setByPass(false);
                      setPage(1);
                      setSearchTerm("");
                    }}
                  />
                </span>
                Discount
              </h6>
            </div>
          </Tooltip>
        </div>
        <Divider lineWidth={1} lineHeight={1} />
        <div className="bottom_border"></div>
        {
          <List
            itemLayout="horizontal"
            className="mt-[1%] w-full"
            dataSource={customers?.data}
            loading={{
              indicator: <Spin size="small" />,
              spinning:
                load_customers ||
                load_activate_deactivate ||
                load_customer_download_information ||
                loading_bypass_product_quantity ||
                loadactivateCustomerSpecialDiscountType,
            }}
            renderItem={(customer) => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <Avatar
                      src={customer?.profile_thumbnail_path}
                      className="w-10 h-10"
                      title={
                        customer?.created_at !== customer?.updated_at
                          ? `  updated ` + TimesAgoUtil(customer?.updated_at)
                          : `not updated`
                      }
                    />
                  }
                  title={
                    <div>
                      {customer?.name != " "
                        ? customer.name
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                          ? customer.name
                              .split(
                                new RegExp(
                                  `(${searchTerm.toLowerCase()})`,
                                  "gi"
                                )
                              )
                              .map((text, i) =>
                                text.toLowerCase() ===
                                searchTerm.toLowerCase() ? (
                                  <span
                                    key={i}
                                    style={
                                      searchTerm !== ""
                                        ? {
                                            backgroundColor: "#F9B939",
                                            padding: "2px",
                                            color: "white",
                                          }
                                        : null
                                    }
                                  >
                                    {text}
                                  </span>
                                ) : (
                                  <span key={i}>{text}</span>
                                )
                              )
                          : customer.name
                        : customer?.phone_number}
                    </div>
                  }
                  description={
                    <h6 className="text-xs text-[#C5C7CD]">
                      {`Registered ` + TimesAgoUtil(customer?.created_at)}
                    </h6>
                  }
                />
                <EditOutlined
                  style={{ color: "#f9b939" }}
                  className="text-lg pl-[3%] -ml-[60%]"
                  onClick={(e) => {
                    e.stopPropagation();
                    customerForOperations(customer);
                    openEditCustomerModal();
                  }}
                  title={`Edit ${customer?.name}`}
                />

                <Switch
                  className="text-sm"
                  style={{ color: "#f9b939" }}
                  checked={customer?.is_active}
                  onChange={() => activateDeactivate(customer)}
                  size="small"
                  title={
                    customer?.is_active ? "activated user" : "deactivated user"
                  }
                />
                <Switch
                  size="small"
                  style={{ color: "#f9b939" }}
                  checked={customer?.bypass_product_quantity_restriction}
                  onChange={() =>
                    updateBypassProductQuantityRestriction(customer)
                  }
                  title={
                    customer?.bypass_product_quantity_restriction
                      ? "User can pass max product buying limit"
                      : "User can't pass max product buying limit"
                  }
                />
                <Switch
                  size="small"
                  style={{ color: "#f9b939" }}
                  checked={customer?.special_discount}
                  onChange={() => updateCustomerSpecialDiscount(customer)}
                  title={
                    customer?.special_discount
                      ? "User have special discount type"
                      : "User don't have special discount type"
                  }
                />
                <MoreOutlined className="text-xl text-[#C5C7CD]" />
              </List.Item>
            )}
          />
        }
        <Divider />
        {customers?.meta?.last_page > 1 ? (
          <div className="ml-[25%]">
            <Pagination
              size="small"
              showSizeChanger={false}
              defaultCurrent={1}
              current={customers?.meta?.current_page}
              total={
                customers?.meta?.last_page ? customers?.meta?.last_page * 10 : 1
              }
              onChange={(page) => {
                changePage(page);
              }}
            />
          </div>
        ) : (
          ``
        )}
      </Card>
      <Modal
        title="Update Customer"
        open={editModal}
        onCancel={closeEditCustomerModal}
        closable={false}
        footer={null}
        mask={false}
      >
        <UpdateCustomer
          className="bg-gray-300"
          customerForOperations={customerForOperation}
          closeEditCustomerModal={closeEditCustomerModal}
        />
      </Modal>
    </div>
  );
};

export default CustomerManager;
