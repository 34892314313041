import AddImageAdvert from "./components/addImageAdverts";
import ImageAdverts from "./components/imageAdvert";
const ImageAdvert = () => {
  return (
    <div className="flex flex-row justify-between">
      <AddImageAdvert />
      <ImageAdverts />
    </div>
  );
};
export default ImageAdvert;
