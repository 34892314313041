import { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, Upload, Input, message } from "antd";
import { useQueryClient } from "react-query";
import "../style/style.css";  
import api from "../adapter/base";

const formlayout = {
  labelCol: {
    span: 20,
  },
  wrapperCol: {
    span: 24,
  },
};
const uploadButton = (
  <div>
    <PlusOutlined />
    <div
      style={{
        marginTop: 8,
      }}
    >
      Upload
    </div>
  </div>
);
const AddCategory = ({ handleCancel }) => {
  const [form] = Form.useForm();
  const [load, setLoad] = useState(false);
  const [counter, setCounter] = useState(0);
  const client = useQueryClient();
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    setCounter((counter) => counter + 1);
    return e?.fileList;
  };
  const onFinish = (values) => {
    setLoad(true);
    const fd = new FormData();
    if (values["upload"]?.length) {
      fd.append(
        "image",
        values["upload"][0].originFileObj,
        values["upload"][0].originFileObj.name
      );
      delete values["upload"];
    } else {
      delete values["upload"];
    }
    Object.keys(values).map((key) => {
      return fd.append(key, values[key]);
    });
    api
      .post(`categories`, fd)
      .then((res) => {
        message.success("New Category Registered successfully");
        form.resetFields();
        client.invalidateQueries("categories_by_pagination");
        handleCancel();
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
      })
      .finally(() => {
        setLoad(false);
      });
  };

  return (
    <div className="AddCategory">
      <Form
        onFinish={onFinish}
        form={form}
        layout="vertical"
        {...formlayout}
        style={{
          maxWidth: 300,
        }}
      >
        <Form.Item
          label="Category Name"
          name="name"
          rules={[
            {
              required: true,
              message: "category name is required",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="ስም በአማርኛ"
          name="name_am"
          rules={[
            {
              required: true,
              message: "category name in amharic is required",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="upload"
          label="የምድቡ ምስል"
          valuePropName="fileList"
          accept=".jpg, .jpeg, .png, .bmp, .gif, .svg,.webp"
          getValueFromEvent={normFile}
          multiple={false}
        >
          <Upload
            name="logo"
            listType="picture-card"
            multiple={false}
            beforeUpload={(file) => {
              return false;
            }}
          >
            {counter < 1 && uploadButton}
          </Upload>
        </Form.Item>

        <Form.Item
          wrapperCol={{
            span: 15,
            offset: 2,
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            style={{ backgroundColor: "#F4AD33", marginTop: "12px" }}
            loading={load}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              fill="currentColor"
              className="bi bi-save"
              viewBox="0 0 16 16"
            >
              {" "}
              <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z" />{" "}
            </svg>
            መዝግብ
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default AddCategory;
