import api from "../../adapter/base";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { message } from "antd";
const freeTripRequests = async (page) => {
  const response = await api.get(
    `/free-trip-requests?page=${page}&user&approver`
  );
  return response?.data;
};

export const useFetchFreeTripRequests = (page = 1) => {
  return useQuery(["freeTripRequests", page], () => freeTripRequests(page));
};

const approveFreeTripRequests = async (free_trip_request_id) => {
  const response = await api.put(
    `/free-trip-requests/${free_trip_request_id}/approve`
  );
  return response;
};

export const useApproveFreeTripRequest = () => {
  const client = useQueryClient();
  const approveFreeTripMutation = useMutation(approveFreeTripRequests, {
    onSuccess: (data) => {
      message.success("Approved Succesfully!");
      client.invalidateQueries("freeTripRequests");
    },
    onError: (err) => {
      if (err?.response?.status === 500) {
        message.warning("Server Error");
      } else {
        message.error(err?.response?.data?.message ?? "Something Went Wrong!");
      }
    },
  });
  return { approveFreeTripMutation };
};

const rejectFreeTripRequests = async (free_trip_request_id) => {
  const response = await api.put(
    `/free-trip-requests/${free_trip_request_id}/disapprove`
  );
  return response;
};

export const useRejectFreeTripRequest = () => {
  const client = useQueryClient();
  const rejectFreeTripRequest = useMutation(rejectFreeTripRequests, {
    onSuccess: () => {
      message.warning("Order Rejected!");
      client.invalidateQueries("freeTripRequests");
    },
    onError: (err) => {
      if (err?.response?.status === 500) {
        message.warning("Server Error");
      } else {
        message.error(err?.response?.data?.message ?? "Something Went Wrong!");
      }
    },
  });
  return { rejectFreeTripRequest };
};
