/* eslint-disable */
import { useState } from "react";
import { List, Avatar, Statistic, Input, message, Pagination } from "antd";
import { useResetPinCode, useCorporateEmployees } from "../../hooks/useEmployee";
import { useDebounce } from "../hooks/useDebounce";
const CorporateEmployee = ({ data }) => {
  const [name, setName] = useState("");
  const [page, setPage] = useState(1);
  const debouncedValue = useDebounce(name, 400);

  const handleSearch = (e) => {
    setName(e.target.value);
    setPage(1);
  };
  const { data: corporateEmployees, isLoading: load_corporate_employee } =
    useCorporateEmployees(data, page, debouncedValue);
  const { mutate: resetPinCode } = useResetPinCode();
  const handleResetPassCode = (userId) => {
    resetPinCode(userId, {
      onSuccess: (data) => {
        message.success([data?.data?.data?.name, "PinCode Resetted"].join(" "));
      },
      onError: (err) => {
        message.warning(err.response.data.message);
      },
    });
  };
  return (
    <div>
      <div className="listitem">
        <Statistic
          title="Registered"
          style={{ marginLeft: "2%" }}
          valueStyle={{
            color: "#3f8600",
          }}
          value={corporateEmployees?.meta?.total}
        ></Statistic>
        <Input.Search onChange={handleSearch} />
        <List
          itemLayout="horizontal"
          dataSource={corporateEmployees?.data}
          header={"የ ሰራተኞች ስም ዝርዝር"}
          loading={load_corporate_employee}
          style={{ marginLeft: "2%" }}
          renderItem={(item) => (
            <List.Item
              extra={
                <div
                  style={{ color: "#0000FF", cursor: "pointer" }}
                  onClick={() => handleResetPassCode(item.id)}
                >
                  reset PinCode
                </div>
              }
            >
              <List.Item.Meta
                style={{ width: "90%" }}
                avatar={
                  <Avatar size="large" src={item.profile_thumbnail_path} />
                }
                title={
                  item.first_name +
                  " " +
                  item.last_name +
                  " " +
                  [item?.is_corporate_manager ? " (M)" : ""]
                }
                description={item.email}
              />
            </List.Item>
          )}
        />
      </div>
      {corporateEmployees?.meta?.last_page > 1 ? (
        <Pagination
          size="small"
          showSizeChanger={false}
          current={corporateEmployees?.meta?.current_page}
          total={corporateEmployees?.meta?.last_page * 10}
          onChange={(page) => {
            setPage(page);
          }}
        />
      ) : (
        ``
      )}
    </div>
  );
};
export default CorporateEmployee;
