/* eslint-disable */
import { useState, useEffect } from "react";
import { Table, Button, message } from "antd";
import tw from "tailwind-styled-components";
import { useOrders, useExportExcel } from "./hooks/useOrderData";
import OrderedProducts from "./orderedProducts";
import { tabColumns } from "./static/TableColumns";
import { FileExcelOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
const StyledTableContainer = tw.div`
  mx-auto max-w-screen-lg
`;

const StyledTable = tw(Table)`
  overflow-x-auto w-full
`;
const SearchResult = ({ filters }) => {
  const [page, setPage] = useState(1);
  const permissions = useSelector((state) => state.permission.permission);

  // onRow click handler
  const [recordData, setRecordData] = useState();
  const [modalVisible, setModalVisible] = useState(false);

  // functions to control modal
  const handleModalClose = () => {
    setModalVisible(false);
  };

  const handleModalOpen = () => {
    setModalVisible(true);
  };

  const { data, isLoading: loadOrders } = useOrders(filters, page);

  const onRow = (record) => {
    return {
      onClick: (event) => {
        handleModalOpen();
        setRecordData(record);
      },
    };
  };

  useEffect(() => {
    setPage(1);
  }, [filters]);

  // handle pagination changes
  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };

  // handle Excel Download controllers
  const { mutate: exportOrders, isLoading: load_export } = useExportExcel();

  const downloadFilteredOrders = (filter) => {
    exportOrders(filter, {
      onSuccess: (data) => {
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement("a");
        link.href = url;

        // descriptive name for excel file
        link.setAttribute(
          "download",
          `${filter?.customer_name ?? ``} ${
            filter?.customer_phone_number ?? ``
          } ${filter?.status ?? ``} ${
            filter?.dateRange
              ? [
                  filter?.dateRange[0].format().split("T")[0],
                  filter?.dateRange[1].format().split("T")[0],
                ].join(" to ")
              : ``
          } ${
            filter?.last_x_minutes
              ? ["before", filter?.last_x_minutes, "minutes_"].join(" ")
              : ``
          } ${
            filter?.order_id ? ["orderId", filter?.order_id].join(" #") : ``
          } orders.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      },
      onError: (err) => {
        message.warning(err?.response?.data?.message);
      },
    });
  };

  return (
    <div>
      <div>
        {permissions.find((permission) => {
          return permission == "Report Management";
        }) && (
          <Button
            loading={load_export}
            onClick={() => {
              downloadFilteredOrders(filters);
            }}
          >
            <FileExcelOutlined
              title="Download Excel File"
              style={{ fontSize: "22px", color: "#79BF59" }}
            />
            Export Excel
          </Button>
        )}
      </div>

      <StyledTableContainer>
        <StyledTable
          columns={tabColumns}
          dataSource={data?.data?.map((dt) => ({ ...dt, key: dt.id }))}
          pagination={
            data?.meta?.last_page > 1
              ? {
                  current: page,
                  position: "both center",
                  total: data?.meta?.last_page * 10,
                  showSizeChanger: false,
                }
              : false
          }
          onRow={onRow}
          onChange={handleTableChange}
          loading={loadOrders}
          size="small"
        />
      </StyledTableContainer>
      <OrderedProducts
        visible={modalVisible}
        onClose={handleModalClose}
        recordData={recordData}
      />
    </div>
  );
};

export default SearchResult;
