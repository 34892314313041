import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "antd/dist/antd.variable.min.css";
import "./App.css";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import Login from "./common/Login";
import Products from "./Admin/Products";
import AdminDashboard from "./Admin/admin_dashboard";
import AdminOrderManagment from "./Admin/admin_order_management";
import RBAC from "./Admin/rbac";
// import AdminCustomerManagement from "./Admin/customer_management";
import CustomerManager from "./customerManagement/main";
import AdminStockManagement from "./Admin/stock_management";
// import Registration from "./common/Registeration";
// import ForgetPassword from "./common/ForgetPassword";
import Recycler from "./Admin/recycler";
import BankManagement from "./bankManagement/main";
import WareHouse from "./Admin/warehouse_management";
import AdminPackageManagement from "./Admin/package_management";
import SupplierManagement from "./Admin/suppliers_management";
import PushNotification from "./AdminComponent/push_notification";
import SiteSetting from "./Admin/site_setting";
// import ResetPassword from "./common/reset_password";
// import UpdateProfile from "./common/update_profile";
import DeliveryType from "./Admin/delivery_type";
import EmployeeManager from "./CorporateManager/employee_management";
import LandingPage from "./Admin/landing_page";
import Report from "./Admin/report";
import Main from "./fulfilment/main";
import MainPage from "./main/main";
import Layout from "./layout/main";
import DiscountUsers from "./DiscountUser";
import ImageAdvert from "./ImageAdvert";
import FreeTripRequests from "./FreeTripRequests/freeTripRequests";
function App() {
  const client = new QueryClient();
  return (
    <>
      <QueryClientProvider client={client}>
        <Router>
          <Routes>
            {["/", "/login"].map((path, index) => (
              <Route path={path} element={<Login />} key={index} />
            ))}
            <Route path="/admin" element={<Layout />}>
              <Route index element={<LandingPage />} />
              <Route path="dashboard" element={<AdminDashboard />} />
              <Route path="main" element={<MainPage />} />
              <Route path="bank" element={<BankManagement />} />
              <Route path="products" element={<Products />} />
              <Route
                path="order_management"
                element={<AdminOrderManagment />}
              />
              <Route path="rbac" element={<RBAC />} />
              <Route path="customer_management" element={<CustomerManager />} />
              <Route path="discount_users" element={<DiscountUsers />} />
              <Route
                path="stock_management"
                element={<AdminStockManagement />}
              />
              <Route path="recycler" element={<Recycler />} />
              <Route path="warehouse" element={<WareHouse />} />
              <Route
                path="package_management"
                element={<AdminPackageManagement />}
              />
              <Route path="fulfillment" element={<Main />} />
              <Route path="delivery-type" element={<DeliveryType />} />
              <Route
                path="supplier_management"
                element={<SupplierManagement />}
              />
              <Route path="push_notification" element={<PushNotification />} />
              <Route path="app_settings" element={<SiteSetting />} />
              <Route path="report" element={<Report />} />
              <Route path="image_advert" element={<ImageAdvert />} />
              <Route path="free_trip_request" element={<FreeTripRequests />} />
            </Route>
            <Route path="empl_manager" element={<EmployeeManager />} />
          </Routes>
        </Router>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </QueryClientProvider>
    </>
  );
}
export default App;
