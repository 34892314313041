import { useQuery } from "react-query";
import api from "../adapter/base";

export const useData = () => {
  const { data: corporates, isLoading: loading } = useQuery(
    "corporates_list",
    () => {
      return api.get("corporates");
    }
  );
  const data = [];
  corporates?.data?.data.map((corporate) => {
    corporate["key"] = corporate.id.toString();
    data.push(corporate);
  });
  return { data: data, load: loading };
};

export const corporateEmployees = (id) => {
  return api.get(`corporates/${id}/users`);
};

export const useCorporateEmployees = (id) => {
  return useQuery(["corporate_employees", { id }], () => {
    return api.get(`coporates/${id}/users`);
  });
};

export const RegisterCorporateEmployee = (employee) => {};
