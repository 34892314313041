import React from "react";
import { Menu, Avatar } from "antd";
import "antd/dist/antd.css";
import image from "../images/ser.png";
import { actions } from "../store/products-slice";
import { auth_actions } from "../store/auth-slice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { permission_action } from "../store/permission_slice";
function CorporateHeader() {
  const user = useSelector((state) => state?.auth?.user?.data);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logout = () => {
    dispatch(auth_actions.logout());
    dispatch(actions.defaultState());
    dispatch(permission_action.removePermission());
    navigate("/login");
  };
  return (
    <div
      className="container-fluid"
      style={{
        marginLeft: "0%",
        width: "100%",
        paddingLeft: "0%",
        paddingRight: "0%",
      }}
    >
      <div>
        <Menu theme="dark" mode="horizontal" className="admin_menu">
          <Menu.Item key="features">
            <span className="span_header">እንኳን በደህና መጡ!</span>
            <h6>{user?.first_name}</h6>
          </Menu.Item>
          <Menu.Item key="about">
            <Avatar
              src={
                <img src={user?.profile_thumbnail_path} alt="user profile" />
              }
            />
          </Menu.Item>
          <Menu.Item key="logout" onClick={logout}>
            {user && (
              <div>
                <div
                  style={{ fontSize: "25px", color: "white" }}
                  className="logout"
                >
                  <i className="fa fa-sign-out"></i>
                </div>
              </div>
            )}
          </Menu.Item>
        </Menu>
        <div>
          <div className="admin_logo">
            <img src={image} className="img" alt="seregela logo" />
            <div className="sergela_link">
              <a href="https://seregelagebeya.com">
                Sergela<span>Gebeya</span>
              </a>
            </div>
          </div>
          <div className="gear_icons">
            <i
              style={{ color: "white", fontSize: 25 }}
              className="fa-solid fa-gear"
            ></i>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CorporateHeader;
