import { useRef, useEffect } from "react";
const MapSelector = (props) => {
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const places = { lat: null, lng: null };
  const onChanges = () => {
    props.onChanges(places);
  };
  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current
    );
    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace();
      places.lat = place.geometry.location.lat();
      places.lng = place.geometry.location.lng();
      onChanges();
    });
  }, []);
  return (
    <div>
      <input
        className="py-2 px-2 rounded-md border border-gray-300 focus:outline-none focus:border-sky-600 hover:outline-none hover:border-sky-600 placeholder-gray-300"
        ref={inputRef}
        onChange={(e) => {
          e.stopPropagation();
          inputRef.current.focus();
        }}
        style={{ width: "100%", zIndex: 15 }}
      />
    </div>
  );
};
export default MapSelector;
