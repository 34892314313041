import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Card } from "antd";
import api from "../adapter/base";
import { useQuery } from "react-query";
import Chart from "react-apexcharts";
function AdminDashboard() {
  const { data: productsCount } = useQuery("product_count", () => {
    return api.get("products/count");
  });
  const { data: catagory } = useQuery("category_count", () => {
    return api.get("categories/count");
  });
  const { data: customer } = useQuery("customer_count", () => {
    return api.get("customers/count");
  });
  const { data: packages } = useQuery("package_count", () => {
    return api.get("packages/count");
  });
  const { data: supplier } = useQuery("supplier_count", () => {
    return api.get("suppliers/count");
  });
  const { data: orders } = useQuery("orders_count", () => {
    return api.get("/orders/count");
  });
  const { data: deliveredOrder } = useQuery("delivered_order_count", () => {
    return api.get("/orders/count", { params: { status: "successful" } });
  });
  const { data: specialUser } = useQuery("special_users_count", () => {
    return api.get("/customers/count", { params: { special_user: {} } });
  });
  const { data: statistics } = useQuery("statistical_data", () => {
    return api.get("/stats/orders/daily");
  });

  const [order_count, setOrder_count] = useState([]);
  const [order_price, setOrder_price] = useState([]);
  const [days, setDays] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const data_stat = statistics?.data?.data?.reverse();
        setOrder_count(data_stat?.map((item) => item.order_count));
        setOrder_price(data_stat?.map((item) => item.order_total_cost));
        setDays(data_stat?.map((item) => item.day));
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, [statistics]);

  const options = {
    title: {
      text: "Last Week Orders Statistical Report",
    },
    legend: {
      colors: "#FAAD14",
    },
    chart: {
      id: "bar-chart",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: days,
    },
    fill: {
      opacity: 1,
    },
    colors: ["#FAAD14", "#6E6E6E"],
    tooltip: {
      y: [
        {
          formatter: function (val) {
            return val.toFixed(0);
          },
        },
        {
          formatter: function (val) {
            return val.toFixed(1) + " ብር";
          },
        },
      ],
    },
    noData: {
      text: "Loading... choose 'order' or 'order price' below",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#000000",
        fontSize: "14px",
        fontFamily: "Helvetica",
      },
    },
  };
  const series = [
    {
      name: "orders",
      data: order_count,
    },
    {
      name: "order price",
      data: order_price,
    },
  ];

  const navigate = useNavigate();

  return (
    <div className="admin_dashboard">
      <h6
        style={{
          fontSize: 17,
          fontWeight: "bold",
          marginTop: "1.6%",
          color: "#252733",
          marginLeft: "10%",
          letterSpacing: 1,
        }}
      >
        Dashboard
      </h6>
      <Row
        gutter={[8, 16]}
        style={{ marginLeft: "12%", marginTop: "2%", maxWidth: "70%" }}
      >
        <Col xs={12} md={8} xl={6}>
          <Card
            loading={customer ? false : true}
            hoverable
            style={{
              maxWidth: 200,
              maxHeight: 120,
              borderRadius: 10,
              borderColor: "#DFE0EB",
            }}
            onClick={() => {
              navigate("/admin/customer_management");
            }}
          >
            <h6
              style={{
                textAlign: "center",
                fontSize: 15,
                fontWeight: "bold",
                color: "#9FA2B4",
              }}
            >
              Customers
            </h6>
            <h6
              style={{
                textAlign: "center",
                fontSize: 25,
                fontWeight: "bold",
                color: "#252733",
              }}
            >
              {customer?.data}
            </h6>
          </Card>
        </Col>
        <Col xs={12} md={8} xl={6}>
          <Card
            hoverable
            style={{
              maxWidth: 200,
              maxHeight: 120,
              borderRadius: 10,
              borderColor: "#DFE0EB",
            }}
            loading={specialUser ? false : true}
            onClick={() => {
              navigate("/admin/rbac");
            }}
          >
            <h6
              style={{
                textAlign: "center",
                fontSize: 15,
                fontWeight: "bold",
                color: "#9FA2B4",
              }}
            >
              Special Users
            </h6>
            <h6
              style={{
                textAlign: "center",
                fontSize: 25,
                fontWeight: "bold",
                color: "#252733",
              }}
            >
              {specialUser?.data}
            </h6>
          </Card>
        </Col>
        <Col xs={12} md={8} xl={6}>
          <Card
            hoverable
            style={{
              maxWidth: 200,
              maxHeight: 120,
              borderRadius: 10,
              borderColor: "#DFE0EB",
            }}
            loading={productsCount ? false : true}
            onClick={() => {
              navigate("/admin/products");
            }}
          >
            <h6
              style={{
                textAlign: "center",
                fontSize: 15,
                fontWeight: "bold",
                color: "#9FA2B4",
              }}
            >
              Products
            </h6>
            <h6
              style={{
                textAlign: "center",
                fontSize: 25,
                fontWeight: "bold",
                color: "#252733",
              }}
            >
              {productsCount?.data}
            </h6>
          </Card>
        </Col>
        <Col xs={12} md={8} xl={6}>
          <Card
            hoverable
            style={{
              maxWidth: 200,
              maxHeight: 120,
              borderRadius: 10,
              borderColor: "#DFE0EB",
            }}
            loading={catagory ? false : true}
            onClick={() => {
              navigate("/admin/stock_management");
            }}
          >
            <h6
              style={{
                textAlign: "center",
                fontSize: 15,
                fontWeight: "bold",
                color: "#9FA2B4",
              }}
            >
              Catagories
            </h6>
            <h6
              style={{
                textAlign: "center",
                fontSize: 25,
                fontWeight: "bold",
                color: "#252733",
              }}
            >
              {catagory?.data}
            </h6>
          </Card>
        </Col>
        <Col xs={12} md={8} xl={6}>
          <Card
            hoverable
            style={{
              maxWidth: 200,
              maxHeight: 120,
              borderRadius: 10,
              borderColor: "#DFE0EB",
            }}
            loading={packages ? false : true}
            onClick={() => {
              navigate("/admin/package_management");
            }}
          >
            <h6
              style={{
                textAlign: "center",
                fontSize: 15,
                fontWeight: "bold",
                color: "#9FA2B4",
              }}
            >
              Packages
            </h6>
            <h6
              style={{
                textAlign: "center",
                fontSize: 25,
                fontWeight: "bold",
                color: "#252733",
              }}
            >
              {packages?.data}
            </h6>
          </Card>
        </Col>
        <Col xs={12} md={8} xl={6}>
          <Card
            hoverable
            style={{
              maxWidth: 200,
              maxHeight: 120,
              borderRadius: 10,
              borderColor: "#FAAD14",
            }}
            loading={orders ? false : true}
            onClick={() => {
              navigate("/admin/fulfillment");
            }}
          >
            <h6
              style={{
                textAlign: "center",
                fontSize: 15,
                fontWeight: "bold",
                color: "#FAAD14",
              }}
            >
              Orders
            </h6>
            <h6
              style={{
                textAlign: "center",
                fontSize: 25,
                fontWeight: "bold",
                color: "#FAAD14",
              }}
            >
              {orders?.data}
            </h6>
          </Card>
        </Col>
        <Col xs={12} md={8} xl={6}>
          <Card
            hoverable
            style={{
              maxWidth: 200,
              maxHeight: 120,
              borderRadius: 10,
              borderColor: "#DFE0EB",
            }}
            loading={supplier ? false : true}
            onClick={() => {
              navigate("/admin/supplier_management");
            }}
          >
            <h6
              style={{
                textAlign: "center",
                fontSize: 15,
                fontWeight: "bold",
                color: "#9FA2B4",
              }}
            >
              Merchants
            </h6>
            <h6
              style={{
                textAlign: "center",
                fontSize: 25,
                fontWeight: "bold",
                color: "#252733",
              }}
            >
              {supplier?.data}
            </h6>
          </Card>
        </Col>
        <Col xs={12} md={8} xl={6}>
          <Card
            hoverable
            style={{
              maxWidth: 200,
              maxHeight: 120,
              borderRadius: 10,
              borderColor: "#DFE0EB",
            }}
            loading={deliveredOrder ? false : true}
            onClick={() => {
              navigate("/admin/fulfillment");
            }}
          >
            <h6
              style={{
                textAlign: "center",
                fontSize: 15,
                fontWeight: "bold",
                color: "#9FA2B4",
              }}
            >
              Delivered Orders
            </h6>
            <h6
              style={{
                textAlign: "center",
                fontSize: 25,
                fontWeight: "bold",
                color: "#252733",
              }}
            >
              {deliveredOrder?.data}
            </h6>
          </Card>
        </Col>
      </Row>

      <div
        id="chart"
        style={{ width: "70%", marginLeft: "10%", marginTop: "5%" }}
      >
        {statistics && (
          <Chart options={options} series={series} type="bar" height="500px" />
        )}
      </div>
    </div>
  );
}

export default AdminDashboard;
