import store from "../store/index";
import Echo from "laravel-echo";
import Pusher from "pusher-js";

const pusher = new Pusher("local", {
  cluster: "mt1",
  encrypted: true,
});

const echo = new Echo({
  broadcaster: "pusher",
  key: "local",
  cluster: "mt1",
  wsHost: "www.socket.seregelagebeya.com",
  forceTLS: true,
  disableStats: true,
  authEndpoint: "https://seregelagebeya.com/api/broadcasting/auth",
  auth: {
    headers: {
      Authorization: `Bearer ${store.getState()?.auth?.user?.access_token}`,
    },
  },
  pusher: pusher,
});

export default echo;
