import { createSlice } from "@reduxjs/toolkit";

const language=createSlice(
{
    name:'lang',
    initialState:{
        lang:'am'
    },
    reducers:{
        change(state,action){
            state.lang=action.payload
        }
    }
}
)

export const lang_actions=language.actions

export default language