import { useState } from "react";
import {
  Statistic,
  Avatar,
  Input,
  List,
  Button,
  message,
  Upload,
  Badge,
  Space,
  Row,
  Col,
  // Popover,
  // Tooltip,
  Divider,
  Spin,
} from "antd";
import {
  UploadOutlined,
  DownloadOutlined,
  PlusOutlined,
  // LockOutlined,
} from "@ant-design/icons";
import { useEmployees } from "./hooks/useEmployee";
import api from "../adapter/cor_mana_base";
import { useQueryClient } from "react-query";
import CorporateHeader from "./corporateHeader";
// import ChangePinForm from "./changePin";
import { useResetPinCode } from "./hooks/useEmployee";
import { useDebounce } from "./hooks/useDebounce";
const EmployeeManager = () => {
  // const [data, setData] = useState([]);
  // const { data: employees, refetch: employee_refetch } = useQuery(
  //   "corporate_employees",
  //   () => {
  //     return api.get("users");
  //   }
  // );
  const [name, setName] = useState();
  const debouncedValue = useDebounce(name, 400);

  const { data: employees, isLoading: load_employee } =
    useEmployees(debouncedValue);
  const handleSearch = (e) => {
    setName(e.target.value);
  };

  const { mutate: resetPinCode, isLoading: load_reset_pin } = useResetPinCode();

  const [visiblity, setVisiblity] = useState(true);
  const [load, setLoad] = useState(false);
  const [user, setUser] = useState();
  const [fileList, setFileList] = useState([]);
  const [error, setError] = useState();
  const [regload, setRegload] = useState(false);

  const handleChanges = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const handleResetPassCode = (userId) => {
    resetPinCode(userId, {
      onSuccess: (data) => {
        message.success([data?.data?.data?.name, "PinCode Resetted"].join(" "));
      },
      onError: (err) => {
        message.warning(err.response.data.message);
      },
    });
  };

  const queryclient = useQueryClient();
  const registerEmployee = () => {
    setRegload(true);
    const fd = new FormData();
    Object.keys(user).forEach((key) => {
      fd.append(key, user[key]);
    });
    if (fileList.length > 0) {
      fd.append(
        "profile_image",
        fileList[0].originFileObj,
        fileList[0].originFileObj.name
      );
    }

    api
      .post(`users`, fd)
      .then((res) => {
        message.success(
          user?.first_name + " " + user?.last_name + " Registered Successfully"
        );
        queryclient.invalidateQueries("corporate_employees");
      })
      .catch((err) => {
        setError(err?.response?.data?.message);
        if (!err?.response?.data?.message) {
          setError("Something Went Wrong please check your input fields");
        }
      })
      .finally(() => {
        setRegload(false);
      });
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const handleUpload = async (file) => {
    setLoad(true);
    setVisiblity(false);
    const fd = new FormData();
    fd.append(
      "file",
      file.fileList[0].originFileObj,
      file.fileList[0].originFileObj.name
    );
    await api
      .post("/users/import", fd)
      .then((res) => {
        message.success("corporate employee registered successfully", 10);
        queryclient.invalidateQueries("corporate_employees");
        setVisiblity(true);
        setLoad(false);
      })
      .catch((err) => {
        message.error(err.response.data.message, 15);
      })
      .finally(() => {
        setVisiblity(true);
        setLoad(false);
      });
  };
  const downloadEmployee = async () => {
    await api
      .get("/users/export", { responseType: "blob" })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `customer-on-boarding ${Date.now()}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // useEffect(() => {
  //   setData([]);
  //   employees?.forEach((employee) => {
  //     setData((prev) => {
  //       return [
  //         ...prev,
  //         {
  //           id: employee.id,
  //           title: employee?.first_name + " " + employee?.last_name,
  //           email: employee?.email,
  //           image: employee?.profile_thumbnail_path,
  //           employee: employee,
  //           is_corporate_manager: employee.is_corporate_manager,
  //         },
  //       ];
  //     });
  //   });
  // }, [employees]);
  return (
    <>
      <CorporateHeader />
      <span>
        <Button
          className="new_comp"
          style={{
            borderRadius: "2%",
            marginTop: "3%",
            marginLeft: "1%",
            color: "#FAAD14",
          }}
          onClick={downloadEmployee}
        >
          <DownloadOutlined /> ኤክሴል ቴምፕሌት
        </Button>
        <Upload
          onChange={handleUpload}
          accept=".xlsx"
          multiple={false}
          maxCount="1"
          style={{ display: "inline" }}
          beforeUpload={(file) => {
            return false;
          }}
        >
          <span>
            <Button
              icon={<UploadOutlined />}
              style={{
                borderRadius: "2%",
                marginTop: "3%",
                marginLeft: "5%",
                color: "#FAAD14",
                display: "inline",
              }}
              loading={load}
              disabled={!visiblity}
            >
              Upload
            </Button>
          </span>
        </Upload>
      </span>
      <Row>
        <Col span={12}>
          <div style={{ width: "80%", marginLeft: "5%" }}>
            <Statistic
              title="Registered Employee Name List"
              style={{ marginLeft: "2%", marginTop: "2%" }}
              valueStyle={{
                color: "#3f8600",
              }}
              value={employees?.length}
            ></Statistic>
            <Input.Search
              placeholder="Search Employee"
              onChange={handleSearch}
            />
            <List
              itemLayout="horizontal"
              dataSource={employees}
              header={" ሰራተኞች ስም ዝርዝር"}
              loading={load_employee}
              style={{ marginLeft: "2%", width: "90%" }}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    style={{ width: "100%" }}
                    avatar={
                      <Avatar size="large" src={item?.profile_thumbnail_path} />
                    }
                    title={[item?.first_name, item?.last_name].join(" ")}
                    description={item?.email}
                  />
                  {item?.is_corporate_manager ? (
                    <Badge
                      style={{ backgroundColor: "white", color: "black" }}
                      count="Manager"
                    />
                  ) : null}
                  {/* <Popover
                    content={<ChangePinForm userId={item.id} />}
                    title="Change Pin"
                    trigger="click"
                  >
                    <Tooltip title="update user pin">
                      <LockOutlined />
                    </Tooltip>
                  </Popover> */}
                  <div
                    style={{ color: "#0000FF", cursor: "pointer" }}
                    onClick={() => handleResetPassCode(item.id)}
                    key={item.id}
                  >
                    {load_reset_pin ? <Spin size="small" /> : `Reset PassCode`}
                  </div>
                </List.Item>
              )}
            />
          </div>
        </Col>
        <Col
          span={10}
          style={{
            height: "100vh",
            border: "1px solid #C7C7C7",
            marginLeft: "3%",
          }}
        >
          <Divider>Register New Employee</Divider>
          <div
            className="container-fluid"
            style={{ marginTop: "10%", marginLeft: "2%" }}
          >
            <Space direction="vertical" size={5} style={{ width: "90%" }}>
              <Upload
                listType="picture-card"
                fileList={fileList}
                onChange={handleChanges}
                accept=".jpg, .jpeg, .png, .bmp, .gif, .svg,.webp"
                beforeUpload={(file) => {
                  return false;
                }}
                style={{ marginLeft: "50%" }}
              >
                {fileList.length === 1 ? null : uploadButton}
              </Upload>
              <Input
                addonBefore="First Name"
                required
                placeholder="Joe"
                value={user?.first_name}
                onChange={(e) =>
                  setUser((pre) => {
                    return { ...pre, first_name: e.target.value };
                  })
                }
              />
              <Input
                addonBefore="Last Name"
                required
                placeholder="Doe"
                value={user?.last_name}
                onChange={(e) =>
                  setUser((pre) => {
                    return { ...pre, last_name: e.target.value };
                  })
                }
              />
              <Input
                addonBefore="Phone Number"
                required
                showCount
                maxLength={12}
                placeholder="251912...."
                value={user?.phone_number}
                onChange={(e) =>
                  setUser((pre) => {
                    return { ...pre, phone_number: e.target.value };
                  })
                }
              />
              <Input
                addonBefore="Email"
                required
                placeholder="John@gmail.com"
                value={user?.email}
                onChange={(e) =>
                  setUser((pre) => {
                    return { ...pre, email: e.target.value };
                  })
                }
              />
              <Input
                addonBefore="Account Number"
                required
                placeholder="Bank Act. No"
                value={user?.account_number}
                onChange={(e) =>
                  setUser((pre) => {
                    return { ...pre, account_number: e.target.value };
                  })
                }
              />
              <Input
                addonBefore="Loan Amount"
                required
                placeholder="1200"
                value={user?.loan_amount}
                onChange={(e) =>
                  setUser((pre) => {
                    return { ...pre, loan_amount: e.target.value };
                  })
                }
              />
              {error && <div style={{ color: "red" }}>{error}</div>}
              <Button
                onClick={registerEmployee}
                loading={regload}
                style={{ color: "#faad14", marginLeft: "40%", font: "bold" }}
              >
                Register
              </Button>
            </Space>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default EmployeeManager;
