/* eslint-disable */
import { useState } from "react";
import { Form, Button, Select, Input, Modal, message } from "antd";
import { useAllStores, useApproveOrder } from "../hooks/useOrderData";
import MapSelector from "../../common/mapselector";
import { useQueryClient } from "react-query";
const ApproveOrder = ({ visible, orderId, onClose }) => {
  // fetch all stores for selectBox
  const { data: allStores, isLoading: loadStores } = useAllStores();
  const { mutate: approveOrder, isLoading: loadApprove } = useApproveOrder();
  const [form] = Form.useForm();
  const { Option } = Select;
  const [location, setLocation] = useState();
  const client = useQueryClient();
  const onFinish = (values) => {
    approveOrder(
      { orderId: orderId, detail: { ...values, ...location } },
      {
        onSuccess: () => {
          message.success("Order Approved Successfully");
          client.invalidateQueries("acceptedOrders");
        },
        onError: (err) => {
          message.warning(err?.response?.data?.message);
        },
      }
    );
  };
  const getplace = (place) => {
    setLocation({
      latitude: place.lat,
      longitude: place.lng,
    });
  };
  return (
    <Modal open={visible} onCancel={onClose} footer={null}>
      <Form onFinish={onFinish} form={form} layout="vertical">
        <Form.Item name="store_id" label="Select WareHouse:">
          <Select loading={loadStores}>
            {allStores?.map((ast) => {
              return (
                <Option key={ast.id} value={ast.id}>
                  {ast.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="driver_phone_number"
          label="Driver Phone Number (optional):"
        >
          <Input maxLength={12} showCount />
        </Form.Item>
        <Form.Item name="location" label="Store Location (optional):">
          <MapSelector onChanges={getplace} />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" loading={loadApprove}>
            Approve
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default ApproveOrder;
