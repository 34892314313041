import { useState } from "react";
import api from "../adapter/base";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { message } from "antd";

const product = (id) => {
  return api.get(`products/${id}`);
};
// list products that are under categories
const products = async (category_id, page = 1, name, paginate) => {
  const res = await api.get(
    `${category_id ? `categories/${category_id}/` : ``}products${
      page ? `?page=${page}` : ``
    }${name ? `&name=${name}` : ``}${paginate ? `&paginate=${paginate}` : ``}`
  );
  return res.data;
};

// used to activate and deactivate a product
const activateDeactivate = (product) => {
  const updatedProductStatus = { is_active: !product.is_active ? 1 : 0 };
  return api.put(`products/${product?.id}`, updatedProductStatus);
};

export const useProduct = (id) => {
  return useQuery(["product", id], () => product(id), {
    select: (data) => {
      const imagepaths = data.data.data.image_paths;
      return imagepaths;
    },
  });
};

export const useProducts = (category_id, page, name, paginate) => {
  return useQuery(["products", category_id, page, name, paginate], () =>
    products(category_id, page, name, paginate)
  );
};

// used to update product status
export const useActivateDeactivateProduct = () => {
  return useMutation(activateDeactivate);
};

const makeProductStockedOrUnStocked = async (product) => {
  const updatedProductStock = {
    is_non_stocked: !product.is_non_stocked ? 1 : 0,
  };
  const response = await api.put(
    `products/${product?.id}`,
    updatedProductStock
  );
  return response;
};

export const useUpdateProductStockStatus = () => {
  const [error, setError] = useState();
  const client = useQueryClient();
  const mutation = useMutation(makeProductStockedOrUnStocked, {
    onSuccess: (data) => {
      const productName = data?.data?.data?.name;
      const stockStatus = data?.data?.data?.is_non_stocked;
      client.invalidateQueries("products");
      message.success(
        stockStatus
          ? [productName, "Become Non Stocked Successfully"].join(" ")
          : [productName, "Become Stocked Successfully"].join(" ")
      );
    },
    onError: (err) => {
      err?.response?.status === 500
        ? setError("Server Error")
        : setError(err?.response?.data?.message);
    },
  });
  return { mutation, error };
};
