import React, { useState, useEffect } from "react";
import "./alert.css";
const AlertError = ({ error, duration = 3000 }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    let timer;
    if (error) {
      timer = setTimeout(() => {
        setVisible(false);
      }, duration);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [error, duration]);

  if (!visible || !error) {
    return null;
  }

  return (
    <div className="alert alert-error">
      <p>{error}</p>
    </div>
  );
};

export default AlertError;
