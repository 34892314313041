/* eslint-disable */
import { useState } from "react";
import { Button, Table, Select, Popconfirm, message } from "antd";
import { useQueryClient } from "react-query";
import tw from "tailwind-styled-components";
import {
  usePendingPickupOrders,
  useAcceptOrders,
  useDeliveryTypes,
  useExportUnAcceptedPendingPickUp,
} from "./hooks/useOrderData";
import { FileExcelOutlined } from "@ant-design/icons";
import { tabColumns } from "./static/TableColumns";
import OrderedProdcutsAndPackage from "./orderedProductForExpandable";
import { useSelector } from "react-redux";
const StyledTableContainer = tw.div`
  mx-auto max-w-screen-lg
`;

const StyledTable = tw(Table)`
  overflow-x-auto w-full
`;

const { Option } = Select;

const AcceptPendingPickup = () => {
  const [page, setPage] = useState(1);
  const [deliveryTypeId, setDeliveryTypeId] = useState();
  const permissions = useSelector((state) => state.permission.permission);
  
  const client = useQueryClient();

  const { data, isLoading: loading } = usePendingPickupOrders(
    page,
    deliveryTypeId
  );
  const { data: deliveryTypes } = useDeliveryTypes();

  // function to handle delivery type change
  const handleDeliveryTypeChange = (value) => {
    setDeliveryTypeId(value);
  };

  // mutation to accept orders
  const { mutate: acceptOrder, isLoading: load } = useAcceptOrders();
  // accept or cancel order
  const confirm = (id) => {
    acceptOrder(id, {
      onSuccess: () => {
        message.success("Order Accepted Successfully");
        client.invalidateQueries("pendingPickUporders");
      },
      onError: (err) => {
        message.warning(err?.response?.data?.message);
      },
    });
  };

  const cancel = () => {
    message.warning("Not Accepted");
  };

  // mutation to download orders in excel format

  const { mutate: download_excel, isLoading: load_excel } =
    useExportUnAcceptedPendingPickUp();

  // table columns
  const columns = [
    ...tabColumns,
    {
      title: (
        <div>
          <Select onChange={handleDeliveryTypeChange} placeholder="Delivery">
            {deliveryTypes?.data?.map((dt) => (
              <Option key={dt.id} value={dt.id}>
                {dt.name}
              </Option>
            ))}
          </Select>
        </div>
      ),
      dataIndex: ["delivery_type", "name"],
      key: "delivery_type",
    },
    {
      title: (
        <div>
          {permissions.find((permission) => {
            return permission == "Report Management";
          }) && (
            <FileExcelOutlined
              title="Download Excel File"
              style={{ fontSize: "22px", color: "#79BF59" }}
              onClick={() => download_excel()}
            />
          )}
        </div>
      ),
      dataIndex: "id",
      render: (orderId) => {
        return (
          <Popconfirm
            title="Are you sure to Accept this Order?"
            onConfirm={(e) => {
              e.stopPropagation();
              confirm(orderId);
            }}
            onCancel={(e) => {
              e.stopPropagation();
              cancel();
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button
              style={{
                backgroundColor: "#FAAD14",
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              Accept
            </Button>
          </Popconfirm>
        );
      },
    },
  ];
  // handle pagination changes
  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };
  return (
    <StyledTableContainer>
      <StyledTable
        columns={columns}
        dataSource={data?.data?.map((dt) => ({ ...dt, key: dt.id }))}
        pagination={
          data?.meta?.last_page > 1
            ? {
                current: page,
                position: "both center",
                total: data?.meta?.last_page * 10,
                showSizeChanger: false,
              }
            : false
        }
        loading={loading || load || load_excel}
        onChange={handleTableChange}
        size="small"
        expandable={{
          expandedRowRender: (record) => (
            <OrderedProdcutsAndPackage recordData={record} />
          ),
        }}
      />
    </StyledTableContainer>
  );
};

export default AcceptPendingPickup;
