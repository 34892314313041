/* eslint-disable */
import { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, Upload, Input, Select, message, Divider } from "antd";
import { useData } from "../../services/corporate_service";
import "../../style/style.css";
import api from "../../adapter/base";

const formlayout = {
  labelCol: {
    span: 20,
  },
  wrapperCol: {
    span: 24,
  },
};
const uploadButton = (
  <div>
    <PlusOutlined />
    <div
      style={{
        marginTop: 8,
      }}
    >
      Upload
    </div>
  </div>
);
const RegisterCorporateManager = () => {
  const [form] = Form.useForm();
  const [load, setLoad] = useState(false);
  const [counter, setCounter] = useState(0);
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    setCounter((counter) => counter + 1);
    return e?.fileList;
  };
  const onFinish = (values) => {
    Object.keys(values).map((key) => {
      if (
        Object.is(values[key], null) ||
        Object.is(values[key], undefined) ||
        Object.is(values[key], "")
      ) {
        delete values[key];
        return;
      }
    });

    setLoad(true);
    const fd = new FormData();
    if (values["upload"]?.length) {
      fd.append(
        "profile_image",
        values["upload"][0].originFileObj,
        values["upload"][0].originFileObj.name
      );
      delete values["upload"];
    } else {
      delete values["upload"];
    }

    Object.keys(values).map((key) => {
      fd.append(key, values[key]);
    });

    api
      .post("/corporate-managers", fd)
      .then((res) => {
        message.success("Corporate Manager Registered Successfully");
        form.resetFields();
        setLoad(false);
        setCounter(0);
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
        form.resetFields();
        setLoad(false);
        setCounter(0);
      });
  };
  return (
    <div className="registercorporateform">
      <h2>+ አዲስ ድርጅት አስተዳዳሪ</h2>
      <Divider />
      <Form
        name="validate_other"
        onFinish={onFinish}
        form={form}
        layout="vertical"
        {...formlayout}
        style={{
          maxWidth: 300,
        }}
      >
        <Form.Item
          label="የመጀመሪያ ስም"
          name="first_name"
          rules={[
            {
              required: true,
              message: "Please input Manager First Name",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="የመጨረሻ ስም"
          name="last_name"
          rules={[
            {
              required: true,
              message: "Please input Manager Last Name",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="ስልክ ቁጥር"
          name="phone_number"
          rules={[
            {
              required: true,
              message: "Please input phone Number",
            },
          ]}
        >
          <Input placeholder="251912...." maxLength={12} showCount />
        </Form.Item>

        <Form.Item
          label="ኢሜል"
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "The input is not valid E-mail!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="ድርጅት" name="corporate_id">
          <Select loading={useData().load}>
            {useData()?.data?.map((corporate) => {
              return (
                <Select.Option value={corporate.id} key={corporate.id}>
                  {corporate.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          label="ፓስዎርድ"
          name="password"
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
        >
          <Input type="password" />
        </Form.Item>

        <Form.Item
          label="እንደገና ፓስዎርድ"
          name="password_confirmation"
          rules={[
            {
              required: true,
              message: "please enter password confirmation",
            },
          ]}
        >
          <Input type="password" />
        </Form.Item>

        <Form.Item
          name="upload"
          label="Profile Image"
          valuePropName="fileList"
          accept=".jpg, .jpeg, .png, .bmp, .gif, .svg,.webp"
          getValueFromEvent={normFile}
          multiple={false}
        >
          <Upload
            name="logo"
            listType="picture-card"
            multiple={false}
            beforeUpload={(file) => {
              return false;
            }}
          >
            {counter < 1 && uploadButton}
          </Upload>
        </Form.Item>

        <Form.Item
          wrapperCol={{
            span: 15,
            offset: 2,
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            style={{ backgroundColor: "#F4AD33", marginTop: "12px" }}
            loading={load}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              fill="currentColor"
              className="bi bi-save"
              viewBox="0 0 16 16"
            >
              {" "}
              <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z" />{" "}
            </svg>
            መዝግብ
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default RegisterCorporateManager;
