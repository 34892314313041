import { message } from "antd";
import axios from "axios";
import store from "../store/index";
const api = axios.create({
  baseURL: "https://api.seregelagebeya.com/api/v1/",
});
api.interceptors.request.use(
  (req) => {
    const state = store.getState();
    const token = state?.auth?.user?.access_token;
    if (token) req.headers["Authorization"] = `Bearer ${token}`;
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (
      error.response.status === 401 &&
      window.location.pathname !== "/login"
    ) {
      window.location = "/login";
      message.error("Timeout");
      return Promise.reject(error);
    } else if (error.response.status === 403) {
      message.error("UnAuthorized Trial");
      window.location = "/login";
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);
export default api;
