/* eslint-disable */
import { Button, Divider, Input, Switch, Form, message, Upload } from "antd";
import { UploadOutlined, SendOutlined, ClearOutlined } from "@ant-design/icons";
import { usePostImageAdvert } from "../hooks/useImageAdvert";
const AddImageAdvert = () => {
  const [form] = Form.useForm();

  const handleChange = (info) => {
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };
  const customRequest = async ({ file, onSuccess, onError }) => {
    setTimeout(() => {
      const isImage = file.type.startsWith("image/");
      if (isImage) {
        onSuccess("done", file);
      } else {
        onError("error");
      }
    }, 1000);
  };

  const resetForm = () => {
    form.resetFields();
  };
  const { mutation } = usePostImageAdvert(resetForm);
  const fd = new FormData();
  const RegisterAdvertImage = (advertImage) => {
    // console.log(advertImage);
    Object.keys(advertImage).map((key) => {
      if (key === "image") {
        fd.append(
          key,
          advertImage[key].fileList[0].originFileObj,
          advertImage[key].fileList[0].originFileObj.name
        );
        delete advertImage[key];
        return;
      }
      if (key === "is_popup") {
        advertImage[key] = advertImage[key] ? 1 : 0;
        fd.set(key, advertImage[key]);
        delete advertImage[key];
        return;
      }
      fd.set(key, advertImage[key]);
    });
    // console.log([...fd]);
    mutation.mutate(fd);
  };
  return (
    <div className="w-[30%] ml-4 mt-1">
      <Divider>Register New add</Divider>
      <Form onFinish={RegisterAdvertImage} form={form} layout="vertical">
        <Form.Item name="title" label="Title">
          <Input
            size="large"
            style={{ borderRadius: "6px", paddingLeft: "9px" }}
          />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <Input.TextArea style={{ borderRadius: "6px", paddingLeft: "9px" }} />
        </Form.Item>
        <Form.Item name="is_popup" label="Popup Ad ?">
          <Switch size="small" />
        </Form.Item>
        <Form.Item
          name="image"
          label="Image"
          rules={[{ required: true, message: "image fieldis required" }]}
        >
          <Upload
            customRequest={customRequest}
            onChange={handleChange}
            showUploadList={true}
            listType="picture"
            maxCount={1}
            accept="image/*"
          >
            <Button
              className="h-xl w-full"
              style={{
                color: "#FAAD14",
                borderRadius: "6px",
              }}
            >
              <UploadOutlined className="text-xl" />
              Click to Upload Image
            </Button>
          </Upload>
        </Form.Item>
        <Divider />
        <Form.Item>
          <div className="flex flex-row-reverse gap-5">
            <Button
              htmlType="submit"
              icon={<SendOutlined />}
              loading={mutation?.isLoading}
              style={{
                color: "#FAAD14",
              }}
            >
              send
            </Button>
            <Button
              htmlType="reset"
              icon={<ClearOutlined />}
              style={{ color: "#1890FF" }}
            >
              reset
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};
export default AddImageAdvert;
