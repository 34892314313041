import { DatePicker, Table, Button, Cascader, Row, Col, message } from "antd";
import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import AdminHeader from "./admin_header";
import AdminNav from "./admin_nav";
import api from "../adapter/base";
import qs from "qs";
import { Today } from "../common/today";

const Report = () => {
  const { RangePicker } = DatePicker;
  const [data, setData] = useState([]);
  const [status, setStatus] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [page, setPage] = useState();
  const [totalpage, setTotalpage] = useState();
  const [btnload, setBtnload] = useState(false);

  const downloadOrders = async () => {
    setBtnload(true);
    await api
      .get("/orders/export", {
        params: {
          status: status,
          date: { from: startDate, until: endDate },
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            strictNullHandling: true,
          });
        },
        responseType: "blob",
      })

      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          status
            ? `${status}_orders_downloaded_at_${
                Today().day + "/" + Today().month + "/" + Today().year
              }.xlsx`
            : `orders_downloaded_at_${
                Today().day + "/" + Today().month + "/" + Today().year
              }.xlsx`
        );
        document.body.appendChild(link);
        link.click();

        setBtnload(false);
        message.success("Table Exported Successfully");
      })
      .catch((err) => {
        message.error(err);
        setBtnload(false);
      });
  };

  const {
    data: filteredData,
    refetch,
    isLoading: load,
    isFetching: fetch,
  } = useQuery("report_filered_data", () => {
    return api.get("orders", {
      params: {
        status: status,
        user: null,
        page: page,
        date: { from: startDate, until: endDate },
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, {
          strictNullHandling: true,
        });
      },
    });
  });

  const daterange = (range) => {
    if (range == undefined) {
      setStartDate(null);
      setEndDate(null);
    }
    if (range.length === 0) {
      setStartDate(null);
      setEndDate(null);
    }
    setStartDate(range[0].format().split("T")[0]);
    setEndDate(range[1].format().split("T")[0]);
  };

  const options = [
    {
      value: null,
      label: "All",
    },
    {
      value: "pending-pickup",
      label: "Pending PickUp",
    },
    {
      value: "pending-delivery",
      label: "Pending Delivery",
    },
    {
      value: "unpaid",
      label: "UnPaid",
    },
    {
      value: "successful",
      label: "Successful",
    },
  ];
  const columns = [
    {
      key: "1",
      title: "ID",
      dataIndex: "id",
    },
    {
      key: "2",
      title: "customer",
      dataIndex: "name",
    },
    {
      key: "3",
      title: "Order Cost",
      dataIndex: "order_cost",
    },
    {
      key: "4",
      title: "Date",
      dataIndex: "date",
    },
    {
      key: "5",
      title: "Status",
      dataIndex: "status",
      render(text, record) {
        return {
          props: {
            style: { color: text == "unpaid" ? "red" : "green" },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      key: "6",
      title: "Phone Number",
      dataIndex: "phone",
    },
    {
      key: "8",
      title: "Payment",
      dataIndex: "payment",
    },

    {
      key: "9",
      title: "Accepted by",
      dataIndex: "accepted_by",
    },
    {
      key: "10",
      title: "Accepted at",
      dataIndex: "accepted_at",
    },
  ];
  useEffect(() => {
    setData([]);
    setTotalpage(filteredData?.data?.meta?.last_page);
    filteredData?.data?.data?.map((order) => {
      setData((prev) => {
        return [
          ...prev,
          {
            id: "#" + order.id,
            name: " " + order?.user?.first_name + " " + order?.user?.last_name,
            status: order.status,
            order_cost: order.order_cost + " ብር",
            phone: order?.user?.phone_number,
            payment: order?.payment_method,
            date: order.created_at,
            accepted_by: order?.acceptor?.name ? order?.acceptor?.name : "?",
            accepted_at: order?.accepted_at ? order?.accepted_at : "?",
          },
        ];
      });
    });
  }, [filteredData]);

  useEffect(() => {
    refetch();
  }, [status, startDate, endDate, page]);
  return (
    <div>
      <Row style={{ marginTop: "15px", marginLeft: "10%" }}>
        <Col span={4}>
          <label>Status</label>
          <Cascader
            options={options}
            onClear={() => setStatus(null)}
            placeholder="Select Status"
            style={{ display: "block", width: "100%" }}
            onChange={(value) => {
              value.length === 2
                ? setStatus(value[0] + "-" + value[1])
                : setStatus(value[0]);
            }}
          />
        </Col>
        <Col span={4} offset={1}>
          <label>Date</label>
          <RangePicker onChange={daterange} allowClear />
        </Col>
        <Col span={8} offset={1}>
          <label>Export Table</label>
          <br />
          <Button
            style={{ backgroundColor: "#FAAD14", color: "white" }}
            onClick={() => {
              downloadOrders();
            }}
            loading={btnload}
            disabled={btnload}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-download"
              viewBox="0 0 16 16"
            >
              {" "}
              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />{" "}
              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />{" "}
            </svg>
            Excel
          </Button>
        </Col>
      </Row>
      <div className="container">
        <Table
          columns={columns}
          dataSource={data}
          loading={load || fetch}
          pagination={{
            total: totalpage * 10,
            showSizeChanger: false,
            onChange: (page) => {
              setPage(page);
            },
          }}
        />
      </div>
    </div>
  );
};

export default Report;
