import { useQuery } from "react-query";
import api from "../adapter/base";

export const useBanks = () => {
  const { data: allBanks, isLoading: loading } = useQuery(
    "all_registered_banks",
    () => {
      return api.get("banks");
    }
  );
  return { allBanks: allBanks?.data?.data, bankLoad: loading };
};
