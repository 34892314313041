/* eslint-disable */
import { useState, useEffect } from "react";
import { Table, Spin, Button, message } from "antd";
import { useCustomers } from "../customerManagement/hooks/useCustomer";
import {
  useDiscountUser,
  useUpdateDiscountUsers,
} from "./hook/useDiscountUserData";
import Profile from "./component/profile";
import TimesAgoUtil from "../customerManagement/utils/timesAgo";
const DiscountUsers = () => {
  const [page, setPage] = useState(1);
  const handlePageChange = (pagination) => {
    setPage(pagination.current);
    setSelectedRowKeys(JSON.parse(localStorage.getItem("discount_users")));
  };

  const { data: customers, isLoading: load_customers } = useCustomers(
    "name",
    page,
    "",
    false
  );
  const { data: discount_users, isLoading: load_discount_users } =
    useDiscountUser(3);

  const { mutate: updateDiscountUsers, isLoading: load_update_discount_user } =
    useUpdateDiscountUsers();
  const columnns = [
    {
      title: "Customer",
      render: (record) => {
        return (
          <Profile
            name={record?.first_name ? record?.name : record?.phone_number}
            image={record?.profile_thumbnail_path}
            registered={TimesAgoUtil(record?.created_at)}
          />
        );
      },
    },
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  //   console.log("selectedRowKeys: ", selectedRowKeys);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("newSelectedRowKeys: ", newSelectedRowKeys);
    newSelectedRowKeys.length &&
      localStorage.setItem(
        "discount_users",
        JSON.stringify([
          ...JSON.parse(localStorage.getItem("discount_users")),
          ...newSelectedRowKeys,
        ])
      );
    setSelectedRowKeys([
      ...JSON.parse(localStorage.getItem("discount_users")),
      ...newSelectedRowKeys,
    ]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const updateDiscountUserss = () => {
    updateDiscountUsers(
      {
        discount_type_id: 3,
        users: selectedRowKeys,
      },
      {
        onSuccess: (data) => {
          message.success(data.message);
        },
        onError: (err) => message.warning("Special Discount User Not Updated!"),
      }
    );
  };

  useEffect(() => {
    if (discount_users?.data) {
      const initialSelectedRowKeys = discount_users.data.map(
        (discount) => discount.id
      );
      localStorage.setItem(
        "discount_users",
        JSON.stringify(initialSelectedRowKeys)
      );
      setSelectedRowKeys(JSON.parse(localStorage.getItem("discount_users")));
    } else {
      setSelectedRowKeys([]);
    }
  }, [discount_users]);

  return (
    <div className="max-w-2xl mx-auto mt-4">
      <Button
        onClick={() => updateDiscountUserss()}
        loading={load_update_discount_user}
        className="justify-end"
      >
        Save
      </Button>
      <Table
        dataSource={customers?.data.map((customer) => ({
          ...customer,
          key: customer.id,
        }))}
        columns={columnns}
        loading={{
          indicator: <Spin size="small" />,
          spinning: load_discount_users || load_customers,
        }}
        pagination={{
          current: page,
          total: customers?.meta?.last_page * 10,
          showSizeChanger: false,
          position: ["bottomCenter"],
        }}
        onChange={handlePageChange}
        rowSelection={rowSelection}
        size="small"
      />
    </div>
  );
};

export default DiscountUsers;
