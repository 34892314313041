/* eslint-disable */
import { useState } from "react";
import {
  useGetImageAdvert,
  useDeleteImageAdvert,
  useActivateDeactivateAd,
  useChangePopUpStatus,
} from "../hooks/useImageAdvert";
import { Divider, Card, Row, Col, Spin, Tooltip, Badge } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
const ImageAdverts = () => {
  const { data: imageAdverts, isLoading: load_image_advert } =
    useGetImageAdvert();
  const { deleteMutation } = useDeleteImageAdvert();
  const { activateDeactivateMutation } = useActivateDeactivateAd();
  const { popupMutation } = useChangePopUpStatus();

  const inactiveSVG = (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 32 32"
      enableBackground="new 0 0 32 32"
      id="Editable-line"
      version="1.1"
      xml="preserve"
      xmlns="http://www.w3.org/2000/svg"
      fill="#1890FF"
      stroke="#1890FF"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <path
          d=" M16,7C9.934,7,4.798,10.776,3,16c1.798,5.224,6.934,9,13,9s11.202-3.776,13-9C27.202,10.776,22.066,7,16,7z"
          fill="none"
          id="XMLID_13_"
          stroke="#1890FF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        ></path>
        <circle
          cx="16"
          cy="16"
          fill="none"
          id="XMLID_14_"
          r="5"
          stroke="#1890FF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        ></circle>
        <line
          fill="none"
          id="XMLID_15_"
          stroke="#1890FF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
          x1="3"
          x2="29"
          y1="3"
          y2="29"
        ></line>
      </g>
    </svg>
  );

  const activeSVG = (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 32 32"
      enable-background="new 0 0 32 32"
      id="Editable-line"
      version="1.1"
      xml="preserve"
      xmlns="http://www.w3.org/2000/svg"
      fill="#FAAD14"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <path
          d=" M16,7C9.934,7,4.798,10.776,3,16c1.798,5.224,6.934,9,13,9s11.202-3.776,13-9C27.202,10.776,22.066,7,16,7z"
          fill="none"
          id="XMLID_10_"
          stroke="#FAAD14"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        ></path>
        <circle
          cx="16"
          cy="16"
          fill="none"
          id="XMLID_12_"
          r="5"
          stroke="#FAAD14"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        ></circle>
      </g>
    </svg>
  );

  const [loadingIndex, setLoadingIndex] = useState();
  const handleUpdate = (ImageAd, index, type) => {
    setLoadingIndex(index);
    switch (type) {
      case "activateInactivate":
        activateDeactivateMutation.mutate(ImageAd);
        setTimeout(() => setLoadingIndex(-1), 500);
        break;
      case "changePopupStatus":
        popupMutation.mutate(ImageAd);
        setTimeout(() => setLoadingIndex(-1), 500);
        break;
      case "deleteImageAd":
        deleteMutation.mutate(ImageAd?.id);
        setTimeout(() => setLoadingIndex(-1), 500);
        break;
      default:
        setLoadingIndex(-1);
        break;
    }
  };
  return (
    <div className="ml-[2%] w-[65%]">
      <Divider>All Ads</Divider>
      {load_image_advert ? (
        <Spin size="small" className="ml-[50%] mt-[15%]" />
      ) : (
        <Row gutter={[16, 16]}>
          {imageAdverts?.data?.map((imageAd, index) => {
            return (
              <Col xs={24} lg={8} key={imageAd.id}>
                <div
                  onClick={
                    () => handleUpdate(imageAd, index, "changePopupStatus")
                    //   popupMutation.mutate(imageAd)
                  }
                  key={imageAd.id}
                >
                  <Badge.Ribbon
                    text={imageAd?.is_popup ? "PopUp" : "Normal"}
                    color={!imageAd?.is_popup ? "#1890FF" : "#FAAD14"}
                    className="hover:cursor-pointer absolute top-3 right-0 bottom-0 z-10 w-[20%]"
                  />
                </div>
                <Card
                  title={
                    <div className="flex  gap-2">
                      {imageAd?.title}
                      <Tooltip title="Activate/Deactivate">
                        <div
                          className="hover:cursor-pointer"
                          onClick={() =>
                            // activateDeactivateMutation.mutate(imageAd);
                            handleUpdate(imageAd, index, "activateInactivate")
                          }
                        >
                          {loadingIndex == index ? (
                            <Spin size="small" />
                          ) : imageAd?.is_active ? (
                            activeSVG
                          ) : (
                            inactiveSVG
                          )}
                        </div>
                        {/* <Switch checked={imageAd?.is_active} size="small" /> */}
                      </Tooltip>
                    </div>
                  }
                  cover={
                    <img
                      src={imageAd?.image_path}
                      style={{
                        width: "100%",
                        height: "150px",
                        objectFit: "cover",
                      }}
                    />
                  }
                  actions={[
                    <span>
                      <DeleteOutlined
                        style={{ color: "red" }}
                        onClick={() =>
                          handleUpdate(imageAd, index, "deleteImageAd")
                        }
                      />
                    </span>,
                  ]}
                >
                  <Card.Meta
                    title={imageAd?.name}
                    description={<div>{imageAd?.description}</div>}
                  />
                </Card>
              </Col>
            );
          })}
        </Row>
      )}
    </div>
  );
};
export default ImageAdverts;
