import "mapbox-gl/dist/mapbox-gl.css";
import Map, {
  Marker,
  NavigationControl,
  FullscreenControl,
  GeolocateControl,
} from "react-map-gl";
import { useState } from "react";
function MapBox({ latitude, longitude }) {
  const [lng] = useState(longitude);
  const [lat] = useState(latitude);

  return (
    <div>
      <Map
        mapboxAccessToken="pk.eyJ1Ijoic2VyZWdlbGEiLCJhIjoiY2t6MDZ1ZGd2MGwyaDJubzFzd2Vxbmt1aCJ9.s4bTwQbO_i47CYeUuhAk5Q"
        // {process.env.REACT_APP_MAPBOX_KEY}
        style={{
          width: "100%",
          height: "500px",
          borderRadius: "5px",
          border: "1px solid gray",
        }}
        initialViewState={{
          longitude: lng,
          latitude: lat,
          zoom: 9,
          maxZoom: 17,
          patch: 4,
        }}
        mapStyle="mapbox://styles/mapbox/streets-v11?optimize=true"
      >
        <Marker longitude={lng} latitude={lat} />
        <NavigationControl position="bottom-right" />
        <FullscreenControl />

        <GeolocateControl />
      </Map>
    </div>
  );
}

export default MapBox;
