/* eslint-disable */
import { Avatar } from "antd";
const Profile = ({ image, name, registered }) => {
  return (
    <div className="flex flex-row gap-2">
      <Avatar src={image} />
      <div className="flex flex-col gap-1">
        <span>{name}</span>
        <span className="text-gray-500 text-sm">{registered}</span>
      </div>
    </div>
  );
};

export default Profile;
