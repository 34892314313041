import api from '../adapter/base'
import qs from 'qs';

export const getRoles=()=>{
    return api.get('roles');
}

export const getRole=(id)=>{
    return api.get(`roles/${id}`)
}

export const getRoleWithUser=(id)=>{
    const header={params:{users:null},
    paramsSerializer: params => {
        return qs.stringify(params,{strictNullHandling: true})
        }}
    return api.get(`roles/${id}`,header)
    }

export const getRolesWithUser=()=>{
    const header={params:{users:null},
    paramsSerializer: params => {
        return qs.stringify(params,{strictNullHandling: true})
        }}
    return api.get(`roles`,header);
    }

export const postRole=(role)=>{
    return api.post('roles',role)
}

export const updateRole=(id,data)=>{
    return api.put(`roles/${id}`,data)
}
export const deleteRoles=(id)=>{
    return api.delete(`roles/${id}`)
}

export const permissionGroups=()=>{
    return api.get('permission-groups');
}

export const permissionGroup=(id)=>{
    return api.get(`/permission-groups/${id}`);
}
