import { Avatar, Tag } from "antd";

// used for productColumns
export const productColumns = [
  {
    dataIndex: "image_paths",
    key: "image",
    render: (image_path) => {
      return <Avatar src={<img src={image_path[0]} alt="product" />} />;
    },
  },
  {
    title: "የእቃው ስም",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "ብዛት",
    dataIndex: ["pivot", "quantity"],
    key: "quantity",
  },
  {
    title: "የአንዱ ዋጋ",
    dataIndex: "price",
    key: "price",
    render: (price) => {
      return [price, " ብር"].join(" ");
    },
  },
  {
    title: "አጠቃላይ ቅናሽ",
    key: "total_discount",
    render: (record) => {
      return [
        (parseFloat(record.discount) * parseInt(record.pivot.quantity)).toFixed(
          2
        ),
        " ብር",
      ].join(" ");
    },
  },
  {
    title: "አጠቃላይ",
    key: "total_price",
    render: (record) => {
      return [
        (
          (parseFloat(record.price) - parseFloat(record.discount)) *
          parseInt(record.pivot.quantity)
        ).toFixed(2),
        " ብር",
      ].join(" ");
    },
  },
];

// used for package columns

export const packageColumns = [
  {
    dataIndex: "image_paths",
    key: "image",
    render: (image_path) => {
      return <Avatar src={<img src={image_path[0]} alt="product" />} />;
    },
  },
  {
    title: "የፓኬጅ ስም",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "ብዛት",
    dataIndex: ["pivot", "quantity"],
    key: "quantity",
  },
  {
    title: "የአንዱ ዋጋ",
    dataIndex: "price",
    key: "price",
    render: (price) => {
      return `${price} ብር`;
    },
  },
  {
    title: "አጠቃላይ ቅናሽ",
    key: "total_discount",
    render: (record) => {
      return [
        (parseFloat(record.discount) * parseInt(record.pivot.quantity)).toFixed(
          2
        ),
        " ብር",
      ].join(" ");
    },
  },
  {
    title: "አጠቃላይ",
    key: "total_cost",
    render: (record) => {
      return [
        (parseFloat(record.price) * parseInt(record.pivot.quantity)).toFixed(2),
        " ብር",
      ].join(" ");
    },
  },
];

export const tabColumns = [
  {
    title: "Order ID",
    dataIndex: "id",
    key: "id",
    render: (orderId) => {
      return ["#", orderId].join(" ");
    },
  },
  {
    title: "Customer Name",
    dataIndex: ["user", "name"],
    key: "customer_name",
    render: (customer_name) => {
      return customer_name !== " " ? customer_name : "? ? ?";
    },
  },
  {
    title: "Order Cost",
    dataIndex: "order_cost",
    key: "order_cost",
    render: (order_cost) => {
      return [order_cost ? order_cost.toFixed(2) : "?", "ብር"].join(" ");
    },
  },
  {
    title: "Total Cost",
    dataIndex: "total_cost",
    key: "total_cost",
    render: (total_cost) => {
      return [total_cost ? total_cost.toFixed(2) : "?", "ብር"].join(" ");
    },
  },
  {
    title: "Date & Time",
    dataIndex: "created_at",
    key: "created_at",
  },
  {
    title: "status",
    dataIndex: "status",
    render: (status, index) => {
      return (
        <Tag
          key={index}
          color={
            status === "unpaid"
              ? "yellow"
              : status === "payment-rejected"
              ? "maroon"
              : "green"
          }
        >
          {status}
        </Tag>
      );
    },
  },
  {
    title: "Phone Number",
    dataIndex: ["user", "phone_number"],
    key: "customer_phone_number",
  },
  {
    title: "Payment",
    dataIndex: "payment_method",
    key: "payment_method",
  },
];
