/* eslint-disable */
import { Table, Divider } from "antd";
import "./style/tables.css";
import tw from "tailwind-styled-components";
import { packageColumns, productColumns } from "./static/TableColumns";
const StyledTable = tw(Table)`
  mt-4
`;

const OrderedProdcutsAndPackage = ({ recordData }) => {
  const getSummaryRow = () => {
    return (
      <Table.Summary.Row>
        <Table.Summary.Cell className="text-xs font-bold text-center">
          {recordData?.discount_type_name ?? ``}
        </Table.Summary.Cell>
        <Table.Summary.Cell className="text-xs font-bold">
          {recordData?.discount_percentage
            ? [recordData?.discount_percentage, "%"].join(" ")
            : ``}
        </Table.Summary.Cell>
        <Table.Summary.Cell />
        <Table.Summary.Cell />
        <Table.Summary.Cell className="text-lg font-bold">
          የሁሉም ድምር
        </Table.Summary.Cell>
        <Table.Summary.Cell className="text-lg font-bold">
          {recordData?.total_cost.toFixed(2)} ብር
        </Table.Summary.Cell>
      </Table.Summary.Row>
    );
  };

  return (
    <div>
      {recordData?.products?.length > 0 ? (
        <div>
          <Divider>
            <div className="text-base font-bold text-center ">
              የተገዙ እቃዎች ዝርዝር
            </div>
          </Divider>
          <StyledTable
            className="productPackage"
            columns={productColumns}
            dataSource={recordData?.products?.map((dt) => ({
              ...dt,
              key: dt.id,
            }))}
            pagination={false}
            size="small"
            summary={getSummaryRow}
          />
        </div>
      ) : (
        ` `
      )}
      {recordData?.packages?.length > 0 ? (
        <div>
          <Divider>
            <div className="text-base font-bold text-center ">
              የተገዙ ፓኬጆች ዝርዝር
            </div>
          </Divider>
          <StyledTable
            className="productPackage"
            columns={packageColumns}
            dataSource={recordData?.packages?.map((dt) => ({
              ...dt,
              key: dt.id,
            }))}
            pagination={false}
            size="small"
          />
        </div>
      ) : (
        ` `
      )}
    </div>
  );
};

export default OrderedProdcutsAndPackage;
